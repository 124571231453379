import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Autocomplete,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahJualAgunan = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tglJual, setTglJual] = useState(new Date());
  const [noSbg, setNoSbg] = useState("");
  const [namaPembeli, setNamaPembeli] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [tglJtTempoTerakhir, setTglJtTempoTerakhir] = useState(new Date());
  const [bungaPerBulanAju, setBungaPerBulanAju] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState("");
  const [nilaiTopup, setNilaiTopup] = useState("");
  const [bayarPinjamanAju, setBayarPinjamanAju] = useState("");

  const [ketJam, setKetJam] = useState("");
  const [hargaTafsirJam, setHargaTafsirJam] = useState("");
  const [jaminanId, setJaminanId] = useState("");

  const [nilaiJual, setNilaiJual] = useState("");
  const [pokok, setPokok] = useState("");
  const [bunga, setBunga] = useState("");
  const [denda, setDenda] = useState("");
  const [sisa, setSisa] = useState("");
  const [pendapatanPenjualan, setPendapatanPenjualan] = useState("");
  const [titipanKonsumen, setTitipanKonsumen] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  const [coas, setCoas] = useState([]);
  const [jaminans, setJaminans] = useState([]);
  const [error, setError] = useState(false);
  const [searchTermJaminan, setSearchTermJaminan] = useState("");
  const [openJaminan, setOpenJaminan] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleClickOpenJaminan = () => {
    setOpenJaminan(true);
  };

  const handleCloseJaminan = () => {
    setOpenJaminan(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJaminan(query);
  };

  useEffect(() => {
    getJualAgunanNextKode();
    getCoasData();
  }, []);

  useEffect(() => {
    getJaminansData();
  }, [page, searchTermJaminan]);

  const getJaminansData = async () => {
    const response = await axios.post(
      `${tempUrl}/jaminansBlmTerjualPerCabangPagination?search_query=${searchTermJaminan}&page=${page}&limit=${limit}`,
      {
        batasJual: setting.batasJual,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setJaminans(response.data.jaminans);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const getJualAgunanNextKode = async () => {
    const response = await axios.post(`${tempUrl}/jualAgunanNextKode`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoJual(response.data);
  };

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
    setKodeCOA(setting.coaKasKasir);
  };

  const saveJualAgunan = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeCOA.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveJualAgunan`, {
          noJual,
          tglJual,
          noSbg,
          namaPembeli,
          keterangan,
          nilaiJual: nilaiJual,
          pokok,
          bunga,
          denda,
          pendapatanPenjualan,
          titipanKonsumen,
          kodeCOA,
          jaminanId,
          kodeCabang,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/jualAgunan");
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Jual Agunan</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveJualAgunan}>
        <Card>
          <Card.Header>Data Agunan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noJual} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Sbg :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noSbg} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agunan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={ketJam}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Pembeli <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPembeli}
                          onChange={(e) =>
                            setNamaPembeli(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control required />}
                          selected={tglJual}
                          onChange={(date) => {
                            setTglJual(date);
                            let newTglJtTempo = new Date(tglJtTempoTerakhir);
                            newTglJtTempo.setHours(0, 0, 0, 0);
                            date.setHours(0, 0, 0, 0);
                            const diffTime = Math.abs(date - newTglJtTempo);
                            let diffDays = Math.ceil(
                              diffTime / (1000 * 60 * 60 * 24)
                            );
                            if (date < newTglJtTempo) {
                              diffDays = -diffDays;
                            }

                            let tempJmlBulan = 1;
                            if (diffDays > setting.batasBunga) {
                              tempJmlBulan = 2;
                            } else {
                              tempJmlBulan = 1;
                            }

                            setBunga(
                              (bungaPerBulanAju *
                                (pinjamanAju + nilaiTopup - bayarPinjamanAju) *
                                tempJmlBulan) /
                                100
                            );

                            let tempTotalPiutang =
                              pinjamanAju + nilaiTopup - bayarPinjamanAju;
                            if (diffDays > 0) {
                              if (
                                tempTotalPiutang < setting.batasPinjamanDenda
                              ) {
                                setDenda(setting.denda1 * diffDays);
                              } else {
                                setDenda(setting.denda2 * diffDays);
                              }
                            } else {
                              setDenda(0);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Agunan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={jaminanId}
                          readOnly
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenJaminan()}
                          isInvalid={jaminanId.length === 0 && true}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Harga Agunan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={hargaTafsirJam.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          onChange={(e) =>
                            setKeterangan(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Harga</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nilai Jual <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={nilaiJual}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setNilaiJual(
                          values.formattedValue
                            .split(".")
                            .join("")
                            .replace(/,/g, "")
                        );

                        let tempNum = values.formattedValue
                          .split(".")
                          .join("")
                          .replace(/,/g, "");

                        let tempSisa = tempNum - pokok - bunga - denda;
                        setSisa(tempSisa);
                        let tempPersenUntungJual =
                          ((parseFloat(pokok) +
                            parseFloat(bunga) +
                            parseFloat(denda)) *
                            parseFloat(setting.persenUntungJual)) /
                          100;
                        if (tempSisa > tempPersenUntungJual) {
                          tempPersenUntungJual =
                            (tempNum * setting.persenUntungJual) / 100;

                          let tempTitipanKonsumen =
                            tempSisa - tempPersenUntungJual;

                          setPendapatanPenjualan(tempPersenUntungJual);
                          setTitipanKonsumen(tempTitipanKonsumen);
                        } else {
                          setPendapatanPenjualan(tempSisa);
                          setTitipanKonsumen(0);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pokok :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pokok.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={bunga}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setBunga(
                          values.formattedValue
                            .split(".")
                            .join("")
                            .replace(/,/g, "")
                        );

                        let tempNum = values.formattedValue
                          .split(".")
                          .join("")
                          .replace(/,/g, "");

                        let tempSisa = nilaiJual - pokok - tempNum - denda;
                        setSisa(tempSisa);
                        let tempPersenUntungJual =
                          ((parseFloat(pokok) +
                            parseFloat(tempNum) +
                            parseFloat(denda)) *
                            parseFloat(setting.persenUntungJual)) /
                          100;
                        if (tempSisa > tempPersenUntungJual) {
                          tempPersenUntungJual =
                            (nilaiJual * setting.persenUntungJual) / 100;

                          let tempTitipanKonsumen =
                            tempSisa - tempPersenUntungJual;

                          setPendapatanPenjualan(tempPersenUntungJual);
                          setTitipanKonsumen(tempTitipanKonsumen);
                        } else {
                          setPendapatanPenjualan(tempSisa);
                          setTitipanKonsumen(0);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={denda}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setDenda(
                          values.formattedValue
                            .split(".")
                            .join("")
                            .replace(/,/g, "")
                        );

                        let tempNum = values.formattedValue
                          .split(".")
                          .join("")
                          .replace(/,/g, "");

                        let tempSisa = nilaiJual - pokok - bunga - tempNum;
                        setSisa(tempSisa);
                        let tempPersenUntungJual =
                          ((parseFloat(pokok) +
                            parseFloat(bunga) +
                            parseFloat(tempNum)) *
                            parseFloat(setting.persenUntungJual)) /
                          100;
                        if (tempSisa > tempPersenUntungJual) {
                          tempPersenUntungJual =
                            (nilaiJual * setting.persenUntungJual) / 100;

                          let tempTitipanKonsumen =
                            tempSisa - tempPersenUntungJual;

                          setPendapatanPenjualan(tempPersenUntungJual);
                          setTitipanKonsumen(tempTitipanKonsumen);
                        } else {
                          setPendapatanPenjualan(tempSisa);
                          setTitipanKonsumen(0);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sisa :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={sisa.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pend. Penjualan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pendapatanPenjualan.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Titipan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={titipanKonsumen.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Kas <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={coaOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCOA.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeCOA(value.split(" ", 1)[0]);
                      }}
                      defaultValue={setting.coaKasKasir}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/jualAgunan")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Card.Body>
        </Card>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJaminan}
        onClose={handleCloseJaminan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jaminan`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Agunan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Harga
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>No. Sbg</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jaminans.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setKodeCabang(user.cabang.id);
                        setJaminanId(user.id);
                        setKetJam(user.ketJam);
                        setHargaTafsirJam(user.hargaTafsirJam);
                        setNoSbg(user.pengajuan.noSbg);

                        setPokok(
                          user.pengajuan.pinjamanAju +
                            user.pengajuan.nilaiTopup -
                            user.pengajuan.bayarPinjamanAju
                        );
                        setBayarPinjamanAju(user.pengajuan.bayarPinjamanAju);

                        let newTglJtTempo = new Date(
                          user.pengajuan.tglJtTempoTerakhir
                        );
                        newTglJtTempo.setHours(0, 0, 0, 0);
                        tglJual.setHours(0, 0, 0, 0);
                        setTglJtTempoTerakhir(newTglJtTempo);
                        const diffTime = Math.abs(tglJual - newTglJtTempo);
                        let diffDays = Math.ceil(
                          diffTime / (1000 * 60 * 60 * 24)
                        );
                        if (tglJual < newTglJtTempo) {
                          diffDays = -diffDays;
                        }

                        let tempJmlBulan = 1;
                        if (diffDays > setting.batasBunga) {
                          tempJmlBulan = 2;
                        } else {
                          tempJmlBulan = 1;
                        }

                        setBunga(
                          (user.pengajuan.bungaPerBulanAju *
                            (user.pengajuan.pinjamanAju +
                              user.pengajuan.nilaiTopup -
                              user.pengajuan.bayarPinjamanAju) *
                            tempJmlBulan) /
                            100
                        );
                        setBungaPerBulanAju(user.pengajuan.bungaPerBulanAju);
                        setPinjamanAju(user.pengajuan.pinjamanAju);
                        setNilaiTopup(user.pengajuan.nilaiTopup);

                        let tempTotalPiutang =
                          user.pengajuan.pinjamanAju +
                          user.pengajuan.nilaiTopup;
                        if (diffDays > 0) {
                          if (tempTotalPiutang < setting.batasPinjamanDenda) {
                            setDenda(setting.denda1 * diffDays);
                          } else {
                            setDenda(setting.denda2 * diffDays);
                          }
                        } else {
                          setDenda(0);
                        }
                        setNilaiJual("");
                        setSisa("");
                        setPendapatanPenjualan("");
                        setTitipanKonsumen("");

                        handleCloseJaminan();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.ketJam}
                      </TableCell>
                      <TableCell>
                        {user.hargaTafsirJam.toLocaleString("de-DE")}
                      </TableCell>
                      <TableCell>{user.pengajuan.noSbg}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahJualAgunan;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
