import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahFormLabaRugiOJK = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [column1, setColumn1] = useState("");
  const [column2, setColumn2] = useState("");
  const [column3, setColumn3] = useState("");
  const [kodeCOAOJK, setKodeCOAOJK] = useState("");
  const [urutanCOAOJK, setUrutanCOAOJK] = useState("");
  const [dariKodeCOAOJK, setDariKodeCOAOJK] = useState("");
  const [sampaiKodeCOAOJK, setSampaiKodeCOAOJK] = useState("");
  const [isMenu, setIsMenu] = useState(false);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const saveFormLabaRugiOJK = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveFormLabaRugiOJK`, {
          column1,
          column2,
          column3,
          kodeCOAOJK: kodeCOAOJK.length === 0 ? null : kodeCOAOJK,
          urutanCOAOJK: urutanCOAOJK.length === 0 ? null : urutanCOAOJK,
          dariKodeCOAOJK,
          sampaiKodeCOAOJK,
          isMenu,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/formLabaRugiOJK");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Form Laba Rugi OJK</h5>
      <hr />
      <Card>
        <Card.Header>Form Laba Rugi OJK</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveFormLabaRugiOJK}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                {" "}
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Column 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={column1}
                          onChange={(e) => setColumn1(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Column 2 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={column2}
                          onChange={(e) => setColumn2(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Column 3 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={column3}
                          onChange={(e) => setColumn3(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode COA OJK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOAOJK}
                          onChange={(e) => setKodeCOAOJK(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Urutan COA OJK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={urutanCOAOJK}
                          onChange={(e) => setUrutanCOAOJK(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Dari Kode COA OJK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={dariKodeCOAOJK}
                          onChange={(e) => setDariKodeCOAOJK(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Sampai Kode COA OJK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={sampaiKodeCOAOJK}
                          onChange={(e) => setSampaiKodeCOAOJK(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                {" "}
                <Row>
                  <Col>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={isMenu} />}
                        label="Menu"
                        onChange={() => setIsMenu(!isMenu)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/formLabaRugiOJK")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahFormLabaRugiOJK;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
