import React, { useState, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { findMonth, findYear } from "../../../constants/helper";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LabaRugiOjk = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );

  const [isCetak, setIsCetak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [open, setOpen] = useState(false);
  const [labaRugiOjksData, setLabaRugiOjksData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LabaRugiOjk",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);

      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 40;
      setPersenLoading(tempPersenLoading.toString());

      let labaRugis = await axios.post(`${tempUrlFico}/labaRugiOjksRebuild`, {
        kodeGroupPendapatan: setting.kodeGroupPendapatan,
        kodeGroupBiayaAtasPendapatan: setting.kodeGroupBiayaAtasPendapatan,
        kodeGroupPengeluaranOperasional:
          setting.kodeGroupPengeluaranOperasional,
        kodeGroupPendapatanLainLain: setting.kodeGroupPendapatanLainLain,
        kodeGroupBiayaLainLain: setting.kodeGroupBiayaLainLain,
        dariTanggal,
        sampaiTanggal,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      console.log(labaRugis.data);
      setLabaRugiOjksData(labaRugis.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setPersenLoading("0");
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableTextLast = {
    letterSpacing: "0.01px",
    borderTop: "1px solid black",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const list = [];
  let tempTotalPendapatan = 0;
  let tempTotalBeban = 0;

  for (let labaRugiOjk of labaRugiOjksData) {
    if (
      labaRugiOjk.urutanCOAOJK === setting.urutanCoaOjkLabaRugiJumlahPendapatan
    ) {
      tempTotalPendapatan += labaRugiOjk.jumlah;
    } else if (
      labaRugiOjk.urutanCOAOJK === setting.urutanCoaOjkLabaRugiJumlahBeban
    ) {
      tempTotalBeban += labaRugiOjk.jumlah;
    }
    list.push(
      <tr>
        <td
          style={{
            fontWeight: labaRugiOjk.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {labaRugiOjk.column1}
        </td>
        <td
          style={{
            fontWeight: labaRugiOjk.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {labaRugiOjk.column2}
        </td>
        <td
          style={{
            fontWeight: labaRugiOjk.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {labaRugiOjk.column3}
        </td>
        <td
          style={{
            fontWeight: labaRugiOjk.isMenu === true ? "800" : "400",
            border: "1px solid black",
            letterSpacing: "0.01px",
            textAlign: "right",
          }}
        >
          {labaRugiOjk.jumlah !== null &&
            (isCetak === true
              ? parseFloat(labaRugiOjk.jumlah)
              : parseFloat(labaRugiOjk.jumlah).toLocaleString("en-EN"))}
        </td>
      </tr>
    );
  }

  list.push(
    <tr>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>C</td>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>
        LABA / ( RUGI ) SEBELUM PAJAK
      </td>
      <td></td>
      <td
        style={{
          fontWeight: "800",
          border: "1px solid black",
          letterSpacing: "0.01px",
          textAlign: "right",
        }}
      >
        {isCetak === true
          ? tempTotalPendapatan - tempTotalBeban
          : (tempTotalPendapatan - tempTotalBeban).toLocaleString("en-EN")}
      </td>
    </tr>
  );

  list.push(
    <tr>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>D</td>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>
        TAKSIRAN PAJAK PENGHASILAN
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td
        style={{
          fontWeight: "800",
          border: "1px solid black",
          letterSpacing: "0.01px",
          textAlign: "right",
        }}
      >
        -
      </td>
    </tr>
  );

  list.push(
    <tr>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>E</td>
      <td style={{ fontWeight: "800", border: "1px solid black" }}>
        LABA / ( RUGI ) PERIODE BERJALAN
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td
        style={{
          fontWeight: "800",
          border: "1px solid black",
          letterSpacing: "0.01px",
          textAlign: "right",
        }}
      >
        {isCetak === true
          ? tempTotalPendapatan - tempTotalBeban
          : (tempTotalPendapatan - tempTotalBeban).toLocaleString("en-EN")}
      </td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LabaRugiOjk",
    sheet: "LabaRugiOjk",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Accounting OJK</h3>
      <h5 style={{ fontWeight: 400 }}>Laba Rugi OJK</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="contained" startIcon={<SearchIcon />} type="submit">
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={generatePDF}
            >
              PDF
            </Button>
            <Button
              color="secondary"
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetak(true);
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}

      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <table
          class="styled-table"
          ref={tableRef}
          style={{ visibility: previewPdf === true ? "visible" : "hidden" }}
        >
          <tbody>
            <tr style={{ textAlign: "center", borderBottom: "none" }}>
              <th colSpan="4" style={{ borderBottom: "none" }}>
                PERUSAHAAN PEGADAIAN SWASTA
              </th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">{setting.namaPerusahaan}</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">LAPORAN LABA RUGI</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">
                Untuk Periode Yang Berakhir Pada Bulan {findMonth(dariTanggal)}{" "}
                tahun {findYear(dariTanggal)}
              </th>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th colspan="2" style={{ border: "1px solid black" }}>
                NAMA AKUN
              </th>
              <th colspan="2" style={{ border: "1px solid black" }}>
                JUMLAH
              </th>
            </tr>
            {list}
          </tbody>
        </table>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal Laba Rugi Periode Berjalan berhasil diposting!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LabaRugiOjk;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
