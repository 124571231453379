import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanPenerimaan = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [kasirId, setKasirId] = useState("");
  const [namakasir, setNamaKasir] = useState("");

  const [kasirs, setKasirs] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapPenerimaansData, setLapPenerimaansData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);

  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanPenerimaan",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let neracas = await axios.post(`${tempUrl}/laporanPenerimaan`, {
        dariTanggal,
        sampaiTanggal,
        kodeCabang,
        kasirId,
        _id: user.id,
        token: user.token,
      });
      setLapPenerimaansData(neracas.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getKasirsData();
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];

  let keys = Object.keys(lapPenerimaansData);
  let tempNo = 1;
  let tempTotalPiutang = 0;
  let tempPiutang = 0;
  let tempTotalBiayaAdm = 0;
  let tempBiayaAdm = 0;
  let tempTotalBunga = 0;
  let tempBunga = 0;
  let tempTotalDenda = 0;
  let tempDenda = 0;
  let tempTotalAll = 0;
  let tempTotal = 0;

  for (let j = 0; j < Object.keys(lapPenerimaansData).length; j++) {
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="10">
          TGL. {lapPenerimaansData[keys[j]][0].tglFormat} :
        </td>
      </tr>
    );
    let groupBy = lapPenerimaansData[keys[j]].reduce((group, pengajuan) => {
      const { tglFormat } = pengajuan;
      group[tglFormat] = group[tglFormat] ?? [];
      group[tglFormat].push(pengajuan);
      return group;
    }, {});
    let keysSubGroup = Object.keys(groupBy);

    for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
      for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
        tempTotalPiutang += groupBy[keysSubGroup[j]][k].piutang;
        tempPiutang += groupBy[keysSubGroup[j]][k].piutang;
        tempTotalBiayaAdm += groupBy[keysSubGroup[j]][k].biayaAdm;
        tempBiayaAdm += groupBy[keysSubGroup[j]][k].biayaAdm;
        tempTotalBunga += groupBy[keysSubGroup[j]][k].bunga;
        tempBunga += groupBy[keysSubGroup[j]][k].bunga;
        tempTotalDenda += groupBy[keysSubGroup[j]][k].denda;
        tempDenda += groupBy[keysSubGroup[j]][k].denda;
        tempTotal +=
          groupBy[keysSubGroup[j]][k].piutang +
          groupBy[keysSubGroup[j]][k].biayaAdm +
          groupBy[keysSubGroup[j]][k].bunga +
          groupBy[keysSubGroup[j]][k].denda;
        tempTotalAll +=
          groupBy[keysSubGroup[j]][k].piutang +
          groupBy[keysSubGroup[j]][k].biayaAdm +
          groupBy[keysSubGroup[j]][k].bunga +
          groupBy[keysSubGroup[j]][k].denda;
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {tempNo}
            </td>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].noSbg}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].namaCustomer}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].alamatCustomer}
            </td>
            <td style={textNumberRight}>
              {groupBy[keysSubGroup[j]][k].piutang.toLocaleString("en-EN")}
            </td>
            <td style={textNumberRight}>
              {groupBy[keysSubGroup[j]][k].biayaAdm.toLocaleString("en-EN")}
            </td>
            <td style={textNumberRight}>
              {groupBy[keysSubGroup[j]][k].bunga.toLocaleString("en-EN")}
            </td>
            <td style={textNumberRight}>
              {groupBy[keysSubGroup[j]][k].denda.toLocaleString("en-EN")}
            </td>
            <td style={textNumberRight}>
              {(
                groupBy[keysSubGroup[j]][k].piutang +
                groupBy[keysSubGroup[j]][k].biayaAdm +
                groupBy[keysSubGroup[j]][k].bunga +
                groupBy[keysSubGroup[j]][k].denda
              ).toLocaleString("en-EN")}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].kasir}
            </td>
          </tr>
        );
        tempNo++;
      }
    }
    list.push(
      <tr style={tableText}>
        <td style={textBold} colspan="3">
          SUBTOTAL TGL. {lapPenerimaansData[keys[j]][0].tglFormat} :
        </td>
        <td style={{ border: "1px solid black" }}></td>
        <td style={textNumberRightBold}>
          {tempPiutang.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRightBold}>
          {tempBiayaAdm.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRightBold}>{tempBunga.toLocaleString("en-EN")}</td>
        <td style={textNumberRightBold}>{tempDenda.toLocaleString("en-EN")}</td>
        <td style={textNumberRightBold}>{tempTotal.toLocaleString("en-EN")}</td>
        <td style={{ border: "1px solid black" }}></td>
      </tr>
    );
    tempPiutang = 0;
    tempBiayaAdm = 0;
    tempBunga = 0;
    tempDenda = 0;
    tempTotal = 0;
  }

  list.push(
    <tr style={tableText}>
      <td style={textBold} colspan="3">
        TOTAL :
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={textNumberRightBold}>
        {tempTotalPiutang.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRightBold}>
        {tempTotalBiayaAdm.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRightBold}>
        {tempTotalBunga.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRightBold}>
        {tempTotalDenda.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRightBold}>
        {tempTotalAll.toLocaleString("en-EN")}
      </td>
      <td style={{ border: "1px solid black" }}></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPenerimaan",
    sheet: "LaporanPenerimaan",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Penerimaan</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                        setNamaKasir("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Penerimaan
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${dariTanggal.getFullYear()}`}
          </p>
          <p>
            Sampai Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p>Kasir : {namakasir}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No.</th>
                <th style={{ border: "1px solid black" }}>No. SBG</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>Alamat</th>
                <th style={{ border: "1px solid black" }}>Piutang</th>
                <th style={{ border: "1px solid black" }}>Bi. Adm</th>
                <th style={{ border: "1px solid black" }}>Bunga</th>
                <th style={{ border: "1px solid black" }}>Denda</th>
                <th style={{ border: "1px solid black" }}>Total</th>
                <th style={{ border: "1px solid black" }}>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPenerimaan;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
