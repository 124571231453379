import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const TampilBankKeluarChild = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBankKeluarChild } = useParams();
  const [tempIdBankKeluarChild, setTempIdBankKeluarChild] = useState("");
  const [noBukti, setNoBukti] = useState("");
  const [tglBankKeluar, setTglBankKeluar] = useState("");
  const [tglBankKeluarDate, setTglBankKeluarDate] = useState();
  const [jumlah, setJumlah] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [isPost, setIsPost] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN" && isPost === false;
  let isEditableAdmin = true;
  // let isEditableAdmin =
  //   user.tipeUser === "ADMIN" &&
  //   tglBankKeluarDate?.getFullYear() === today.getFullYear() &&
  //   tglBankKeluarDate?.getMonth() === today.getMonth() &&
  //   tglBankKeluarDate?.getDate() === today.getDate() &&
  //   isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getBankKeluarChildById();
  }, []);

  const getBankKeluarChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrlFico}/bankKeluarsChild/${idBankKeluarChild}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setTempIdBankKeluarChild(response.data.id);
      setNoBukti(response.data.bankkeluar.noBukti);
      setTglBankKeluar(formatDate(response.data.bankkeluar.tglBankKeluar));
      setTglBankKeluarDate(response.data.bankkeluar.tglBankKeluar);
      setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);
      setKeterangan(response.data.keterangan);
      setIsPost(response.data.isPost);
      setJumlah(response.data.jumlah);
    }
  };

  const deleteBankKeluarChild = async (id) => {
    try {
      setLoading(true);
      // Delete Kas Keluar
      await axios.post(
        `${tempUrlFico}/deleteBankKeluarChild/${tempIdBankKeluarChild}`,
        {
          bankKeluarId: id,
          jumlah,
          _id: user.id,
          token: user.token,
        }
      );
      setLoading(false);
      navigate(`/daftarBankKeluar/bankKeluar/${id}`);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Finance</h3>
      <h5 style={{ fontWeight: 400 }}>Detail Bank Keluar</h5>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarBankKeluar/bankKeluar/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={deleteButtonContainer}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${noBukti}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteBankKeluarChild(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {isEditable && (
          <ButtonGroup variant="contained">
            <Button
              color="primary"
              startIcon={<EditIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                navigate(
                  `/daftarBankKeluar/bankKeluar/${id}/${idBankKeluarChild}/edit`
                );
              }}
            >
              Ubah
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={handleClickOpen}
            >
              Hapus
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <hr />
      <Card>
        <Card.Header>Detail Bank Keluar</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode COA :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={kodeCOA} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={tglBankKeluar}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jumlah :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={jumlah.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TampilBankKeluarChild;

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
