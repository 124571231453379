import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const LaporanTunggakan = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [perTanggal, setPerTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [kategoriJaminanId, setKategoriJaminanId] = useState("");
  const [namaKategoriJaminan, setNamaKategoriJaminan] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");

  const [marketings, setMarketings] = useState([]);
  const [kategoriJaminans, setKategoriJaminans] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTunggakansData, setLapTunggakansData] = useState({});
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);

  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kategoriJaminanOptions = kategoriJaminans.map((kategoriJaminan) => ({
    label: kategoriJaminan.namaKategori,
    id: kategoriJaminan.id,
  }));

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "l",
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.48 },
    });
  };

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let laporanTunggakan = await axios.post(`${tempUrl}/laporanTunggakan`, {
        kategoriJaminanId,
        marketingId,
        jenisResikoAju,
        perTanggal,
        kodeCabang,
        _id: user.id,
        token: user.token,
      });
      setLapTunggakansData(laporanTunggakan.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getMarketingsData();
    getKategoriJaminansData();
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getMarketingsData = async () => {
    setMarketingId("");
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
    setJenisResikoAju("");
  };

  const getKategoriJaminansData = async () => {
    setKategoriJaminanId("");
    const response = await axios.post(`${tempUrl}/kategoriJaminans`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKategoriJaminans(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const list = [];

  if (Object.keys(lapTunggakansData).length !== 0) {
    // List Per 30
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>1 - 30 h</td>
        <td style={textNumberRight}>
          {lapTunggakansData.per30.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per30.totalOutstanding.toLocaleString("en-EN")}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.per30.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
    // List Per 60
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          31 - 60 h
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per60.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per60.totalOutstanding.toLocaleString("en-EN")}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.per60.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
    // List Per 90
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          61 - 90 h
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per90.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per90.totalOutstanding.toLocaleString("en-EN")}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.per90.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
    // List Per 120
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          91 - 120 h
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per120.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.per120.totalOutstanding.toLocaleString("en-EN")}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.per120.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
    // List more than 120
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          {">120 h"}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.moreThan120.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.moreThan120.totalOutstanding.toLocaleString(
            "en-EN"
          )}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.moreThan120.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
    // List Total
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>Total</td>
        <td style={textNumberRight}>
          {lapTunggakansData.total.totalTunggakan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapTunggakansData.total.totalOutstanding.toLocaleString("en-EN")}
        </td>
        <td
          style={textNumberRight}
        >{`${lapTunggakansData.total.npl.toLocaleString("en-EN")}%`}</td>
      </tr>
    );
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanTunggakan",
    sheet: "LaporanTunggakan",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Tunggakan</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Per Tanggal :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={perTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setPerTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori Jaminan :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriJaminanOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategoriJaminanId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI JAMINAN"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKategoriJaminanId(value.id);
                        setNamaKategoriJaminan(value.label);
                      } else {
                        setKategoriJaminanId("");
                        setNamaKategoriJaminan("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaKategoriJaminan}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaMarketing}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Jenis Resiko :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={jenisResikoOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && jenisResikoAju.length === 0 && true}
                        size="small"
                        placeholder="SEMUA JENIS RESIKO"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setJenisResikoAju(value);
                      } else {
                        setJenisResikoAju("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={jenisResikoAju}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={handleGeneratePdf}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Tunggakan
          </h5>
          <p>
            Per Tanggal :
            {` ${perTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(perTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${perTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p>Kategori : {namaKategoriJaminan}</p>
          <p>Marketing : {namaMarketing}</p>
          <p>Resiko : {jenisResikoAju}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>Keterlambatan</th>
                <th style={{ border: "1px solid black" }}>Total Tunggakan</th>
                <th style={{ border: "1px solid black" }}>Total Outstanding</th>
                <th style={{ border: "1px solid black" }}>%</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanTunggakan;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
  display: "table",
};
