import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Autocomplete,
} from "@mui/material";
import { Container, Card, Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";

const UbahAngsuranFidusiaChild = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const { id, idAngsuranChild } = useParams();

  const [no, setNo] = useState("");
  const [isPercepatan, setIsPercepatan] = useState("");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Data Angsuran
  const [isBayarLast, setIsBayarLast] = useState(false);
  const [idAngsuran, setIdAngsuran] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoBefore, setTglJatuhTempoBefore] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");

  // Data Inputan
  const [tglBayar, setTglBayar] = useState("");
  const [tglBayarTeks, setTglBayarTeks] = useState("");
  const [keterlambatan, setKeterlambatan] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [denda, setDenda] = useState("");
  const [hutangDenda, setHutangDenda] = useState("");
  const [potongan, setPotongan] = useState("");
  const [totalPiutang, setTotalPiutang] = useState("");
  const [totalBayar, setTotalBayar] = useState("");
  const [bayar, setBayar] = useState("");

  // Data Percepatan
  const [penerimaanId, setPenerimaanId] = useState("");
  const [penerimaanAngsuran, setPenerimaanAngsuran] = useState({});
  const [kodeCOAAngsuran, setKodeCOAAngsuran] = useState("");

  const [coas, setCoas] = useState([]);

  const [isPost, setIsPost] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    getCoasData();
    getAngsuranFidusiaChildById();
  }, []);

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const getAngsuranFidusiaChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/angsuranFidusiasByPengajuanShow`,
        {
          pengajuanFidusiaId: id,
          no: idAngsuranChild,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setNo(response.data.no);
      setIsPercepatan(response.data.isPercepatan);
      setIdAngsuran(response.data.id);
      let newTglJatuhTempo = new Date(response.data.tglJatuhTempo);
      setTglJatuhTempo(formatDate(response.data.tglJatuhTempo));
      setAngModal(parseInt(response.data.angModal));
      setAngBunga(parseInt(response.data.angBunga));
      setAngPerBulan(response.data.angPerBulan);

      let newTglBayar = new Date(response.data.tglBayar);
      setTglBayar(response.data.tglBayar && formatDate(response.data.tglBayar));

      let tempDateName;
      switch (newTglBayar.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }
      let tempTglBayar = `${newTglBayar.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempDateName} ${newTglBayar.getFullYear()}`;
      setTglBayarTeks(tempTglBayar);
      setNoKwitansi(response.data.noKwitansi);
      setKeterangan(response.data.keterangan);
      setDenda(response.data.denda);
      setHutangDenda(response.data.hutangDenda);
      setPotongan(response.data.potongan);
      setTotalPiutang(response.data.totalPiutang);
      setTotalBayar(response.data.totalBayar);
      setBayar(response.data.bayar);

      const diffTime = Math.abs(newTglBayar - newTglJatuhTempo);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (newTglBayar > newTglJatuhTempo) {
        setKeterlambatan(diffDays);
      }

      const angsuranBayarLast = await axios.post(
        `${tempUrl}/angsuranFidusiasByPengajuanBayarLast`,
        {
          pengajuanFidusiaId: id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      if (
        angsuranBayarLast.data &&
        response.data.id === angsuranBayarLast.data.id
      ) {
        setIsBayarLast(true);
        setTglJatuhTempoBefore(angsuranBayarLast.data.tglJatuhTempo);
      }

      if (response.data.isPercepatan === true) {
        // Get Data Penerimaan Percepatan
        const penerimaan = await axios.post(
          `${tempUrl}/penerimaanFidusiasByPengajuanShowPercepatan`,
          {
            pengajuanFidusiaId: id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        setPenerimaanId(penerimaan.data.id);
        setPenerimaanAngsuran(penerimaan.data);
        setIsPost(penerimaan.data.isPost);
        setKodeCOAAngsuran(
          `${penerimaan.data.coa.kodeCOA} - ${penerimaan.data.coa.namaCOA}`
        );
      } else {
        // Get Data Penerimaan
        const penerimaan = await axios.post(
          `${tempUrl}/penerimaanFidusiasByPengajuan`,
          {
            pengajuanFidusiaId: id,
            no: idAngsuranChild,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        if (penerimaan.data !== null) {
          setIsPost(penerimaan.data.isPost);
        }
        setPenerimaanId(penerimaan.data.id);
        setKodeCOAAngsuran(
          `${penerimaan.data.coa.kodeCOA} - ${penerimaan.data.coa.namaCOA}`
        );
      }
    }
  };

  const updatePenerimaanFidusia = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeCOAAngsuran.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(
            `${tempUrl}/updatePenerimaanFidusia/${penerimaanId}`,
            {
              kodeCOA: kodeCOAAngsuran.split(" ", 1)[0],
              kodeCabang: user.cabang.id,
              userIdUpdate: user.id,
              _id: user.id,
              token: user.token,
            }
          );
          setLoading(false);
          navigate(
            `/daftarAngsuranFidusia/angsuranFidusia/${id}/${idAngsuranChild}`
          );
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Box>
        <Typography color="#757575">Fidusia</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Ubah Angsuran Ke-
        </Typography>
        <Divider sx={dividerStyle} />

        <Form
          noValidate
          validated={validated}
          onSubmit={updatePenerimaanFidusia}
        >
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={idAngsuranChild} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Jatuh Tempo :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglJatuhTempo} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kas <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={coaOptions}
                        renderInput={(params) => (
                          <TextField
                            error={
                              error && kodeCOAAngsuran.length === 0 && true
                            }
                            size="small"
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => {
                          setKodeCOAAngsuran(value);
                        }}
                        // defaultValue={kodeCOAAngsuran}
                        value={kodeCOAAngsuran}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran Modal :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={angModal.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran Bunga :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={angBunga.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran / Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={angPerBulan.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Bayar :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglBayar} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterlambatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={`${keterlambatan} hari`} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Kwitansi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKwitansi} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={keterangan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Denda :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={denda.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Hutang Denda :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hutangDenda.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Potongan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={potongan.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Total Piutang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={totalPiutang.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Total Bayar :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={totalBayar.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Bayar :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={bayar.toLocaleString("de-DE")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                navigate(
                  `/daftarAngsuranFidusia/angsuranFidusia/${id}/${idAngsuranChild}`
                );
              }}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Edit
            </Button>
          </Box>
        </Form>
      </Box>
    </Container>
  );
};

export default UbahAngsuranFidusiaChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
  mb: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    md: 4,
  },
  marginTop: {
    md: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cetakContainer = {
  width: "300px",
  fontSize: "14px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "1000px",
  padding: "20px",
};

const cetakWrapper = {
  display: "flex",
  justifyContent: "space-between",
};

const cetakWrapperNoMarginTop = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
};

const cetakCenter = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakCenterBold = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
};

const cetakText = {
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakWrapperTotal = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
  marginBottom: "10px",
};

const cetakWrapperText = {
  display: "flex",
  justifyContent: "space-around",
};

const cetakWrapperTextName = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "80px",
};

const colorRed = {
  color: "red",
};
