import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const Jurnal = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [kasirId, setKasirId] = useState("");
  const [namaKasir, setNamaKasir] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [namaCOA, setNamaCOA] = useState("");

  const [coas, setCoas] = useState([]);
  const [kasirs, setKasirs] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapPenerimaansData, setLapPenerimaansData] = useState([]);
  const [value, setValue] = useState("semua");
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);

  const handleChangeLaporan = (event) => {
    setValue(event.target.value);
    setPreviewPdf(false);
  };

  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
    id: coa.id,
  }));
  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanJurnal",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 20;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      setTimeout(() => {
        tempPersenLoading += 20;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");

      let neracas = await axios.post(`${tempUrlFico}/jurnals`, {
        value,
        coaKasKasir: setting.coaKasKasir,
        piutangNasabahGadai: setting.piutangNasabahGadai,
        pendapatanAdministrasiGadai: setting.pendapatanAdministrasiGadai,
        kasPenerimaanGadai: setting.kasPenerimaanGadai,
        pendapatanBungaPinjGadai: setting.pendapatanBungaPinjGadai,
        pendDendaPiutangGadai: setting.pendDendaPiutangGadai,
        pendPenjualanBarangGadai: setting.pendPenjualanBarangGadai,
        piutangNasabahFidusia: setting.piutangNasabahFidusia,
        pendapatanBungaPinjFidusia: setting.pendapatanBungaPinjFidusia,
        pendDendaPiutangFidusia: setting.pendDendaPiutangFidusia,
        titipanNasabah: setting.titipanNasabah,
        biayaKerugian: setting.biayaKerugian,
        pendPinaltiPiutangFidusia: setting.pendPinaltiPiutangFidusia,
        dariTanggal,
        sampaiTanggal,
        kodeCabang,
        kasirId,
        kodeCOA,
        _id: user.id,
        token: user.token,
      });
      setLapPenerimaansData(neracas.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "3000");
    }
  };

  useEffect(() => {
    getCoasData();
    getKasirsData();
    getCabangsData();
  }, []);

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAs`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
    fontWeight: 500,
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];
  let tempTotalDebet = 0;
  let tempTotalKredit = 0;
  let tempNumber = 1;

  for (let j = 0; j < lapPenerimaansData.length; j++) {
    if (kodeCOA && value === "semua") {
      if (
        lapPenerimaansData[j][0].coaId == kodeCOA ||
        lapPenerimaansData[j][1].coaId == kodeCOA
      ) {
        if (lapPenerimaansData[j][0].jenis == "D") {
          tempTotalDebet += lapPenerimaansData[j][0].jumlah;
        } else {
          tempTotalKredit += lapPenerimaansData[j][0].jumlah;
        }
        if (lapPenerimaansData[j][1].jenis == "D") {
          tempTotalDebet += lapPenerimaansData[j][1].jumlah;
        } else {
          tempTotalKredit += lapPenerimaansData[j][1].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {tempNumber}
            </td>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {lapPenerimaansData[j][0].tglJurnalFormat}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noKwitansi}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noBukti}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noPerjanjian}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].coa}
            </td>
            <td style={textNumberRight}>
              {lapPenerimaansData[j][0].jumlah.toLocaleString("en-EN")}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][1].coa}
            </td>
            <td style={textNumberRight}>
              {lapPenerimaansData[j][1].jumlah.toLocaleString("en-EN")}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].keterangan}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].kasir}
            </td>
          </tr>
        );
        tempNumber++;
      }
    } else if (kodeCOA && value === "perAccount") {
      for (let i = 0; i < lapPenerimaansData[j].length; i++) {
        let perAccountFilter = false;

        for (let data of lapPenerimaansData[j]) {
          if (data.coaId == kodeCOA) {
            perAccountFilter = true;
          }
        }

        if (perAccountFilter) {
          if (lapPenerimaansData[j][i].jumlah > 0) {
            if (lapPenerimaansData[j][i].jenis == "D") {
              tempTotalDebet += lapPenerimaansData[j][i].jumlah;
              list.push(
                <tr style={tableText}>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {tempNumber}
                  </td>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {lapPenerimaansData[j][i].tglJurnalFormat}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noKwitansi}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noBukti}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noPerjanjian}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].coa}
                  </td>
                  <td style={textNumberRight}>
                    {lapPenerimaansData[j][i].jumlah.toLocaleString("en-EN")}
                  </td>
                  <td style={{ border: "1px solid black" }}></td>
                  <td style={textNumberRight}></td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].keterangan}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].kasir}
                  </td>
                </tr>
              );
              tempNumber++;
            } else {
              tempTotalKredit += lapPenerimaansData[j][i].jumlah;
              list.push(
                <tr style={tableText}>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {tempNumber}
                  </td>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {lapPenerimaansData[j][i].tglJurnalFormat}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noKwitansi}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noBukti}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noPerjanjian}
                  </td>
                  <td style={{ border: "1px solid black" }}></td>
                  <td style={textNumberRight}></td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].coa}
                  </td>
                  <td style={textNumberRight}>
                    {lapPenerimaansData[j][i].jumlah.toLocaleString("en-EN")}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].keterangan}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].kasir}
                  </td>
                </tr>
              );
              tempNumber++;
            }
          }
        }
      }
    } else {
      if (lapPenerimaansData[j][0].kategori !== "PENERIMAAN FIDUSIA") {
        if (lapPenerimaansData[j][0].jenis == "D") {
          tempTotalDebet += lapPenerimaansData[j][0].jumlah;
        } else {
          tempTotalKredit += lapPenerimaansData[j][0].jumlah;
        }
        if (lapPenerimaansData[j][1].jenis == "D") {
          tempTotalDebet += lapPenerimaansData[j][1].jumlah;
        } else {
          tempTotalKredit += lapPenerimaansData[j][0].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {tempNumber}
            </td>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {lapPenerimaansData[j][0].tglJurnalFormat}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noKwitansi}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noBukti}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].noPerjanjian}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].coa}
            </td>
            <td style={textNumberRight}>
              {lapPenerimaansData[j][0].jumlah.toLocaleString("en-EN")}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][1].coa}
            </td>
            <td style={textNumberRight}>
              {lapPenerimaansData[j][1].jumlah.toLocaleString("en-EN")}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].keterangan}
            </td>
            <td style={{ border: "1px solid black" }}>
              {lapPenerimaansData[j][0].kasir}
            </td>
          </tr>
        );
        tempNumber++;
      } else {
        for (let i = 0; i < lapPenerimaansData[j].length; i++) {
          if (lapPenerimaansData[j][i].jumlah > 0) {
            if (lapPenerimaansData[j][i].jenis == "D") {
              tempTotalDebet += lapPenerimaansData[j][i].jumlah;
              list.push(
                <tr style={tableText}>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {tempNumber}
                  </td>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {lapPenerimaansData[j][i].tglJurnalFormat}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noKwitansi}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noBukti}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noPerjanjian}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].coa}
                  </td>
                  <td style={textNumberRight}>
                    {lapPenerimaansData[j][i].jumlah.toLocaleString("en-EN")}
                  </td>
                  <td style={{ border: "1px solid black" }}></td>
                  <td style={textNumberRight}></td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].keterangan}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].kasir}
                  </td>
                </tr>
              );
              tempNumber++;
            } else {
              tempTotalKredit += lapPenerimaansData[j][i].jumlah;
              list.push(
                <tr style={tableText}>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {tempNumber}
                  </td>
                  <td
                    style={{ paddingLeft: "15px", border: "1px solid black" }}
                  >
                    {lapPenerimaansData[j][i].tglJurnalFormat}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noKwitansi}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noBukti}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].noPerjanjian}
                  </td>
                  <td style={{ border: "1px solid black" }}></td>
                  <td style={textNumberRight}></td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].coa}
                  </td>
                  <td style={textNumberRight}>
                    {lapPenerimaansData[j][i].jumlah.toLocaleString("en-EN")}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].keterangan}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {lapPenerimaansData[j][i].kasir}
                  </td>
                </tr>
              );
              tempNumber++;
            }
          }
        }
      }
    }
  }

  list.push(
    <tr style={textBold}>
      <td
        style={{ paddingLeft: "15px", border: "1px solid black" }}
        colspan="6"
      >
        Total
      </td>
      <td style={textNumberRightBold}>
        {tempTotalDebet.toLocaleString("en-EN")}
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={textNumberRightBold}>
        {tempTotalKredit.toLocaleString("en-EN")}
      </td>
      <td style={{ border: "1px solid black" }} colspan="2"></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanJurnal",
    sheet: "LaporanJurnal",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Jurnal</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <FormControl sx={{ marginTop: 1 }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Tampilan Kode Akun
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="semua"
              value={value}
              onChange={handleChangeLaporan}
            >
              <FormControlLabel
                value="semua"
                control={<Radio />}
                label="Semua"
              />
              <FormControlLabel
                value="perAccount"
                control={<Radio />}
                label="Per Account"
              />
            </RadioGroup>
          </FormControl>
          <hr />

          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kodeCabang.length === 0 && true}
                        size="small"
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKodeCabang(value.id.split(" ", 1)[0]);
                        setNamaCabang(value.id);
                      } else {
                        setKodeCabang("");
                        setNamaCabang("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kodeCabang}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kode Akun :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={coaOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kodeCOA.length === 0 && true}
                        size="small"
                        placeholder="SEMUA AKUN"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKodeCOA(value.id);
                        setNamaCOA(value.label);
                      } else {
                        setKodeCOA("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kodeCOA}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Jurnal
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()}`}
          </p>
          <p>
            Sampai Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Akun : {namaCOA}</p>
          <p>Cabang : {namaCabang}</p>
          <p>Kasir : {namaKasir}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}>No. Kwitansi</th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }} colspan="2">
                  Debet
                </th>
                <th style={{ border: "1px solid black" }} colspan="2">
                  Kredit
                </th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Tanggal</th>
                <th style={{ border: "1px solid black" }}>Bukti</th>
                <th style={{ border: "1px solid black" }}>No. SBG</th>
                <th style={{ border: "1px solid black" }}>No. Perjanjian</th>
                <th style={{ border: "1px solid black" }}>Akun</th>
                <th style={{ border: "1px solid black" }}>Jumlah</th>
                <th style={{ border: "1px solid black" }}>Akun</th>
                <th style={{ border: "1px solid black" }}>Jumlah</th>
                <th style={{ border: "1px solid black" }}>Keterangan</th>
                <th style={{ border: "1px solid black" }}>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default Jurnal;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "10px",
  fontFamily: "sans-serif",
  width: "1000px",
  fontWeight: 600,
};
