import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const Unposting = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorInput, setOpenErrorInput] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleCloseErrorInput = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorInput(false);
  };

  const unposting = async (e) => {
    var lastday = function (y, m) {
      return new Date(y, m, 0).getDate();
    };
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        let isDariTglBiggerThanSampaiTgl = dariTanggal > sampaiTanggal;
        if (isDariTglBiggerThanSampaiTgl) {
          setOpenErrorInput(true);
        } else {
          setLoading(true);
          let tempPersenLoading = parseInt(persenLoading);
          // Delete Neraca Saldo
          const allNeracaSaldo = await axios.post(
            `${tempUrlFico}/neracaSaldoAboveTanggal`,
            {
              dariTanggal,
              sampaiTanggal,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          const deletedNeracaSaldoAboveTanggal = await axios.post(
            `${tempUrlFico}/deleteNeracaSaldoAboveTanggal`,
            {
              dariTanggal,
              sampaiTanggal,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          tempPersenLoading += 50;
          setPersenLoading(tempPersenLoading.toString());
          const groupedNeracaSaldo = allNeracaSaldo.data.reduce(
            (group, neracaSaldo) => {
              const { tglNeracaSaldo } = neracaSaldo;
              group[tglNeracaSaldo] = group[tglNeracaSaldo] ?? [];
              group[tglNeracaSaldo].push(neracaSaldo);
              return group;
            },
            {}
          );
          let keys = Object.keys(groupedNeracaSaldo);

          tempPersenLoading += 50;
          setPersenLoading(tempPersenLoading.toString());

          if (allNeracaSaldo.data.length === 0) {
            // Make Last Neraca Saldo
            await axios.post(`${tempUrlFico}/saveLastNeracaSaldo`, {
              dariTanggal: user.tutupperiode.dariTanggal,
              sampaiTanggal: user.tutupperiode.sampaiTanggal,
              userIdInput: user.id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            });
          } else {
            // for (let i = 0; i < Object.keys(groupedNeracaSaldo).length; i++) {
            // Make Last Neraca Saldo
            await axios.post(`${tempUrlFico}/saveLastNeracaSaldo`, {
              dariTanggal: user.tutupperiode.dariTanggal,
              sampaiTanggal: user.tutupperiode.sampaiTanggal,
              userIdInput: user.id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            });
            // Jurnal Unposting
            await axios.post(`${tempUrlFico}/jurnalUnpostingRebuild`, {
              dariTanggal,
              sampaiTanggal,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            });

            dariTanggal = new Date(
              dariTanggal.setMonth(dariTanggal.getMonth() + 1)
            );
            sampaiTanggal =
              dariTanggal.getFullYear() +
              "-" +
              (dariTanggal.getMonth() + 1) +
              "-" +
              lastday("1", dariTanggal.getMonth() + 1);
            // }
          }
          setPersenLoading("0");

          setLoading(false);
          setOpen(true);
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpenError(!openError);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Unposting</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Card>
        <Card.Header>Rentang Tanggal</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={unposting}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={dariTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setDariTanggal(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setSampaiTanggal(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                startIcon={<DriveFileRenameOutlineIcon />}
                type="submit"
                disabled={
                  new Date(user.tutupperiode.dariTanggal) <
                  new Date(setting.programDariTanggal)
                    ? true
                    : false
                }
              >
                Unposting
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal berhasil diunposting!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorInput}
        autoHideDuration={6000}
        onClose={handleCloseErrorInput}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseErrorInput}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Sampai Tanggal tidak boleh lebih besar Dari Tanggal
        </Alert>
      </Snackbar>
      {error && (
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert onClose={handleCloseError} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default Unposting;

const spacingTop = {
  mt: 4,
};

const subTitleText = {
  fontWeight: "900",
};

const alertBox = {
  width: "100%",
};
