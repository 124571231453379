import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import LogoPerusahaanSpFidusiaBlackAndWhite from "../../../assets/LogoPerusahaanSpFidusiaBlackAndWhite.jpg";
import jsPDF from "jspdf";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TampilSp1Fidusia = () => {
  const { screenSize } = useStateContext();
  const reportTemplateRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);

  // Data Customer
  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [ketResikoAju, setKetResikoAju] = useState("");

  // Data Penjamin
  const [nikPenjamin, setNikPenjamin] = useState("");
  const [noKkPenjamin, setNoKkPenjamin] = useState("");
  const [namaPenjamin, setNamaPenjamin] = useState("");
  const [tempatLahirPenjamin, setTempatLahirPenjamin] = useState("");
  const [tanggalLahirPenjamin, setTanggalLahirPenjamin] = useState("");
  const [jenisKelaminPenjamin, setJenisKelaminPenjamin] = useState("");
  const [agamaPenjamin, setAgamaPenjamin] = useState("");
  const [noTeleponPenjamin, setNoTeleponPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [alamatRefRegister, setAlamatRefRegister] = useState("");
  const [teleponRefRegister, setTeleponRefRegister] = useState("");
  const [alamatPenjamin, setAlamatPenjamin] = useState("");
  const [kodeProvinsiPenjamin, setKodeProvinsiPenjamin] = useState("");
  const [kodeKabupatenPenjamin, setKodeKabupatenPenjamin] = useState("");
  const [kodeKecamatanPenjamin, setKodeKecamatanPenjamin] = useState("");
  const [kodeKelurahanPenjamin, setKodeKelurahanPenjamin] = useState("");
  const [kodePosPenjamin, setKodePosPenjamin] = useState("");
  const [statusPerkawinanPenjamin, setStatusPerkawinanPenjamin] = useState("");
  const [pekerjaanPenjamin, setPekerjaanPenjamin] = useState("");
  const [kewarganegaraanPenjamin, setKewarganegaraanPenjamin] = useState("");

  // Data Pinjaman
  const [noAju, setNoAju] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [kategori, setKategori] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoText, setTglJatuhTempoText] = useState("");
  const [tglJatuhTempoSp, setTglJatuhTempoSp] = useState("");
  const [keterlambatan, setKeterlambatan] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState(0);
  const [angPerBulan, setAngPerBulan] = useState(0);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tanggalAju, setTanggalAju] = useState("");
  const [tenor, setTenor] = useState(0);
  const [bungaPerTahun, setBungaPerTahun] = useState(0);
  const [totalPiutang, setTotalPiutang] = useState(0);
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  // Data Kendaraan
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [kodeWarna, setKodeWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [bpkbAN, setBpkbAN] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [isi, setIsi] = useState("");
  const [merk, setMerk] = useState("");
  const [nopol, setNopol] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("");
  const [taksiran, setTaksiran] = useState("");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("");

  // Data Approval
  const [noSbg, setNoSbg] = useState("");
  const [tglKontrak, setTglKontrak] = useState("");
  const [noKwitansi, setNoKwitansi] = useState();

  // Asuransi
  const [noFidusia, setNoFidusia] = useState("");
  const [nilaiPertanggungan, setNilaiPertanggungan] = useState(0);
  const [noAsuransi, setNoAsuransi] = useState("");

  // Data SP1
  const [noSurat, setNoSurat] = useState("");
  const [tglSurat, setTglSurat] = useState("");
  const [denda, setDenda] = useState("0");
  const [dendaPerHari, setDendaPerHari] = useState("0");
  const [total, setTotal] = useState("0");
  const [no, setNo] = useState("");
  const [kodeCabang, setKodeCabang] = useState("");

  const navigate = useNavigate();
  const [previewPdf, setPreviewPdf] = useState(false);
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  let findMonth = (month) => {
    let tempDateName;
    switch (month) {
      case 1:
        tempDateName = "JANUARI";
        break;
      case 2:
        tempDateName = "FEBRUARI";
        break;
      case 3:
        tempDateName = "MARET";
        break;
      case 4:
        tempDateName = "APRIL";
        break;
      case 5:
        tempDateName = "MEI";
        break;
      case 6:
        tempDateName = "JUNI";
        break;
      case 7:
        tempDateName = "JULI";
        break;
      case 8:
        tempDateName = "AGUSTUS";
        break;
      case 9:
        tempDateName = "SEPTEMBER";
        break;
      case 10:
        tempDateName = "OKTOBER";
        break;
      case 11:
        tempDateName = "NOVEMBER";
        break;
      case 12:
        tempDateName = "DESEMBER";
        break;
      default:
        break;
    }
    return tempDateName;
  };

  let tempToday = new Date();
  let tempTodayName = `
  ${tempToday.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })} ${findMonth(tempToday.getMonth() + 1)} ${tempToday.getFullYear()}
  `;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const countDateDuration = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    setTglAngAkhir(formatDate(final));
  };

  const countDateDurationReview = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    setTglAngAkhir(formatDate(final));
  };

  useEffect(() => {
    getSp1ById();
  }, []);

  const getSp1ById = async () => {
    setLoading(true);
    const findSp1 = await axios.post(`${tempUrl}/sp1s/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoSurat(findSp1.data.noSurat);
    setTglSurat(formatDate(findSp1.data.tglSurat));
    setDenda(findSp1.data.denda);
    setTotal(findSp1.data.total);
    setNo(findSp1.data.no);
    let newTglJatuhTempo = new Date(findSp1.data.angsuranfidusia.tglJatuhTempo);
    setTglJatuhTempo(formatDate(findSp1.data.angsuranfidusia.tglJatuhTempo));

    setTglJatuhTempoText(`
    ${newTglJatuhTempo.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })} ${findMonth(
      newTglJatuhTempo.getMonth() + 1
    )} ${newTglJatuhTempo.getFullYear()}
    `);

    let tempTglJatuhTempoSp = new Date(
      findSp1.data.angsuranfidusia.tglJatuhTempo
    );
    let tempTglJatuhTempoSpAddDays = tempTglJatuhTempoSp.setDate(
      newTglJatuhTempo.getDate() + setting.sp1
    );
    tempTglJatuhTempoSp = new Date(tempTglJatuhTempoSpAddDays);
    setTglJatuhTempoSp(`
    ${tempTglJatuhTempoSp.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })} ${findMonth(
      tempTglJatuhTempoSp.getMonth() + 1
    )} ${tempTglJatuhTempoSp.getFullYear()}
    `);

    let tempDateTglSurat = new Date(findSp1.data.tglSurat);
    let tempDateTglJatuhTempo = new Date(
      findSp1.data.angsuranfidusia.tglJatuhTempo
    );
    let tempKeterlambatan = 0;
    const diffTime = Math.abs(tempDateTglSurat - tempDateTglJatuhTempo);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (tempDateTglSurat > tempDateTglJatuhTempo) {
      tempKeterlambatan = diffDays;
      setKeterlambatan(tempKeterlambatan);
    }

    let tempDendaPerHari = findSp1.data.denda / tempKeterlambatan;
    setDendaPerHari(tempDendaPerHari);

    const response = await axios.post(
      `${tempUrl}/pengajuanFidusias/${findSp1.data.angsuranfidusia.pengajuanfidusia.id}`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    // Data Customer
    setCifCustomer(response.data.customer.cifCustomer);
    setNikCustomer(response.data.customer.nikCustomer);
    setNoKkCustomer(response.data.customer.noKkCustomer);
    setNamaCustomer(response.data.customer.namaCustomer);
    setTempatLahirCustomer(response.data.customer.tempatLahirCustomer);
    setTanggalLahirCustomer(
      formatDate(response.data.customer.tanggalLahirCustomer)
    );
    setJenisKelaminCustomer(response.data.customer.jenisKelaminCustomer);
    setAgamaCustomer(response.data.customer.agamaCustomer);
    setNoTeleponCustomer(response.data.customer.alamatCustomer);
    setAlamatCustomer(response.data.customer.alamatCustomer);
    setAlamatTTCustomer(response.data.customer.alamatTTCustomer);
    setKodeProvinsi(response.data.customer.provinsis.namaProvinsi);
    setKodeProvinsiTT(response.data.customer.provinsisTT.namaProvinsi);
    setKodeKabupaten(response.data.customer.kabupaten.namaKabupaten);
    setKodeKabupatenTT(response.data.customer.kabupatenTT.namaKabupaten);
    setKodeKecamatan(response.data.customer.kecamatan.namaKecamatan);
    setKodeKecamatanTT(response.data.customer.kecamatanTT.namaKecamatan);
    setKodeKelurahan(response.data.customer.kelurahan.namaKelurahan);
    setKodeKelurahanTT(response.data.customer.kelurahanTT.namaKelurahan);
    setKodePos(response.data.customer.kelurahan.kodePos);
    setKodePosTT(response.data.customer.kelurahanTT.kodePos);
    setStatusPerkawinanCustomer(
      response.data.customer.statusPerkawinanCustomer
    );
    setPekerjaanCustomer(response.data.customer.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.customer.kewarganegaraanCustomer);
    setJenisResikoAju(response.data.jenisResikoAju);
    setKetResikoAju(response.data.ketResikoAju);

    // Data Penjamin
    setNikPenjamin(response.data.nikPenjamin);
    setNoKkPenjamin(response.data.noKkPenjamin);
    setNamaPenjamin(response.data.namaPenjamin);
    setTempatLahirPenjamin(response.data.tempatLahirPenjamin);
    setTanggalLahirPenjamin(formatDate(response.data.tanggalLahirPenjamin));
    setJenisKelaminPenjamin(response.data.jenisKelaminPenjamin);
    setAgamaPenjamin(response.data.agamaPenjamin);
    setNoTeleponPenjamin(response.data.noTeleponPenjamin);
    setNamaRefRegister(response.data.namaRefRegister);
    setAlamatRefRegister(response.data.alamatRefRegister);
    setTeleponRefRegister(response.data.teleponRefRegister);
    setAlamatPenjamin(response.data.alamatPenjamin);
    setKodeProvinsiPenjamin(response.data.provinsisPenjamin.namaProvinsi);
    setKodeKabupatenPenjamin(response.data.kabupatenPenjamin.namaKabupaten);
    setKodeKecamatanPenjamin(response.data.kecamatanPenjamin.namaKecamatan);
    setKodeKelurahanPenjamin(response.data.kelurahanPenjamin.namaKelurahan);
    setKodePosPenjamin(response.data.kelurahanPenjamin.kodePos);
    setStatusPerkawinanPenjamin(response.data.statusPerkawinanPenjamin);
    setPekerjaanPenjamin(response.data.pekerjaanPenjamin);
    setKewarganegaraanPenjamin(response.data.kewarganegaraanPenjamin);

    // Data Pinjaman
    setNoAju(response.data.noAju);
    setNoPerjanjian(response.data.noPerjanjian);
    setKategori(response.data.kategori);
    setPinjamanAju(response.data.pinjamanAju);
    setAngPerBulan(response.data.angPerBulan);
    setKodeMarketing(response.data.marketing.kodeMarketing);
    setTanggalAju(formatDate(response.data.tanggalAju));
    setTglAng(formatDate(response.data.tglAng));
    countDateDurationReview(response.data.tenor, response.data.tglAng);
    setNoPerjanjian(response.data.noPerjanjian);
    setTenor(response.data.tenor);
    setBungaPerTahun(response.data.bungaPerTahun);
    setTotalPiutang(response.data.totalPiutang);
    setKodeSurveyor(response.data.surveyor.kodeSurveyor);
    setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);

    // Data Kendaraan
    setKodeTipe(response.data.tipe.kodeTipe);
    setTahun(response.data.tahun);
    setNoRangka(response.data.noRangka);
    setKodeWarna(response.data.warna.namaWarna);
    setNoBpkb(response.data.noBpkb);
    setTglStnk(formatDate(response.data.tglStnk));
    setBpkbAN(response.data.bpkbAN);
    setNoMesin(response.data.noMesin);
    setIsi(response.data.isi);
    setMerk(response.data.merk);
    setNopol(response.data.nopol);

    // Data Penilaian
    setHargaPasarSetempatOtr(response.data.hargaPasarSetempatOtr);
    setTaksiran(response.data.taksiran);
    setMaksimalPemberianPinjaman(response.data.maksimalPemberianPinjaman);

    // Data Asuransi
    setNoFidusia(response.data.noFidusia);
    setNilaiPertanggungan(response.data.nilaiPertanggungan);
    setNoAsuransi(response.data.noAsuransi);

    // Data Approval
    setNoSbg(response.data.noSbg);
    setTglKontrak(formatDate(response.data.tglKontrak));
    setNoKwitansi(response.data.noKwitansi);
    countDateDuration(response.data.tenor, response.data.tglAng);

    setLoading(false);
  };

  const deleteSp1 = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteSp1/${id}`, {
        noAju,
        no,
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarSp1Fidusia");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Data SP-1</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarSp1Fidusia")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdf(!previewPdf);
            }}
          >
            LIHAT
          </Button>
        </ButtonGroup>
      </Box>

      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} style={cetakContainer}>
            <div style={{ marginLeft: "70px" }}>
              <img
                src={LogoPerusahaanSpFidusiaBlackAndWhite}
                alt="LogoPerusahaanSpFidusiaBlackAndWhite"
                style={{ width: "800px" }}
              />
            </div>
            <hr style={{ border: "2px solid black" }} />
            <div style={{ paddingRight: "80px", paddingLeft: "80px" }}>
              <p style={{ textAlign: "right", marginTop: "40px" }}>
                {setting.provinsiPerusahaan}, {tempTodayName}
              </p>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No</p>
                </div>
                <div style={{ marginLeft: "80px" }}>
                  <p style={cetakText}>: {noSurat}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Perihal</p>
                </div>
                <div style={{ marginLeft: "50px" }}>
                  <b style={cetakText}>: SURAT PERINGATAN I</b>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Lampiran</p>
                </div>
                <div style={{ marginLeft: "30px" }}>
                  <b style={cetakText}>: 1 lembar</b>
                </div>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
              >
                <p>Kepada Yth, </p>
                <p style={cetakText}>
                  <b>Bapak/Ibu {namaCustomer}</b>
                </p>
                <p style={cetakText}>
                  <b>Nasabah {setting.namaPerusahaan}</b>
                </p>
                <p style={cetakText}>
                  <b>Di Tempat</b>
                </p>
              </div>
              <p style={{ marginBottom: "40px" }}>Dengan Hormat, </p>
              <div>
                <p>
                  Bersama surat ini kami beritahukan kepada Bapak/Ibu{" "}
                  <b>{namaCustomer}</b> dengan <b>{noPerjanjian}</b> selaku
                  Nasabah {setting.namaPerusahaan}, bahwa menurut pembukuan
                  kami, anda masih menunggak pembayaran angsuran selama{" "}
                  {setting.sp1} Hari yakni dari Tanggal Jatuh Tempo{" "}
                  <b>
                    {tglJatuhTempoText} s.d {tglJatuhTempoSp}
                  </b>
                  , sesuai jatuh tempo pembayaran di {setting.namaPerusahaan}.
                </p>
              </div>
              <div>
                <p>
                  Kami harap anda dapat segera menyelesaikan kewajiban
                  pembayaran angsuran kredit pergadaian 1 unit{" "}
                  {kategori === "KENDARAAN RODA 2" ? "Motor" : "Mobil"} untuk 1
                  bulan pembayaran selambat-lambatnya {setting.sp1LambatBayar}{" "}
                  hari sejak dari tanggal jatuh tempo angsuran (
                  <b>{tglJatuhTempoText}</b>), apabila tidak ada pembayaran /
                  pelunasan tunggakan, maka kami akan susulkan dengan{" "}
                  <b>Surat Peringatan II.</b>
                </p>
              </div>
              <div style={{ marginTop: "30px", marginBottom: "50px" }}>
                <p>
                  Demikian surat ini kami sampaikan, atas perhatian dan
                  kerjasamanya kami ucapkan terima kasih.
                </p>
              </div>
              <div style={{ marginBottom: "120px" }}>
                <p style={cetakText}>Hormat Kami,</p>
                <b>{setting.namaPerusahaan}</b>
              </div>
              <div style={{ marginBottom: "30px" }}>
                <p style={cetakTextUnderline}>
                  <b>{setting.direkturUtama}</b>
                </p>
                <b>Direktur Utama</b>
              </div>
              <div>
                <p style={cetakText}>
                  <b>
                    NB: Jika Bapak/Ibu sudah melakukan pembayaran tunggakan
                    angsuran mohon abaikan Surat Peringatan ini.
                  </b>
                </p>
                <b style={cetakText}>Terima Kasih.</b>
              </div>
            </div>
          </div>
        </div>
      )}

      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          {id && (
            <>
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </>
          )}
        </ButtonGroup>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${noAju}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteSp1(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Form>
        <Card>
          <Card.Header>Data Pribadi Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          required
                          value={cifCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKkCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={agamaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisResikoAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={ketResikoAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>Tempat Tinggal</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatTTCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupatenTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsiTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePosTT} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penjamin</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noKkPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={jenisKelaminPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={agamaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={statusPerkawinanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kewarganegaraanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={alamatRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={teleponRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKelurahanPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKecamatanPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKabupatenPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeProvinsiPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodePosPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Perjanjian :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noPerjanjian} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pinjaman Rp. :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pinjamanAju.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={angPerBulan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tenor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bunga/Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglAng}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          customInput={<Form.Control />}
                          value={tglAngAkhir}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kategori :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kategori}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Surveyor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeSurveyor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeMarketing}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Kendaraan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeTipe}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tahun}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Polisi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nopol}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeWarna}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Merk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={merk} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CC :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={isi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Stnk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglStnk}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. BPKP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noBpkb}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        BPKP A/N :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bpkbAN}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={hargaPasarSetempatOtr.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={taksiran.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={maksimalPemberianPinjaman.toLocaleString(
                            "de-DE"
                          )}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Asuransi</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noFidusia} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nilai Pertanggungan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nilaiPertanggungan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Asuransi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAsuransi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Approval</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Perjanjian :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noPerjanjian} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kontrak :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglKontrak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Ang 1 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglAng} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tenor :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tenor} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Ang Akhir :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglAngAkhir} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pinjamanAju.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Tahun Rp. :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${bungaPerTahun} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (parseFloat(bungaPerTahun) * parseFloat(pinjamanAju)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bunga Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        (angPerBulan - pinjamanAju / tenor) *
                        tenor
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data SP-1</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran Ke- :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={no} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Surat :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noSurat} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Surat :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglSurat} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jatuh Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJatuhTempo} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterlambatan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={`${keterlambatan.toLocaleString("de-DE")} hari`}
                      readOnly
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran/Bulan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angPerBulan.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda/hari :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={dendaPerHari.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={denda.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(angPerBulan + denda).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </Container>
  );
};

export default TampilSp1Fidusia;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cetakContainer = {
  width: "1100px",
  fontSize: "16px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "1000px",
  padding: "20px",
};

const cetakText = {
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakTextUnderline = {
  marginTop: "0px",
  marginBottom: "0px",
  textDecoration: "underline",
};
