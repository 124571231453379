import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Paper,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahKasMasuk = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [inputTglKasMasuk, setInputTglKasMasuk] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [kodeCOA, setKodeCOA] = useState("");
  const [keterangan, setKeterangan] = useState("");

  // Data Child
  const [totalTransaksi, setTotalTransaksi] = useState(0);
  const [reloadListKasMasukChild, setReloadListKasMasukChild] = useState(0);
  const [listKasMasukChild, setListKasMasukChild] = useState([
    {
      keterangan: "",
      kodeCOA: "",
      jumlah: "",
    },
  ]);

  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [error, setError] = useState(false);
  const [COAsData, setCOAsData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  let coaOptions = COAsData.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    findDefaultDate();
    getNextKodeKasMasuk();
    getCOAsData();
  }, []);

  const findDefaultDate = async () => {
    let newPeriodeAwal = new Date(user.tutupperiode.dariTanggal);
    let newPeriodeAkhir = new Date(user.tutupperiode.sampaiTanggal);
    let newToday = new Date();

    let isDateBetween =
      newToday >= newPeriodeAwal && newToday <= newPeriodeAkhir;

    if (isDateBetween) {
      // Default Date Today
      setInputTglKasMasuk(new Date());
    }
  };

  const getNextKodeKasMasuk = async () => {
    setLoading(true);
    const nextKodeKasMasuk = await axios.post(
      `${tempUrlFico}/kasMasuksNextKode`,
      {
        dariTanggal: user.tutupperiode.dariTanggal,
        sampaiTanggal: user.tutupperiode.sampaiTanggal,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setNoBukti(nextKodeKasMasuk.data);
    setLoading(false);
  };

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKas`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const saveKasMasuk = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeCOA.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        await axios.post(`${tempUrlFico}/saveKasMasuk`, {
          dariTanggal: user.tutupperiode.dariTanggal,
          sampaiTanggal: user.tutupperiode.sampaiTanggal,
          tglKasMasuk: inputTglKasMasuk,
          coaId: tempCOA.data.id,
          keterangan,
          jenis: "D",

          listKasMasukChild,

          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarKasMasuk");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Finance</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Kas Masuk</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveKasMasuk}>
        <Card>
          <Card.Header>Kas Masuk</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode COA <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={kodeCOA}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenCOA()}
                      isInvalid={kodeCOA.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTglKasMasuk}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setInputTglKasMasuk(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      onChange={(e) => {
                        setKeterangan(e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Detail Transaksi</Card.Header>
          <Card.Body>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  bgcolor: "info.light",
                  textTransform: "none",
                  marginRight: 2,
                  marginBottom: 2,
                }}
                onClick={() => {
                  listKasMasukChild.push({
                    keterangan: "",
                    kodeCOA: "",
                    jumlah: "",
                  });
                  setReloadListKasMasukChild(Math.floor(Math.random() * 1000));
                }}
              >
                {"+ Input Transaksi"}
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Keterangan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      COA
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nominal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Hapus
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listKasMasukChild.map((kasMasukChild, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={kasMasukChild.keterangan}
                          onChange={(e) => {
                            kasMasukChild.keterangan =
                              e.target.value.toUpperCase();
                            setReloadListKasMasukChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              sx={{ width: "300px" }}
                              {...params}
                            />
                          )}
                          value={kasMasukChild.kodeCOA}
                          onChange={(e, value) => {
                            if (value) {
                              kasMasukChild.kodeCOA = value.label;
                              setReloadListKasMasukChild(
                                Math.floor(Math.random() * 1000)
                              );
                            } else {
                              kasMasukChild.kodeCOA = "";
                              setReloadListKasMasukChild(
                                Math.floor(Math.random() * 1000)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={kasMasukChild.jumlah}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            kasMasukChild.jumlah = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            let tempTotalTransaksi = 0;
                            for (let kasMasukChildData of listKasMasukChild) {
                              let tempJumlah = parseFloat(
                                kasMasukChildData.jumlah
                              );

                              if (isNaN(tempJumlah)) {
                                tempJumlah = 0;
                              }

                              tempTotalTransaksi += tempJumlah;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListKasMasukChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            listKasMasukChild.splice(index, 1);

                            let tempTotalTransaksi = 0;
                            for (let jurnalUmumChildData of listKasMasukChild) {
                              let tempJumlah = parseFloat(
                                jurnalUmumChildData.jumlah
                              );

                              if (isNaN(tempJumlah)) {
                                tempJumlah = 0;
                              }

                              tempTotalTransaksi += tempJumlah;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListKasMasukChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>
                      <Form.Control
                        value={totalTransaksi.toLocaleString("de-DE")}
                        disabled
                        readOnly
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/daftarKasMasuk")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahKasMasuk;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "800px",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const colorRed = {
  color: "red",
};
