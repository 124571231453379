import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { NumericFormat } from "react-number-format";

const UbahSetting = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // GADAI
  const [batasPinjamanDenda, setBatasPinjamanDenda] = useState("");
  const [denda1, setDenda1] = useState("");
  const [denda2, setDenda2] = useState("");
  const [lamaJtTempo, setLamaJtTempo] = useState("");
  const [kategoriPercepatan, setKategoriPercepatan] = useState("");
  const [hariBatasPercepatan1, setHariBatasPercepatan1] = useState("");
  const [diskonBatasPercepatan1, setDiskonBatasPercepatan1] = useState("");
  const [hariBatasPercepatan2, setHariBatasPercepatan2] = useState("");
  const [diskonBatasPercepatan2, setDiskonBatasPercepatan2] = useState("");
  const [hariBatasPercepatan3, setHariBatasPercepatan3] = useState("");
  const [diskonBatasPercepatan3, setDiskonBatasPercepatan3] = useState("");
  const [batasBunga, setBatasBunga] = useState("");
  const [batasJual, setBatasJual] = useState("");
  const [lamaHariBukaJual, setLamaHariBukaJual] = useState("");
  const [persenUntungJual, setPersenUntungJual] = useState("");
  const [feeAdmPerpanjanganGadai, setFeeAdmPerpanjanganGadai] = useState("");
  const [feeAdmGadai, setFeeAdmGadai] = useState("");
  const [isOpenFeeAdmGadai, setIsOpenFeeAdmGadai] = useState("");

  // COA GADAI
  const [coaKasKasir, setCoaKasKasir] = useState("");
  const [coaKodeKas, setCoaKodeKas] = useState("");
  const [piutangNasabahGadai, setPiutangNasabahGadai] = useState("");
  const [pendapatanAdministrasiGadai, setPendapatanAdministrasiGadai] =
    useState("");
  const [pendapatanBungaPinjGadai, setPendapatanBungaPinjGadai] = useState("");
  const [kasPenerimaanGadai, setKasPenerimaanGadai] = useState("");
  const [pendDendaPiutangGadai, setPendDendaPiutangGadai] = useState("");
  const [pendPinaltiPiutangFidusia, setPendPinaltiPiutangFidusia] =
    useState("");
  const [pendPenjualanBarangGadai, setPendPenjualanBarangGadai] = useState("");

  // FIDUSIA
  const [toleransiFidusia, setToleransiFidusia] = useState("");
  const [persenDendaFidusia, setPersenDendaFidusia] = useState("");
  const [sp1, setSp1] = useState("");
  const [sp2, setSp2] = useState("");
  const [sp3, setSp3] = useState("");
  const [sp1LambatBayar, setSp1LambatBayar] = useState("");
  const [sp2LambatBayar, setSp2LambatBayar] = useState("");
  const [sp3LambatBayar, setSp3LambatBayar] = useState("");
  const [sPanggilan, setSPanggilan] = useState("");

  // COA FIDUSIA
  const [kasPenerimaanFidusia, setKasPenerimaanFidusia] = useState("");
  const [piutangNasabahFidusia, setPiutangNasabahFidusia] = useState("");
  const [pendapatanBungaPinjFidusia, setPendapatanBungaPinjFidusia] =
    useState("");
  const [pendDendaPiutangFidusia, setPendDendaPiutangFidusia] = useState("");

  // COA
  const [labaRugiPeriodeBerjalan, setLabaRugiPeriodeBerjalan] = useState("");
  const [accountPembalance, setAccountPembalance] = useState("");
  const [groupKewajibanHutang, setGroupKewajibanHutang] = useState("");
  const [groupModalSaham, setGroupModalSaham] = useState("");
  const [groupAktivaLancar, setGroupAktivaLancar] = useState("");
  const [groupAktivaTetap, setGroupAktivaTetap] = useState("");
  const [kodeGroupPendapatan, setKodeGroupPendapatan] = useState("");
  const [kodeGroupBiayaAtasPendapatan, setKodeGroupBiayaAtasPendapatan] =
    useState("");
  const [kodeGroupPengeluaranOperasional, setKodeGroupPengeluaranOperasional] =
    useState("");
  const [kodeGroupPendapatanLainLain, setKodeGroupPendapatanLainLain] =
    useState("");
  const [kodeGroupBiayaLainLain, setKodeGroupBiayaLainLain] = useState("");
  const [subGroupCoaKas, setSubGroupCoaKas] = useState("");
  const [titipanNasabah, setTitipanNasabah] = useState("");
  const [biayaKerugian, setBiayaKerugian] = useState("");

  // COA OJK
  const [
    urutanCoaOjkLabaRugiJumlahPendapatan,
    setUrutanCoaOjkLabaRugiJumlahPendapatan,
  ] = useState("");
  const [urutanCoaOjkLabaRugiJumlahBeban, setUrutanCoaOjkLabaRugiJumlahBeban] =
    useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const isOpenFeeAdmGadaiOption = ["YA", "TIDAK"];

  useEffect(() => {
    getSettingById();
  }, []);

  const getSettingById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/settings/${id}`, {
      _id: user.id,
      token: user.token,
    });

    // GADAI
    setBatasPinjamanDenda(response.data.batasPinjamanDenda);
    setDenda1(response.data.denda1);
    setDenda2(response.data.denda2);
    setLamaJtTempo(response.data.lamaJtTempo);
    setKategoriPercepatan(response.data.kategoriPercepatan);
    setHariBatasPercepatan1(response.data.hariBatasPercepatan1);
    setDiskonBatasPercepatan1(response.data.diskonBatasPercepatan1);
    setHariBatasPercepatan2(response.data.hariBatasPercepatan2);
    setDiskonBatasPercepatan2(response.data.diskonBatasPercepatan2);
    setHariBatasPercepatan3(response.data.hariBatasPercepatan3);
    setDiskonBatasPercepatan3(response.data.diskonBatasPercepatan3);
    setBatasBunga(response.data.batasBunga);
    setBatasJual(response.data.batasJual);
    setLamaHariBukaJual(response.data.lamaHariBukaJual);
    setPersenUntungJual(response.data.persenUntungJual);
    setFeeAdmPerpanjanganGadai(response.data.feeAdmPerpanjanganGadai);
    setFeeAdmGadai(response.data.feeAdmGadai);
    setIsOpenFeeAdmGadai(
      response.data.isOpenFeeAdmGadai === true
        ? isOpenFeeAdmGadaiOption[0]
        : isOpenFeeAdmGadaiOption[1]
    );

    // COA GADAI
    setCoaKasKasir(response.data.coaKasKasir);
    setCoaKodeKas(response.data.coaKodeKas);
    setPiutangNasabahGadai(response.data.piutangNasabahGadai);
    setPendapatanAdministrasiGadai(response.data.pendapatanAdministrasiGadai);
    setPendapatanBungaPinjGadai(response.data.pendapatanBungaPinjGadai);
    setKasPenerimaanGadai(response.data.kasPenerimaanGadai);
    setPendDendaPiutangGadai(response.data.pendDendaPiutangGadai);
    setPendPinaltiPiutangFidusia(response.data.pendPinaltiPiutangFidusia);
    setPendPenjualanBarangGadai(response.data.pendPenjualanBarangGadai);

    // FIDUSIA
    setToleransiFidusia(response.data.toleransiFidusia);
    setPersenDendaFidusia(response.data.persenDendaFidusia);
    setSp1(response.data.sp1);
    setSp2(response.data.sp2);
    setSp3(response.data.sp3);
    setSp1LambatBayar(response.data.sp1LambatBayar);
    setSp2LambatBayar(response.data.sp2LambatBayar);
    setSp3LambatBayar(response.data.sp3LambatBayar);
    setSPanggilan(response.data.sPanggilan);

    // COA FIDUSIA
    setKasPenerimaanFidusia(response.data.kasPenerimaanFidusia);
    setPiutangNasabahFidusia(response.data.piutangNasabahFidusia);
    setPendapatanBungaPinjFidusia(response.data.pendapatanBungaPinjFidusia);
    setPendDendaPiutangFidusia(response.data.pendDendaPiutangFidusia);

    // COA
    setLabaRugiPeriodeBerjalan(response.data.labaRugiPeriodeBerjalan);
    setAccountPembalance(response.data.accountPembalance);
    setGroupKewajibanHutang(response.data.groupKewajibanHutang);
    setGroupModalSaham(response.data.groupModalSaham);
    setGroupAktivaLancar(response.data.groupAktivaLancar);
    setGroupAktivaTetap(response.data.groupAktivaTetap);
    setKodeGroupPendapatan(response.data.kodeGroupPendapatan);
    setKodeGroupBiayaAtasPendapatan(response.data.kodeGroupBiayaAtasPendapatan);
    setKodeGroupPengeluaranOperasional(
      response.data.kodeGroupPengeluaranOperasional
    );
    setKodeGroupPendapatanLainLain(response.data.kodeGroupPendapatanLainLain);
    setKodeGroupBiayaLainLain(response.data.kodeGroupBiayaLainLain);
    setSubGroupCoaKas(response.data.subGroupCoaKas);
    setTitipanNasabah(response.data.titipanNasabah);
    setBiayaKerugian(response.data.biayaKerugian);

    // COA OJK
    setUrutanCoaOjkLabaRugiJumlahPendapatan(
      response.data.urutanCoaOjkLabaRugiJumlahPendapatan
    );
    setUrutanCoaOjkLabaRugiJumlahBeban(
      response.data.urutanCoaOjkLabaRugiJumlahBeban
    );
    setLoading(false);
  };

  const updateSetting = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateSetting/${id}`, {
            coaKasKasir,
            coaKodeKas,
            batasPinjamanDenda: batasPinjamanDenda,
            denda1: denda1,
            denda2: denda2,
            lamaJtTempo,
            kategoriPercepatan,
            hariBatasPercepatan1,
            diskonBatasPercepatan1,
            hariBatasPercepatan2,
            diskonBatasPercepatan2,
            hariBatasPercepatan3,
            diskonBatasPercepatan3,
            batasBunga,
            batasJual,
            lamaHariBukaJual,
            persenUntungJual,
            feeAdmPerpanjanganGadai,
            feeAdmGadai,
            isOpenFeeAdmGadai: isOpenFeeAdmGadai === "YA" ? true : false,
            toleransiFidusia,
            persenDendaFidusia,
            sp1,
            sp2,
            sp3,
            sp1LambatBayar,
            sp2LambatBayar,
            sp3LambatBayar,
            sPanggilan,
            kasPenerimaanFidusia,
            piutangNasabahFidusia,
            pendapatanBungaPinjFidusia,
            pendDendaPiutangFidusia,
            labaRugiPeriodeBerjalan,
            accountPembalance,
            groupKewajibanHutang,
            groupModalSaham,
            groupAktivaLancar,
            groupAktivaTetap,
            piutangNasabahGadai,
            pendapatanAdministrasiGadai,
            kodeGroupPendapatan,
            kodeGroupBiayaAtasPendapatan,
            kodeGroupPengeluaranOperasional,
            kodeGroupPendapatanLainLain,
            kodeGroupBiayaLainLain,
            subGroupCoaKas,
            pendapatanBungaPinjGadai,
            kasPenerimaanGadai,
            pendDendaPiutangGadai,
            pendPinaltiPiutangFidusia,
            pendPenjualanBarangGadai,
            titipanNasabah,
            biayaKerugian,
            urutanCoaOjkLabaRugiJumlahPendapatan,
            urutanCoaOjkLabaRugiJumlahBeban,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          const findSetting = await axios.post(`${tempUrl}/lastSetting`, {
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          const gantiPeriodeUser = await axios.post(
            `${tempUrl}/updateUserThenLogin/${user.id}`,
            {
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: gantiPeriodeUser.data.details,
            setting: findSetting.data,
          });
          setLoading(false);
          navigate(`/setting`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Setting</h5>
      <hr />
      <Card>
        <Card.Header>Setting</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateSetting}>
            <h5>GADAI</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Denda 1 :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={denda1}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setDenda1(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Lama Jatuh Tempo :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={lamaJtTempo}
                          onChange={(e) =>
                            setLamaJtTempo(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Hari Batas Percepatan 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={hariBatasPercepatan1}
                          onChange={(e) =>
                            setHariBatasPercepatan1(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Hari Batas Percepatan 2 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={hariBatasPercepatan2}
                          onChange={(e) =>
                            setHariBatasPercepatan2(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Hari Batas Percepatan 3 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={hariBatasPercepatan3}
                          onChange={(e) =>
                            setHariBatasPercepatan3(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Batas Bunga :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={batasBunga}
                          onChange={(e) =>
                            setBatasBunga(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Untung Jual (%) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={persenUntungJual}
                          onChange={(e) =>
                            setPersenUntungJual(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Fee Adm. (%) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={feeAdmGadai}
                          onChange={(e) =>
                            setFeeAdmGadai(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Buka Fee Adm. :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={isOpenFeeAdmGadai}
                          onChange={(e) => {
                            setIsOpenFeeAdmGadai(e.target.value);
                          }}
                        >
                          {isOpenFeeAdmGadaiOption.map((isOpenFeeAdmGadai) => (
                            <option value={isOpenFeeAdmGadai}>
                              {isOpenFeeAdmGadai}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Batas Pinjaman Denda :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={batasPinjamanDenda}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setBatasPinjamanDenda(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Denda 2 :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={denda2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setDenda2(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kategori Percepatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kategoriPercepatan}
                          onChange={(e) =>
                            setKategoriPercepatan(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Diskon Batas Percepatan 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={diskonBatasPercepatan1}
                          onChange={(e) =>
                            setDiskonBatasPercepatan1(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Diskon Batas Percepatan 2 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={diskonBatasPercepatan2}
                          onChange={(e) =>
                            setDiskonBatasPercepatan2(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Diskon Batas Percepatan 3 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={diskonBatasPercepatan3}
                          onChange={(e) =>
                            setDiskonBatasPercepatan3(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Batas Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={batasJual}
                          onChange={(e) =>
                            setBatasJual(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Lama Hari Buka Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={lamaHariBukaJual}
                          onChange={(e) =>
                            setLamaHariBukaJual(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Fee Adm. Perpanjangan Gadai (%) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={feeAdmPerpanjanganGadai}
                          onChange={(e) =>
                            setFeeAdmPerpanjanganGadai(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <hr />
            <h5>COA GADAI</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Coa Kas Kasir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={coaKasKasir}
                          onChange={(e) =>
                            setCoaKasKasir(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Coa Kode Kas Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={coaKodeKas}
                          onChange={(e) =>
                            setCoaKodeKas(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kas Penerimaan Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kasPenerimaanGadai}
                          onChange={(e) =>
                            setKasPenerimaanGadai(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Pendapatan Administrasi Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pendapatanAdministrasiGadai}
                          onChange={(e) =>
                            setPendapatanAdministrasiGadai(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Pend. Denda Piutang Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pendDendaPiutangGadai}
                          onChange={(e) =>
                            setPendDendaPiutangGadai(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Pend. Pinalti Piutang Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pendPinaltiPiutangFidusia}
                          onChange={(e) =>
                            setPendPinaltiPiutangFidusia(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Piutang Nasabah Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={piutangNasabahGadai}
                          onChange={(e) =>
                            setPiutangNasabahGadai(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Pend. Penjualan Barang Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pendPenjualanBarangGadai}
                          onChange={(e) =>
                            setPendPenjualanBarangGadai(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Pend. Bunga Pinj. Gadai :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pendapatanBungaPinjGadai}
                          onChange={(e) =>
                            setPendapatanBungaPinjGadai(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <hr />
            <h5>FIDUSIA</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        toleransiFidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={toleransiFidusia}
                          onChange={(e) =>
                            setToleransiFidusia(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        SP-1 (hari) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp1}
                          onChange={(e) => setSp1(e.target.value.toUpperCase())}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        SP-2 (hari) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp2}
                          onChange={(e) => setSp2(e.target.value.toUpperCase())}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        SP-3 (hari) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp3}
                          onChange={(e) => setSp3(e.target.value.toUpperCase())}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        S. Panggilan (hari) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sPanggilan}
                          onChange={(e) =>
                            setSPanggilan(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Denda (%) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={persenDendaFidusia}
                          onChange={(e) =>
                            setPersenDendaFidusia(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Lambar Bayar SP-1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp1LambatBayar}
                          onChange={(e) =>
                            setSp1LambatBayar(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Lambar Bayar SP-2 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp2LambatBayar}
                          onChange={(e) =>
                            setSp2LambatBayar(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Lambar Bayar SP-3 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={sp3LambatBayar}
                          onChange={(e) =>
                            setSp3LambatBayar(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <hr />
            <h5>FIDUSIA</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kas Penerimaan Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={kasPenerimaanFidusia}
                          onChange={(e) =>
                            setKasPenerimaanFidusia(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Piutang Nasabah Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={piutangNasabahFidusia}
                          onChange={(e) =>
                            setPiutangNasabahFidusia(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pend. Bunga Pinj. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={pendapatanBungaPinjFidusia}
                          onChange={(e) =>
                            setPendapatanBungaPinjFidusia(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pend. Denda Piutang Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={pendDendaPiutangFidusia}
                          onChange={(e) =>
                            setPendDendaPiutangFidusia(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <hr />
            <h5>COA</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Laba Rugi Periode Berjalan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={labaRugiPeriodeBerjalan}
                          onChange={(e) =>
                            setLabaRugiPeriodeBerjalan(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Group Kewajiban Hutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={groupKewajibanHutang}
                          onChange={(e) =>
                            setGroupKewajibanHutang(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Group Aktiva Lancar :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={groupAktivaLancar}
                          onChange={(e) =>
                            setGroupAktivaLancar(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kode Group Pendapatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeGroupPendapatan}
                          onChange={(e) =>
                            setKodeGroupPendapatan(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kode Group Biaya Atas Pendapatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeGroupBiayaAtasPendapatan}
                          onChange={(e) =>
                            setKodeGroupBiayaAtasPendapatan(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kode Group Pengeluaran Operasional :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeGroupPengeluaranOperasional}
                          onChange={(e) =>
                            setKodeGroupPengeluaranOperasional(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kode Group Pendapatan Lain-Lain :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeGroupPendapatanLainLain}
                          onChange={(e) =>
                            setKodeGroupPendapatanLainLain(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kode Group Biaya Lain-Lain :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeGroupBiayaLainLain}
                          onChange={(e) =>
                            setKodeGroupBiayaLainLain(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Sub Group Coa Kas :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={subGroupCoaKas}
                          onChange={(e) =>
                            setSubGroupCoaKas(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Biaya Kerugian :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={biayaKerugian}
                          onChange={(e) =>
                            setBiayaKerugian(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Account Pembalance :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={accountPembalance}
                          onChange={(e) =>
                            setAccountPembalance(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Group Modal Saham :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={groupModalSaham}
                          onChange={(e) =>
                            setGroupModalSaham(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Group Aktiva Tetap :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={groupAktivaTetap}
                          onChange={(e) =>
                            setGroupAktivaTetap(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Titipan Nasabah :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={titipanNasabah}
                          onChange={(e) =>
                            setTitipanNasabah(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <hr />
            <h5>COA</h5>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Urutan Coa Ojk Laba Rugi Jumlah Pendapatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={urutanCoaOjkLabaRugiJumlahPendapatan}
                          onChange={(e) =>
                            setUrutanCoaOjkLabaRugiJumlahPendapatan(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Urutan Coa Ojk Laba Rugi Beban :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={urutanCoaOjkLabaRugiJumlahBeban}
                          onChange={(e) =>
                            setUrutanCoaOjkLabaRugiJumlahBeban(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/setting")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahSetting;

const alertBox = {
  width: "100%",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
