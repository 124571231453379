import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import { ShowTableKasKeluar } from "../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Pagination } from "@mui/material";

const TampilKasKeluar = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [noBukti, setNoBukti] = useState("");
  const [tglKasKeluar, setTglKasKeluar] = useState("");
  const [tglKasKeluarDate, setTglKasKeluarDate] = useState();
  const [kodeCOA, setKodeCOA] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [isPost, setIsPost] = useState("");
  const [jumlah, setJumlah] = useState("");

  const [kasKeluarsChildData, setKasKeluarsChildData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN" && isPost === false;
  let isEditableAdmin = true;
  // let isEditableAdmin =
  //   user.tipeUser === "ADMIN" &&
  //   tglKasKeluarDate?.getFullYear() === today.getFullYear() &&
  //   tglKasKeluarDate?.getMonth() === today.getMonth() &&
  //   tglKasKeluarDate?.getDate() === today.getDate() &&
  //   isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = kasKeluarsChildData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(kasKeluarsChildData.length / PER_PAGE);
  const _DATA = usePagination(kasKeluarsChildData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    getKasKeluarById();
  }, []);

  const getKasKeluarById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrlFico}/kasKeluars/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoBukti(response.data.noBukti);
    setTglKasKeluar(response.data.tglKasKeluar);
    setTglKasKeluarDate(new Date(response.data.tglKasKeluar));
    setKodeCOA(response.data.coa);
    setKeterangan(response.data.keterangan);
    setIsPost(response.data.isPost);
    setJumlah(response.data.jumlah);
    const response2 = await axios.post(
      `${tempUrlFico}/kasKeluarsChildByKasKeluar`,
      {
        kasKeluarId: response.data.id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setKasKeluarsChildData(response2.data);
    setLoading(false);
  };

  const deleteKasKeluar = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrlFico}/deleteKasKeluar/${id}`, {
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarKasKeluar");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Finance</h3>
      <h5 style={{ fontWeight: 400 }}>Kas Keluar</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarKasKeluar")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarKasKeluar/kasKeluar/${id}/tambahKasKeluarChild`}
              editLink={`/daftarKasKeluar/kasKeluar/${id}/edit`}
              deleteUser={deleteKasKeluar}
              nameUser={noBukti}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Kas Keluar</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kas Keluar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglKasKeluar}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jumlah.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableKasKeluar id={id} currentPosts={currentPosts} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilKasKeluar;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
