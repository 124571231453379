import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Container, Card, Col, Row, Form } from "react-bootstrap";
import CompanyPic from "../../../assets/LogoPerusahaan.jpeg";
import LogoPerusahaanNama from "../../../assets/logoPerusahaanNama.jpg";
import LogoPerusahaanSpFidusiaBlackAndWhite from "../../../assets/LogoPerusahaanSpFidusiaBlackAndWhite.jpg";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";

const TampilAngsuranFidusiaChild = () => {
  const { screenSize } = useStateContext();
  const reportTemplateRef = useRef(null);
  const reportTemplateRefPercepatan = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const { id, idAngsuranChild } = useParams();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);

  const [namaCustomer, setNamaCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [kategori, setKategori] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [tglAngText, setTglAngText] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [jamAng, setJamAng] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [nopol, setNopol] = useState("");
  const [warna, setWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [no, setNo] = useState("");
  const [isPercepatan, setIsPercepatan] = useState("");

  const navigate = useNavigate();
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewPdfSuratKetLunas, setPreviewPdfSuratKetLunas] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Data Angsuran
  const [isBayarLast, setIsBayarLast] = useState(false);
  const [mainId, setMainId] = useState("");
  const [idAngsuran, setIdAngsuran] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [sisaBulanPercepatan, setSisaBulanPercepatan] = useState("");
  const [tglJatuhTempoBefore, setTglJatuhTempoBefore] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");

  // Data Inputan
  const [tglBayar, setTglBayar] = useState("");
  const [tglBayarTeks, setTglBayarTeks] = useState("");
  const [keterlambatan, setKeterlambatan] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [denda, setDenda] = useState("");
  const [hutangDenda, setHutangDenda] = useState("");
  const [potongan, setPotongan] = useState("");
  const [totalPiutang, setTotalPiutang] = useState("");
  const [totalBayar, setTotalBayar] = useState("");
  const [bayar, setBayar] = useState("");

  // Data Percepatan
  const [noPenerimaan, setNoPenerimaan] = useState("");
  const [bayarPercepatan, setBayarPercepatan] = useState("");
  const [merk, setMerk] = useState("");
  const [kodeTipe, setKodeTipe] = useState("");
  const [namaTipe, setNamaTipe] = useState("");
  const [tenor, setTenor] = useState("");
  const [penerimaanAngsuran, setPenerimaanAngsuran] = useState({});
  const [kodeCOAAngsuran, setKodeCOAAngsuran] = useState("");

  // Surat Keterangan Lunas
  const [isLastAngsuran, setIsLastAngsuran] = useState(false);
  const [noSurat, setNoSurat] = useState("");
  const [tglCetak, setTglCetak] = useState(new Date());
  const [tglCetakData, setTglCetakData] = useState(new Date());
  const [tglCetakTeks, setTglCetakTeks] = useState("");

  const [isPost, setIsPost] = useState(false);
  const [loading, setLoading] = useState(false);

  let todayDate = new Date();
  let tempTodayMonth;
  switch (todayDate.getMonth() + 1) {
    case 1:
      tempTodayMonth = "JANUARI";
      break;
    case 2:
      tempTodayMonth = "FEBRUARI";
      break;
    case 3:
      tempTodayMonth = "MARET";
      break;
    case 4:
      tempTodayMonth = "APRIL";
      break;
    case 5:
      tempTodayMonth = "MEI";
      break;
    case 6:
      tempTodayMonth = "JUNI";
      break;
    case 7:
      tempTodayMonth = "JULI";
      break;
    case 8:
      tempTodayMonth = "AGUSTUS";
      break;
    case 9:
      tempTodayMonth = "SEPTEMBER";
      break;
    case 10:
      tempTodayMonth = "OKTOBER";
      break;
    case 11:
      tempTodayMonth = "NOVEMBER";
      break;
    case 12:
      tempTodayMonth = "DESEMBER";
      break;
    default:
      break;
  }

  let tempTglTodayText = `${todayDate.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })} ${tempTodayMonth} ${todayDate.getFullYear()}`;

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const handleGeneratePdfPercepatan = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRefPercepatan.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  useEffect(() => {
    getAngsuranFidusiaChildById();
  }, []);

  const getAngsuranFidusiaChildById = async () => {
    if (id) {
      const pengajuanFidusia = await axios.post(
        `${tempUrl}/pengajuanFidusias/${id}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setNamaCustomer(pengajuanFidusia.data.customer.namaCustomer);
      setNikCustomer(pengajuanFidusia.data.customer.nikCustomer);
      setAlamatCustomer(pengajuanFidusia.data.customer.alamatCustomer);
      setKategori(pengajuanFidusia.data.kategori);
      let newTglAng = new Date(pengajuanFidusia.data.tglAng);
      setTglAng(formatDate(pengajuanFidusia.data.tglAng));
      setNoPerjanjian(pengajuanFidusia.data.noPerjanjian);
      setNoMesin(pengajuanFidusia.data.noMesin);
      setNoRangka(pengajuanFidusia.data.noRangka);
      setNopol(pengajuanFidusia.data.nopol);
      setWarna(pengajuanFidusia.data.warna.namaWarna);
      setNoBpkb(pengajuanFidusia.data.noBpkb);
      setTenor(pengajuanFidusia.data.tenor);
      let tempDateName;
      switch (newTglAng.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }
      let tempTglAngText = `${newTglAng.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempDateName} ${newTglAng.getFullYear()}`;
      setTglAngText(tempTglAngText);

      const response = await axios.post(
        `${tempUrl}/angsuranFidusiasByPengajuanShow`,
        {
          pengajuanFidusiaId: id,
          no: idAngsuranChild,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setNo(response.data.no);
      setIsPercepatan(response.data.isPercepatan);
      setIdAngsuran(response.data.id);
      let newTglJatuhTempo = new Date(response.data.tglJatuhTempo);
      setTglJatuhTempo(formatDate(response.data.tglJatuhTempo));
      setAngModal(parseInt(response.data.angModal));
      setAngBunga(parseInt(response.data.angBunga));
      setAngPerBulan(response.data.angPerBulan);

      let newTglBayar = new Date(response.data.tglBayar);
      setTglBayar(response.data.tglBayar && formatDate(response.data.tglBayar));
      switch (newTglBayar.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }
      let tempTglBayar = `${newTglBayar.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempDateName} ${newTglBayar.getFullYear()}`;
      setTglBayarTeks(tempTglBayar);
      setNoKwitansi(response.data.noKwitansi);
      setKeterangan(response.data.keterangan);
      setDenda(response.data.denda);
      setHutangDenda(response.data.hutangDenda);
      setPotongan(response.data.potongan);
      setTotalPiutang(response.data.totalPiutang);
      setTotalBayar(response.data.totalBayar);
      setBayar(response.data.bayar);

      const diffTime = Math.abs(newTglBayar - newTglJatuhTempo);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (newTglBayar > newTglJatuhTempo) {
        setKeterlambatan(diffDays);
      }

      const angsuranBayarLast = await axios.post(
        `${tempUrl}/angsuranFidusiasByPengajuanBayarLast`,
        {
          pengajuanFidusiaId: id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      if (
        angsuranBayarLast.data &&
        response.data.id === angsuranBayarLast.data.id
      ) {
        setIsBayarLast(true);
        setTglJatuhTempoBefore(angsuranBayarLast.data.tglJatuhTempo);
      }

      if (response.data.isPercepatan === true) {
        const angsuranBayarLastBefore = await axios.post(
          `${tempUrl}/angsuranFidusiasByPengajuanBayarLastPercepatan`,
          {
            pengajuanFidusiaId: id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );

        if (angsuranBayarLastBefore.data) {
          setIsBayarLast(true);
          setTglJatuhTempoBefore(angsuranBayarLastBefore.data.tglJatuhTempo);
          setSisaBulanPercepatan(parseInt(angsuranBayarLastBefore.data.no));
        }

        // Get Data Penerimaan Percepatan
        const penerimaan = await axios.post(
          `${tempUrl}/penerimaanFidusiasByPengajuanShowPercepatan`,
          {
            pengajuanFidusiaId: id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        if (penerimaan.data !== null) {
          setPenerimaanAngsuran(penerimaan.data);
          setNoPenerimaan(penerimaan.data.no);
          setBayarPercepatan(penerimaan.data.bayar);
          setMerk(penerimaan.data.pengajuanfidusia.tipe.merk);
          setKodeTipe(penerimaan.data.pengajuanfidusia.tipe.kodeTipe);
          setNamaTipe(penerimaan.data.pengajuanfidusia.tipe.namaTipe);
          setIsPost(penerimaan.data.isPost);
          setKodeCOAAngsuran(penerimaan.data.coa.namaCOA);
          let tglCreatedAngsuran = new Date(penerimaan.data.updatedAt);
          const timeString = formatTime(tglCreatedAngsuran);
          setJamAng(timeString);
        }
      } else {
        // Get Data Penerimaan
        const penerimaan = await axios.post(
          `${tempUrl}/penerimaanFidusiasByPengajuan`,
          {
            pengajuanFidusiaId: id,
            no: idAngsuranChild,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        if (penerimaan.data !== null) {
          setIsPost(penerimaan.data.isPost);
          setKodeCOAAngsuran(penerimaan.data.coa.namaCOA);
          let tglCreatedAngsuran = new Date(penerimaan.data.updatedAt);
          const timeString = formatTime(tglCreatedAngsuran);
          setJamAng(timeString);
        }
      }

      // Last Angsuran
      if (pengajuanFidusia.data.tenor === parseInt(response.data.no)) {
        setIsLastAngsuran(true);
        setNoSurat(pengajuanFidusia.data.noSurat);
        setTglCetak(
          pengajuanFidusia.data.tglCetak
            ? new Date(pengajuanFidusia.data.tglCetak)
            : null
        );
        setTglCetakData(
          pengajuanFidusia.data.tglCetak
            ? new Date(pengajuanFidusia.data.tglCetak)
            : null
        );

        let tempDateName;
        switch (tglCetak.getMonth() + 1) {
          case 1:
            tempDateName = "JANUARI";
            break;
          case 2:
            tempDateName = "FEBRUARI";
            break;
          case 3:
            tempDateName = "MARET";
            break;
          case 4:
            tempDateName = "APRIL";
            break;
          case 5:
            tempDateName = "MEI";
            break;
          case 6:
            tempDateName = "JUNI";
            break;
          case 7:
            tempDateName = "JULI";
            break;
          case 8:
            tempDateName = "AGUSTUS";
            break;
          case 9:
            tempDateName = "SEPTEMBER";
            break;
          case 10:
            tempDateName = "OKTOBER";
            break;
          case 11:
            tempDateName = "NOVEMBER";
            break;
          case 12:
            tempDateName = "DESEMBER";
            break;
          default:
            break;
        }
        let tempTglCetakText = `${tglCetak.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })} ${tempDateName} ${tglCetak.getFullYear()}`;
        setTglCetakTeks(tempTglCetakText);
      }
    }
  };

  const deleteAngsuranFidusia = async () => {
    setLoading(true);
    try {
      if (isPercepatan === true) {
        // Cancel Percepatan
        await axios.post(
          `${tempUrl}/cancelBayarAngsuranFidusiaPercepatanUpdatePengajuanFidusia`,
          {
            dataBayarAngsuranFidusia: {
              pengajuanFidusiaId: id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            },
            dataPengajuanFidusia: {
              pengajuanFidusiaId: id,
              tenor,
              sisaBulanPercepatan,
              tglJatuhTempo: tglJatuhTempoBefore,
              kodeCabang: user.cabang.id,
              _id: user.id,
              token: user.token,
            },
            kodeCabang: user.cabang.id,
            _id: user.id,
            token: user.token,
          }
        );
        navigate(`/daftarAngsuranFidusia/angsuranFidusia/${id}`);
      } else {
        await axios.post(
          `${tempUrl}/cancelBayarAngsuranFidusiaUpdatePengajuanFidusia`,
          {
            dataBayarAngsuranFidusia: {
              pengajuanFidusiaId: id,
              no: idAngsuranChild,
              _id: user.id,
              token: user.token,
            },
            dataPengajuanFidusia: {
              pengajuanFidusiaId: id,
              tglJtTempo: tglJatuhTempoBefore,
              tglJtTempoTerakhir: tglJatuhTempoBefore,
              kodeCabang: user.cabang.id,
              _id: user.id,
              token: user.token,
            },
            kodeCabang: user.cabang.id,
            _id: user.id,
            token: user.token,
          }
        );
        navigate(`/daftarAngsuranFidusia/angsuranFidusia/${id}`);
      }
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${idAngsuranChild} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const updatePengajuanFidusia = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && noSurat.length !== 0 && tglCetak.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(
          `${tempUrl}/updatePengajuanFidusiaSuratKetLunas/${id}`,
          {
            // Surat Keterangan Lunas
            noSurat,
            tglCetak: new Date(tglCetak),

            kodeCabang: user.cabang.id,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
          }
        );
        setLoading(false);
        getAngsuranFidusiaChildById();
        navigate(
          `/daftarAngsuranFidusia/angsuranFidusia/${id}/${idAngsuranChild}`
        );
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarAngsuranFidusia/angsuranFidusia/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {bayar > 0 && (
            <Button
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPreviewPdf(!previewPdf);
                setPreviewPdfSuratKetLunas(false);
              }}
            >
              CETAK
            </Button>
          )}
          {bayar > 0 && isLastAngsuran && (
            <Button
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPreviewPdfSuratKetLunas(!previewPdfSuratKetLunas);
                setPreviewPdf(false);
              }}
            >
              SURAT KET. LUNAS
            </Button>
          )}
        </ButtonGroup>
      </Box>

      {isPercepatan === false && bayar > 0 && previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} style={cetakContainerKwitansiAngsuran}>
            <p style={cetakCenter}>{setting.namaPerusahaan}</p>
            <p style={cetakCenter}>
              {setting.alamatPerusahaan} ({setting.kabupatenPerusahaan}){" "}
              {setting.kotaPerusahaan}
            </p>
            <p style={cetakCenter}>NO. TELP. {setting.teleponPerusahaan}</p>
            <hr style={{ marginBottom: "20px" }} />
            <div style={{ display: "flex" }}>
              <div>
                <p style={cetakText}>Tanggal & Waktu</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={cetakText}>
                  : {tglBayar} {jamAng}
                </p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <p style={cetakText}>No. Kwitansi</p>
              </div>
              <div style={{ marginLeft: "33px" }}>
                <p style={cetakText}>: {noKwitansi}</p>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <p style={cetakCenterBold}>BUKTI PEMBAYARAN PINJAMAN</p>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Nasabah</p>
                </div>
                <div style={{ marginLeft: "53px" }}>
                  <p style={cetakText}>
                    : {namaCustomer.split(" ")[0]} {namaCustomer.split(" ")[1]}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tgl. Pembayaran</p>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <p style={cetakText}>: {tglBayarTeks}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Perjanjian</p>
                </div>
                <div style={{ marginLeft: "27px" }}>
                  <p style={cetakText}>: {noPerjanjian}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tgl. Jatuh Tempo</p>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <p style={cetakText}>: {tglAng.slice(0, 2)}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Pol</p>
                </div>
                <div style={{ marginLeft: "62px" }}>
                  <p style={cetakText}>: {nopol}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Mesin</p>
                </div>
                <div style={{ marginLeft: "48px" }}>
                  <p style={cetakText}>: {noMesin}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Angsuran Ke</p>
                </div>
                <div style={{ marginLeft: "33px" }}>
                  <p style={cetakText}>
                    : {no} ({angkaTerbilang(no)})
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tenor</p>
                </div>
                <div style={{ marginLeft: "73px" }}>
                  <p style={cetakText}>: {tenor}</p>
                </div>
              </div>
            </div>
            <hr style={{ marginBottom: "20px" }} />
            <div style={cetakWrapper}>
              <p>Keterangan</p>
              <p>Nominal</p>
            </div>
            <hr style={{ marginTop: "0px" }} />
            <div style={cetakWrapperNoMarginTop}>
              <p>Angsuran</p>
              <p>{angModal.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Bunga Pinjaman</p>
              <p>{angBunga.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Hutang Denda</p>
              <p>{hutangDenda.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Denda keterlambatan. Terlambat</p>
              <p>{denda.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>{keterlambatan}hari</p>
              <p></p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p style={{ color: "red" }}>Potongan</p>
              <p style={{ color: "red" }}>{potongan.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Jemputan</p>
              <p>0</p>
            </div>
            <hr style={{ marginTop: "0px" }} />
            <div style={cetakWrapperNoMarginTop}>
              <p>Total.</p>
              <p>{bayar.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperTotal}>
              <p>Biaya Penarikan</p>
              <p>0</p>
            </div>
            <div style={cetakWrapperText}>
              <p>Nasabah,</p>
              <p>{setting.namaPerusahaan} ,</p>
            </div>
            <div style={cetakWrapperTextName}>
              <p style={{ marginLeft: "-50px" }}>
                {namaCustomer.split(" ")[0]} {namaCustomer.split(" ")[1]}
              </p>
              <p style={{ marginLeft: "-40px" }}>{user.username}</p>
            </div>
          </div>
        </div>
      )}

      {isPercepatan === true && bayar > 0 && previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdfPercepatan}
          >
            CETAK
          </Button>
          <div
            ref={reportTemplateRefPercepatan}
            style={cetakContainerKwitansiAngsuran}
          >
            <p style={cetakCenter}>{setting.namaPerusahaan}</p>
            <p style={cetakCenter}>
              {setting.alamatPerusahaan} ({setting.kabupatenPerusahaan}){" "}
              {setting.kotaPerusahaan}
            </p>
            <p style={cetakCenter}>NO. TELP. {setting.teleponPerusahaan}</p>
            <hr style={{ marginBottom: "20px" }} />
            <div style={{ display: "flex" }}>
              <div>
                <p style={cetakText}>Tanggal & Waktu</p>
              </div>
              <div style={{ marginLeft: "24px" }}>
                <p style={cetakText}>
                  : {tglBayar} {jamAng}
                </p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <p style={cetakText}>No. Kwitansi</p>
              </div>
              <div style={{ marginLeft: "48px" }}>
                <p style={cetakText}>: {noKwitansi}</p>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <p style={cetakCenterBold}>BUKTI PEMBAYARAN PINJAMAN</p>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Nasabah</p>
                </div>
                <div style={{ marginLeft: "66px" }}>
                  <p style={cetakText}>
                    : {namaCustomer.split(" ")[0]} {namaCustomer.split(" ")[1]}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tgl. Pembayaran</p>
                </div>
                <div style={{ marginLeft: "22px" }}>
                  <p style={cetakText}>: {tglBayarTeks}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Perjanjian</p>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <p style={cetakText}>: {noPerjanjian}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tgl. Jatuh Tempo</p>
                </div>
                <div style={{ marginLeft: "22px" }}>
                  <p style={cetakText}>: {tglAng.slice(0, 2)}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Pol</p>
                </div>
                <div style={{ marginLeft: "74px" }}>
                  <p style={cetakText}>: {nopol}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>No. Mesin</p>
                </div>
                <div style={{ marginLeft: "60px" }}>
                  <p style={cetakText}>: {noMesin}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Angsuran Ke</p>
                </div>
                <div style={{ marginLeft: "44px" }}>
                  <p style={cetakText}>
                    : {no} ({angkaTerbilang(no)})
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Tenor</p>
                </div>
                <div style={{ marginLeft: "84px" }}>
                  <p style={cetakText}>: {tenor}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={cetakText}>Metode Pembayaran</p>
                </div>
                <div style={{ marginLeft: "2px" }}>
                  <p style={cetakText}>
                    :{" "}
                    {penerimaanAngsuran.coa.kasBank === "KAS"
                      ? "TUNAI"
                      : "TRANSFER"}
                  </p>
                </div>
              </div>
            </div>
            <hr style={{ marginBottom: "20px" }} />
            <div style={cetakWrapper}>
              <p>Keterangan</p>
              <p>Nominal</p>
            </div>
            <hr style={{ marginTop: "0px" }} />
            <div style={cetakWrapperNoMarginTop}>
              <p>Pelunasan-Pokok</p>
              <p>
                {parseInt(penerimaanAngsuran.angModal).toLocaleString("de-DE")}
              </p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Pelunasan-Bunga</p>
              <p>
                {(
                  parseInt(
                    penerimaanAngsuran.angModal + penerimaanAngsuran.angBunga
                  ) -
                  parseInt(penerimaanAngsuran.angModal) +
                  penerimaanAngsuran.potongan
                ).toLocaleString("de-DE")}
              </p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p>Pelunasan-Denda</p>
              <p>
                {(
                  penerimaanAngsuran.hutangDenda + penerimaanAngsuran.denda
                ).toLocaleString("de-DE")}
              </p>
            </div>
            <div style={cetakWrapperNoMarginTop}>
              <p style={{ color: "red" }}>Pelunasan-Potongan</p>
              <p style={{ color: "red" }}>
                {penerimaanAngsuran.potongan.toLocaleString("de-DE")}
              </p>
            </div>
            <hr style={{ marginTop: "0px" }} />
            <div style={cetakWrapperNoMarginTop}>
              <p>Total</p>
              <p>
                {parseInt(
                  penerimaanAngsuran.angModal +
                    penerimaanAngsuran.angBunga +
                    penerimaanAngsuran.hutangDenda +
                    penerimaanAngsuran.denda
                ).toLocaleString("de-DE")}
              </p>
            </div>
            <div style={cetakWrapperText}>
              <p>Nasabah,</p>
              <p>{setting.namaPerusahaan} ,</p>
            </div>
            <div style={cetakWrapperTextName}>
              <p style={{ marginLeft: "-50px" }}>
                {namaCustomer.split(" ")[0]} {namaCustomer.split(" ")[1]}
              </p>
              <p style={{ marginLeft: "-40px" }}>{user.username}</p>
            </div>
          </div>
        </div>
      )}

      {bayar > 0 && isLastAngsuran && previewPdfSuratKetLunas && (
        <div>
          <Form
            noValidate
            validated={validated}
            onSubmit={updatePengajuanFidusia}
          >
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Card>
                  <Card.Header>Data Surat Keterangan Lunas</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            No. Surat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={noSurat}
                              onChange={(e) =>
                                setNoSurat(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Tgl. Cetak <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <DatePicker
                              required
                              dateFormat="dd/MM/yyyy"
                              customInput={<Form.Control required />}
                              selected={tglCetak}
                              onChange={(date) => setTglCetak(date)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Simpan
                    </Button>
                  </Card.Body>
                </Card>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Form>
          {tglCetakData && (
            <>
              <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                onClick={handleGeneratePdf}
                style={{ marginTop: "40px" }}
              >
                CETAK
              </Button>
              <div
                ref={reportTemplateRef}
                style={cetakContainerSuratKeteranganLunas}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={LogoPerusahaanSpFidusiaBlackAndWhite}
                    alt="LogoPerusahaanSpFidusiaBlackAndWhite"
                    style={{ width: "800px" }}
                  />
                </div>
                <hr style={{ borderColor: "black" }} />
                <p style={cetakCenterBoldBig}>SURAT KETERANGAN LUNAS</p>
                <div
                  style={{
                    paddingRight: "80px",
                    paddingLeft: "80px",
                    fontSize: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div></div>
                    <div style={{ marginLeft: "20px" }}>
                      <p style={cetakText}>
                        {setting.provinsiPerusahaan}, {tglCetakTeks}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <p>No</p>
                      <p style={{ marginLeft: "80px" }}>: {noSurat}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-20px" }}>
                      <p>Perihal</p>
                      <p style={{ marginLeft: "50px" }}>
                        : Surat Keterangan Lunas
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>Dengan Hormat,</p>
                    </div>
                    <div>
                      <p>
                        Sehubungan dengan adanya permohonan anda yang terkait
                        dengan pelunasan pinjaman kredit Fidusia{" "}
                        {setting.namaPerusahaan} dengan data sebagai berikut :
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <p>Nama</p>
                      <p style={{ marginLeft: "100px" }}>: {namaCustomer}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Nomor Identitas</p>
                      <p style={{ marginLeft: "30px" }}>: {nikCustomer}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Nomor Perjanjian</p>
                      <p style={{ marginLeft: "20px" }}>: {noPerjanjian}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>No BPKB</p>
                      <p style={{ marginLeft: "75px" }}>: {noBpkb}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Unit Jaminan</p>
                      <p style={{ marginLeft: "50px" }}>: 1 (Satu)</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Nomor Mesin</p>
                      <p style={{ marginLeft: "50px" }}>: {noMesin}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Nomor Rangka</p>
                      <p style={{ marginLeft: "35px" }}>: {noRangka}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p>Status Pinjaman</p>
                      <p style={{ marginLeft: "30px" }}>: LUNAS</p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>
                        &emsp; &emsp; Demikian Surat Keterangan Lunas ini dibuat
                        agar dapat dipergunakan sebagaimana mestinya. Atas
                        perhatian dan kerja samanya kami ucapkan terima kasih.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <b>{setting.namaPerusahaan}</b>
                    </div>
                  </div>
                  <div style={{ marginTop: "100px" }}>
                    <div>
                      <u>
                        <b
                          style={{
                            textDecoration: "solid underline black 2px",
                          }}
                        >
                          {setting.direkturUtama}
                        </b>
                      </u>
                    </div>
                  </div>
                  <div>
                    <div>
                      <b>Direktur Utama</b>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Box>
        <Typography color="#757575">Fidusia</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Angsuran Ke-
        </Typography>
        <Divider sx={dividerStyle} />

        {isPercepatan === false && isPost === false && isBayarLast && (
          <Box sx={buttonModifierContainer}>
            <ButtonGroup variant="contained">
              {user.tipeUser !== "ADMIN" && (
                <Button
                  color="primary"
                  startIcon={<EditIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    navigate(
                      `/daftarAngsuranFidusia/angsuranFidusia/${id}/${idAngsuranChild}/edit`
                    );
                  }}
                >
                  Ubah
                </Button>
              )}
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </ButtonGroup>
          </Box>
        )}

        {isPercepatan === true && isPost === false && isBayarLast && (
          <Box sx={buttonModifierContainer}>
            <ButtonGroup variant="contained">
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${idAngsuranChild}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteAngsuranFidusia(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={idAngsuranChild} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jatuh Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJatuhTempo} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Kas :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeCOAAngsuran} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran Modal :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angModal.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran Bunga :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angBunga.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran / Bulan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angPerBulan.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglBayar} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterlambatan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={`${keterlambatan} hari`} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Kwitansi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKwitansi} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={keterangan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={denda.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Hutang Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={hutangDenda.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Potongan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={potongan.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Piutang :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPiutang.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalBayar.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={bayar.toLocaleString("de-DE")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TampilAngsuranFidusiaChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
  mb: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    md: 4,
  },
  marginTop: {
    md: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cetakContainer = {
  fontSize: "14px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "300px",
  padding: "20px",
};

const cetakContainerSuratKeteranganLunas = {
  fontSize: "14px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "1000px",
  padding: "20px",
};

const cetakContainerKwitansiAngsuran = {
  fontSize: "14px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "300px",
  padding: "20px",
  marginLeft: "35px",
};

const cetakWrapper = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-between",
};

const cetakWrapperNoMarginTop = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
};

const cetakCenter = {
  fontSize: "12px",
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakCenterBold = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
  fontSize: "12px",
};

const cetakCenterBoldBig = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
  fontSize: "25px",
};

const cetakText = {
  fontSize: "12px",
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakWrapperTotal = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
  marginBottom: "10px",
};

const cetakWrapperText = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-around",
};

const cetakWrapperTextName = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-around",
  marginTop: "80px",
};

const colorRed = {
  color: "red",
};
