import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahJurnalUmumChild = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [tglJurnalUmum, setTglJurnalUmum] = useState("");
  const [tglJurnalUmumDate, setTglJurnalUmumDate] = useState("");

  // Data Child
  const [totalTransaksi, setTotalTransaksi] = useState(0);
  const [reloadListJurnalUmumChild, setReloadListJurnalUmumChild] = useState(0);
  const [listJurnalUmumChild, setListJurnalUmumChild] = useState([
    {
      keterangan: "",
      akunDebet: "",
      akunKredit: "",
      nominal: "",
    },
  ]);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [coas, setCoas] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    getCoasData();
    getJurnalUmumChild();
  }, []);

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/coas`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const getJurnalUmumChild = async () => {
    setLoading(true);
    const pickedJurnalUmum = await axios.post(
      `${tempUrlFico}/jurnalUmums/${id}`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setNoBukti(pickedJurnalUmum.data.noBukti);
    let newTglJurnalUmum = new Date(pickedJurnalUmum.data.tglJurnalUmum);
    let tempTglJurnalUmum = `${newTglJurnalUmum
      .getDate()
      .toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${(newTglJurnalUmum.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${newTglJurnalUmum.getFullYear()}`;
    setTglJurnalUmum(tempTglJurnalUmum);
    setTglJurnalUmumDate(pickedJurnalUmum.data.tglJurnalUmum);
    setLoading(false);
  };

  const saveJurnalUmumChild = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        let savedJurnalUmum = await axios.post(
          `${tempUrlFico}/saveTransaksiJurnalUmumChild/${id}`,
          {
            dariTanggal: user.tutupperiode.dariTanggal,
            sampaiTanggal: user.tutupperiode.sampaiTanggal,

            noBukti,
            tglJurnalUmum: tglJurnalUmumDate,

            listJurnalUmumChild,

            userIdInput: user.id,
            kodeCabang: user.cabang.id,
            _id: user.id,
            token: user.token,
          }
        );
        setLoading(false);
        navigate(`/daftarJurnalUmum/jurnalUmum/${savedJurnalUmum.data.id}`);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Finance</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Detail Jurnal Umum</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveJurnalUmumChild}>
        <Card>
          <Card.Header>Jurnal Umum</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={tglJurnalUmum}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Detail Transaksi</Card.Header>
          <Card.Body>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  bgcolor: "info.light",
                  textTransform: "none",
                  marginRight: 2,
                  marginBottom: 2,
                }}
                onClick={() => {
                  listJurnalUmumChild.push({
                    keterangan: "",
                    akunDebet: "",
                    akunKredit: "",
                    nominal: "",
                  });
                  setReloadListJurnalUmumChild(
                    Math.floor(Math.random() * 1000)
                  );
                }}
              >
                {"+ Input Transaksi"}
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Keterangan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Akun Debet
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Akun Kredit
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nominal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Hapus
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listJurnalUmumChild.map((jurnalUmumChild, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={jurnalUmumChild.keterangan}
                          onChange={(e) => {
                            jurnalUmumChild.keterangan =
                              e.target.value.toUpperCase();
                            setReloadListJurnalUmumChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              sx={{ width: "200px" }}
                              {...params}
                            />
                          )}
                          value={jurnalUmumChild.akunDebet}
                          onChange={(e, value) => {
                            if (value) {
                              jurnalUmumChild.akunDebet = value.label;
                              setReloadListJurnalUmumChild(
                                Math.floor(Math.random() * 1000)
                              );
                            } else {
                              jurnalUmumChild.akunDebet = "";
                              setReloadListJurnalUmumChild(
                                Math.floor(Math.random() * 1000)
                              );
                            }
                          }}
                          disabled={
                            jurnalUmumChild.akunKredit.length > 0 && true
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              sx={{ width: "200px" }}
                              {...params}
                            />
                          )}
                          value={jurnalUmumChild.akunKredit}
                          onChange={(e, value) => {
                            if (value) {
                              jurnalUmumChild.akunKredit = value.label;
                              setReloadListJurnalUmumChild(
                                Math.floor(Math.random() * 1000)
                              );
                            } else {
                              jurnalUmumChild.akunKredit = "";
                              setReloadListJurnalUmumChild(
                                Math.floor(Math.random() * 1000)
                              );
                            }
                          }}
                          disabled={
                            jurnalUmumChild.akunDebet.length > 0 && true
                          }
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={jurnalUmumChild.nominal}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            jurnalUmumChild.nominal = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            let tempTotalTransaksi = 0;
                            for (let jurnalUmumChildData of listJurnalUmumChild) {
                              let tempNominal = parseFloat(
                                jurnalUmumChildData.nominal
                              );

                              if (isNaN(tempNominal)) {
                                tempNominal = 0;
                              }

                              tempTotalTransaksi += tempNominal;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListJurnalUmumChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            listJurnalUmumChild.splice(index, 1);

                            let tempTotalTransaksi = 0;
                            for (let jurnalUmumChildData of listJurnalUmumChild) {
                              let tempNominal = parseInt(
                                jurnalUmumChildData.nominal
                              );

                              if (isNaN(tempNominal)) {
                                tempNominal = 0;
                              }

                              tempTotalTransaksi += tempNominal;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListJurnalUmumChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/daftarJurnalUmum")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={alertBox}>
            Data berhasil tersimpan!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahJurnalUmumChild;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const colorRed = {
  color: "red",
};
