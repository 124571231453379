import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableAngsuranSPInfoNasabah } from "../../../components/ShowTable";
import { Loader, usePagination } from "../../../components";
import { Container } from "react-bootstrap";
import { Box, Button, Pagination } from "@mui/material";

const TampilInfoNasabahFidusiaSP = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);

  const [angsurans, setAngsurans] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = angsurans.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(angsurans.length / PER_PAGE);
  const _DATA = usePagination(angsurans, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    getPengajuanById();
  }, []);

  const getPengajuanById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/pengajuanFidusias/${id}`, {
      _id: user.id,
      token: user.token,
    });

    const allAngsuransByNoJual = await axios.post(
      `${tempUrl}/angsuranFidusiasByNoAjuForSP`,
      {
        noAju: response.data.noAju,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setAngsurans(allAngsuransByNoJual.data);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Data Angsuran SP</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() =>
          navigate(`/daftarInfoNasabahFidusia/infoNasabahFidusia/${id}`)
        }
        sx={{ marginLeft: 2, marginTop: 4, marginBottom: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={tableContainer}>
        <ShowTableAngsuranSPInfoNasabah id={id} currentPosts={currentPosts} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilInfoNasabahFidusiaSP;

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
