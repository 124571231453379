import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableJualAgunan } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilJualAgunan = () => {
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [noJual, setNoJual] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [noSbg, setNoSbg] = useState("");
  const [namaPembeli, setNamaPembeli] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [ketJam, setKetJam] = useState("");
  const [hargaTafsirJam, setHargaTafsirJam] = useState("");
  const [nilaiJual, setNilaiJual] = useState("");
  const [pokok, setPokok] = useState("");
  const [bunga, setBunga] = useState("");
  const [denda, setDenda] = useState("");
  const [pendapatanPenjualan, setPendapatanPenjualan] = useState("");
  const [titipanKonsumen, setTitipanKonsumen] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [isPost, setIsPost] = useState(false);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [jualAgunans, setJualAgunans] = useState([]);
  const [jualAgunansPagination, setJualAgunansPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getJualAgunansPagination();
    id && getJualAgunanById();
  }, [id, page, searchTerm]);

  const getJualAgunansPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/jualAgunansPerCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setJualAgunansPagination(response.data.jualAgunans);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJualAgunans = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/jualAgunansPerCabang`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setJualAgunans(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJualAgunanById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualAgunans/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoJual(response.data.noJual);
      setTglJual(formatDate(response.data.tglJual));
      setNoSbg(response.data.noSbg);
      setNamaPembeli(response.data.namaPembeli);
      setKeterangan(response.data.keterangan);
      setKetJam(response.data.jaminan.ketJam);
      setHargaTafsirJam(response.data.jaminan.hargaTafsirJam);
      setNilaiJual(response.data.nilaiJual);
      setPokok(response.data.pokok);
      setBunga(response.data.bunga);
      setDenda(response.data.denda);
      setPendapatanPenjualan(response.data.pendapatanPenjualan);
      setTitipanKonsumen(response.data.titipanKonsumen);
      setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);
      setIsPost(response.data.isPost);
    }
  };

  const deleteJualAgunan = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteJualAgunan/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      setTglJual("");
      setNoJual("");
      setNoSbg("");
      setNamaPembeli("");
      setKeterangan("");
      setNilaiJual("");
      setPokok("");
      setBunga("");
      setDenda("");
      setPendapatanPenjualan("");
      setTitipanKonsumen("");
      setKodeCOA("");
      navigate("/jualAgunan");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${tglJual} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Jual Agunan`, 85, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "JualAgunan",
    sheet: "DaftarJualAgunan",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Jual Agunan</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJualAgunans();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJualAgunans();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>No. Jual</th>
                <th>Tgl. Jual</th>
                <th>No. Sbg</th>
                <th>Nama Pembeli</th>
                <th>Agunan</th>
                <th>Harga Ag.</th>
                <th>Nilai Jual</th>
              </tr>
            </thead>
            <tbody>
              {jualAgunans.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noJual}</td>
                  <td>{user.tglJual}</td>
                  <td>{user.noSbg}</td>
                  <td>{user.namaPembeli}</td>
                  <td>{user.jaminan.ketJam}</td>
                  <td>{user.jaminan.hargaTafsirJam.toLocaleString("de-DE")}</td>
                  <td>{user.nilaiJual.toLocaleString("de-DE")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No. Jual</th>
                <th>Tgl. Jual</th>
                <th>No. Sbg</th>
                <th>Nama Pembeli</th>
                <th>Agunan</th>
                <th>Harga Ag.</th>
                <th>Nilai Jual</th>
              </tr>
              {jualAgunans.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noJual}</td>
                  <td>{user.tglJual}</td>
                  <td>{user.noSbg}</td>
                  <td>{user.namaPembeli}</td>
                  <td>{user.jaminan.ketJam}</td>
                  <td>{user.jaminan.hargaTafsirJam.toLocaleString("de-DE")}</td>
                  <td>{user.nilaiJual.toLocaleString("de-DE")}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/jualAgunan/tambahJualAgunan`}
          editLink={`/jualAgunan/${id}/edit`}
          deleteUser={deleteJualAgunan}
          nameUser={noJual}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Jual :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={noJual} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Sbg :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={noSbg} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Agunan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={ketJam}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Nama Pembeli :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={namaPembeli} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tgl. Jual :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={tglJual} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Harga Agunan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaTafsirJam.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nilai Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={nilaiJual.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Pokok :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={pokok.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Bunga :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={bunga.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Denda :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={denda.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Pend. Penjualan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={pendapatanPenjualan.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Titipan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={titipanKonsumen.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Kas :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={kodeCOA} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJualAgunan currentPosts={jualAgunansPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilJualAgunan;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
