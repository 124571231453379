import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrlFico } from "../../../contexts/ContextProvider";
import { ShowTableNeracaSaldo } from "../../../components/ShowTable";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Box, Typography } from "@mui/material";
import { Container } from "react-bootstrap";

const NeracaSaldo = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [isFetchError, setIsFetchError] = useState(false);
  const [neracaSaldos, setNeracaSaldosData] = useState([]);
  const [totalDebet, setTotalDebet] = useState(0);
  const [totalKredit, setTotalKredit] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNeracaSaldosData();
  }, []);

  const getNeracaSaldosData = async () => {
    let tempTotalDebet = 0;
    let tempTotalKredit = 0;
    setLoading(true);
    try {
      const neracaSaldos = await axios.post(
        `${tempUrlFico}/neracaSaldoPeriode`,
        {
          dariTanggal: user.tutupperiode.dariTanggal,
          sampaiTanggal: user.tutupperiode.sampaiTanggal,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setNeracaSaldosData(neracaSaldos.data);
      for (let i = 0; i < neracaSaldos.data.length; i++) {
        tempTotalDebet += neracaSaldos.data[i].debet;
        tempTotalKredit += neracaSaldos.data[i].kredit;
      }
      setTotalDebet(tempTotalDebet);
      setTotalKredit(tempTotalKredit);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Neraca Saldo</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Box sx={tableContainer}>
        <ShowTableNeracaSaldo
          currentPosts={neracaSaldos}
          totalDebet={totalDebet}
          totalKredit={totalKredit}
        />
      </Box>
    </Container>
  );
};

export default NeracaSaldo;

const subTitleText = {
  fontWeight: "900",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
