import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const Posting = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorInput, setOpenErrorInput] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleCloseErrorInput = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorInput(false);
  };

  const posting = async (e) => {
    let countNeracaSaldo = 0;
    var lastday = function (y, m) {
      return new Date(y, m, 0).getDate();
    };
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        let isDariTglBiggerThanSampaiTgl = dariTanggal > sampaiTanggal;
        if (isDariTglBiggerThanSampaiTgl) {
          setOpenErrorInput(true);
        } else {
          setLoading(true);
          let tempPersenLoading = parseInt(persenLoading);
          // Jurnal Posting Jurnal Umum
          await axios.post(`${tempUrlFico}/saveJurnalPostingJurnalUmum`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Approval
          await axios.post(`${tempUrlFico}/saveJurnalPostingApproval`, {
            piutangNasabahGadai: setting.piutangNasabahGadai,
            pendapatanAdministrasiGadai: setting.pendapatanAdministrasiGadai,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Topup
          await axios.post(`${tempUrlFico}/saveJurnalPostingTopup`, {
            piutangNasabahGadai: setting.piutangNasabahGadai,
            pendapatanAdministrasiGadai: setting.pendapatanAdministrasiGadai,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Tebus
          await axios.post(`${tempUrlFico}/saveJurnalPostingTebus`, {
            piutangNasabahGadai: setting.piutangNasabahGadai,
            pendapatanBungaPinjGadai: setting.pendapatanBungaPinjGadai,
            pendDendaPiutangGadai: setting.pendDendaPiutangGadai,
            kasPenerimaanGadai: setting.kasPenerimaanGadai,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Perpanjangan
          await axios.post(`${tempUrlFico}/saveJurnalPostingPerpanjangan`, {
            piutangNasabahGadai: setting.piutangNasabahGadai,
            pendapatanBungaPinjGadai: setting.pendapatanBungaPinjGadai,
            pendDendaPiutangGadai: setting.pendDendaPiutangGadai,
            pendapatanAdministrasiGadai: setting.pendapatanAdministrasiGadai,
            kasPenerimaanGadai: setting.kasPenerimaanGadai,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          // Jurnal Posting Cicilan
          await axios.post(`${tempUrlFico}/saveJurnalPostingCicilan`, {
            piutangNasabahGadai: setting.piutangNasabahGadai,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Jual Agunan
          await axios.post(`${tempUrlFico}/saveJurnalPostingJualAgunan`, {
            coaKasKasir: setting.coaKasKasir,
            piutangNasabahGadai: setting.piutangNasabahGadai,
            pendapatanBungaPinjGadai: setting.pendapatanBungaPinjGadai,
            pendDendaPiutangGadai: setting.pendDendaPiutangGadai,
            pendPenjualanBarangGadai: setting.pendPenjualanBarangGadai,
            titipanNasabah: setting.titipanNasabah,
            kasPenerimaanGadai: setting.kasPenerimaanGadai,
            biayaKerugian: setting.biayaKerugian,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Penerimaan Fidusia
          await axios.post(
            `${tempUrlFico}/saveJurnalPostingPenerimaanFidusia`,
            {
              piutangNasabahFidusia: setting.piutangNasabahFidusia,
              pendapatanBungaPinjFidusia: setting.pendapatanBungaPinjFidusia,
              pendDendaPiutangFidusia: setting.pendDendaPiutangFidusia,
              // pendPinaltiPiutangFidusia: setting.pendPinaltiPiutangFidusia,
              kasPenerimaanFidusia: setting.kasPenerimaanFidusia,
              dariTanggal,
              sampaiTanggal,
              userIdInput: user.id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Pengajuan Fidusia
          await axios.post(`${tempUrlFico}/saveJurnalPostingPengajuanFidusia`, {
            piutangNasabahFidusia: setting.piutangNasabahFidusia,
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Kas Masuk
          await axios.post(`${tempUrlFico}/saveJurnalPostingKasMasuk`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Kas Keluar
          await axios.post(`${tempUrlFico}/saveJurnalPostingKasKeluar`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Bank Masuk
          await axios.post(`${tempUrlFico}/saveJurnalPostingBankMasuk`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          // Jurnal Posting Bank Keluar
          await axios.post(`${tempUrlFico}/saveJurnalPostingBankKeluar`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());

          const allNeracaSaldo = await axios.post(
            `${tempUrlFico}/neracaSaldoAboveTanggal`,
            {
              dariTanggal,
              sampaiTanggal,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          const deletedNeracaSaldoAboveTanggal = await axios.post(
            `${tempUrlFico}/deleteNeracaSaldoAboveTanggal`,
            {
              dariTanggal,
              sampaiTanggal,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());

          const groupedNeracaSaldo = allNeracaSaldo.data.reduce(
            (group, neracaSaldo) => {
              const { tglNeracaSaldo } = neracaSaldo;
              group[tglNeracaSaldo] = group[tglNeracaSaldo] ?? [];
              group[tglNeracaSaldo].push(neracaSaldo);
              return group;
            },
            {}
          );
          let keys = Object.keys(groupedNeracaSaldo);

          // for (let i = 0; i < allNeracaSaldo.data.length; i++) {
          //   // Delete tglBigger Neraca Saldo
          //   await axios.post(
          //     `${tempUrlFico}/deleteNeracaSaldo/${allNeracaSaldo.data[i].id}`,
          //     {
          //       _id: user.id,
          //       token: user.token,
          //       kodeCabang: user.cabang.id,
          //     }
          //   );
          //   countNeracaSaldo++;
          // }
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());

          console.log(`countNeracaSaldo: ${countNeracaSaldo}`);
          console.log(
            `Object.keys(groupedNeracaSaldo).length: ${
              Object.keys(groupedNeracaSaldo).length
            }`
          );
          // if (countNeracaSaldo === 0) {
          console.log(`dariTanggal: ${dariTanggal}`);
          console.log(`sampaiTanggal: ${sampaiTanggal}`);
          // Make Last Neraca Saldo
          await axios.post(`${tempUrlFico}/saveLastNeracaSaldo`, {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          // Update Neraca Saldo Jurnal Posting
          await axios.post(
            `${tempUrlFico}/saveJurnalPostingNeracaSaldoRebuild2`,
            {
              dariTanggal,
              sampaiTanggal,
              userIdInput: user.id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          // await axios.post(`${tempUrlFico}/saveJurnalPostingNeracaSaldoRebuild`, {
          //   dariTanggal,
          //   sampaiTanggal,
          //   userIdInput: user.id,
          //   _id: user.id,
          //   token: user.token,
          //   kodeCabang: user.cabang.id,
          // });
          // await axios.post(`${tempUrlFico}/saveJurnalPostingNeracaSaldo`, {
          //   dariTanggal,
          //   sampaiTanggal,
          //   userIdInput: user.id,
          //   _id: user.id,
          //   token: user.token,
          //   kodeCabang: user.cabang.id,
          // });

          // }
          // else {
          //   for (let i = 0; i < Object.keys(groupedNeracaSaldo).length; i++) {
          //     // Make Last Neraca Saldo
          //     await axios.post(`${tempUrlFico}/saveLastNeracaSaldo`, {
          //       dariTanggal,
          //       sampaiTanggal,
          //       userIdInput: user.id,
          //       _id: user.id,
          //       token: user.token,
          //       kodeCabang: user.cabang.id,
          //     });
          //     // Update Neraca Saldo Jurnal Posting
          //     await axios.post(`${tempUrlFico}/saveJurnalPostingNeracaSaldo`, {
          //       dariTanggal,
          //       sampaiTanggal,
          //       userIdInput: user.id,
          //       _id: user.id,
          //       token: user.token,
          //       kodeCabang: user.cabang.id,
          //     });

          //     console.log(`dariTanggal: ${dariTanggal}`);
          //     dariTanggal = new Date(
          //       dariTanggal.setMonth(dariTanggal.getMonth() + 1)
          //     );
          //     console.log(`New dariTanggal: ${dariTanggal}`);
          //     console.log(`sampaiTanggal: ${sampaiTanggal}`);
          //     sampaiTanggal = new Date(
          //       dariTanggal.getFullYear() +
          //         "-" +
          //         (dariTanggal.getMonth() + 1) +
          //         "-" +
          //         lastday("1", dariTanggal.getMonth() + 1)
          //     );
          //     console.log(`New sampaiTanggal: ${sampaiTanggal}`);
          //   }
          // }
          tempPersenLoading += 5;
          setPersenLoading(tempPersenLoading.toString());
          setLoading(false);
          setOpen(true);
          setPersenLoading("0");
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpenError(!openError);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Posting</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Card>
        <Card.Header>Rentang Tanggal</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={posting}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={dariTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setDariTanggal(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setSampaiTanggal(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                startIcon={<DriveFileRenameOutlineIcon />}
                type="submit"
                disabled={
                  new Date(user.tutupperiode.dariTanggal) <
                  new Date(setting.programDariTanggal)
                    ? true
                    : false
                }
              >
                Posting
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal berhasil diposting!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorInput}
        autoHideDuration={6000}
        onClose={handleCloseErrorInput}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseErrorInput}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Sampai Tanggal tidak boleh lebih besar Dari Tanggal
        </Alert>
      </Snackbar>
      {error && (
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert onClose={handleCloseError} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default Posting;

const spacingTop = {
  mt: 4,
};

const subTitleText = {
  fontWeight: "900",
};

const alertBox = {
  width: "100%",
};
