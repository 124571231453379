import React, { useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaBook,
  FaUserCog,
  FaSignOutAlt,
  FaDochub,
  FaFileInvoiceDollar,
  FaClipboardList,
  FaExchangeAlt,
  FaMotorcycle,
  FaCity,
} from "react-icons/fa";

const Sidebar = ({
  image,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const { user, dispatch, setting } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutButtonHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* Header */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight />}
              onClick={handleCollapsedChange}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FaAngleDoubleLeft />}
              onClick={handleCollapsedChange}
            >
              <div
                style={{
                  padding: "9px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 15,
                  letterSpacing: "1px",
                }}
              >
                {setting.namaProgram} {setting.namaSoftware}
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          <SubMenu title={"Master"} icon={<FaBook />}>
            {user.akses.jaminan === true && (
              <SubMenu title={"Jaminan"}>
                <MenuItem>
                  Kategori <NavLink to="/kategoriJaminan" />
                </MenuItem>
                <MenuItem>
                  Jenis Jaminan <NavLink to="/jenisJaminan" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.marketing === true && (
              <MenuItem>
                Marketing <NavLink to="/marketing" />
              </MenuItem>
            )}
            {user.akses.bukuBesar === true && (
              <SubMenu title={"Buku Besar"}>
                <MenuItem>
                  Kelompok COA <NavLink to="/jenisCoa" />
                </MenuItem>
                <MenuItem>
                  Group COA <NavLink to="/groupCoa" />
                </MenuItem>
                <MenuItem>
                  SubGroup COA <NavLink to="/subGroupCoa" />
                </MenuItem>
                <MenuItem>
                  COA <NavLink to="/coa" />
                </MenuItem>
                <MenuItem>
                  Form Laba Rugi OJK <NavLink to="/formLabaRugiOJK" />
                </MenuItem>
                <MenuItem>
                  Form Neraca OJK <NavLink to="/formNeracaOJK" />
                </MenuItem>
                <MenuItem>
                  Form Arus Kas OJK <NavLink to="/formArusKasOJK" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.area === true && (
              <SubMenu title={"Area"}>
                <MenuItem>
                  Provinsi <NavLink to="/provinsi" />
                </MenuItem>
                <MenuItem>
                  Kabupaten / Kota <NavLink to="/kabupaten" />
                </MenuItem>
                <MenuItem>
                  Kecamatan <NavLink to="/kecamatan" />
                </MenuItem>
                <MenuItem>
                  Kelurahan / Desa <NavLink to="/kelurahan" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.customer === true && (
              <MenuItem>
                Customer <NavLink to="/customer" />
              </MenuItem>
            )}
            {user.akses.surveyor === true && (
              <MenuItem>
                Surveyor <NavLink to="/surveyor" />
              </MenuItem>
            )}
            {user.akses.eksekutor === true && (
              <MenuItem>
                Eksekutor <NavLink to="/eksekutor" />
              </MenuItem>
            )}
            {user.akses.tipe === true && (
              <MenuItem>
                Tipe <NavLink to="/tipe" />
              </MenuItem>
            )}
            {user.akses.warna === true && (
              <MenuItem>
                Warna <NavLink to="/warna" />
              </MenuItem>
            )}
            {/* {user.akses.cabang === true && (
              <MenuItem>
                Cabang <NavLink to="/cabang" />
              </MenuItem>
            )} */}
          </SubMenu>
          <SubMenu title={"Gadai"} icon={<FaExchangeAlt />}>
            <SubMenu title={"Transaksi"}>
              {user.akses.pengajuan === true && (
                <MenuItem>
                  Pengajuan <NavLink to="/daftarPengajuan" />
                </MenuItem>
              )}
              {user.akses.approval === true && (
                <MenuItem>
                  Approval <NavLink to="/daftarApproval" />
                </MenuItem>
              )}
              {user.akses.buktiPencairan === true && (
                <MenuItem>
                  Bukti Pencairan <NavLink to="/daftarBuktiPencairan" />
                </MenuItem>
              )}
              {user.akses.topup === true && (
                <MenuItem>
                  Topup <NavLink to="/daftarTopup" />
                </MenuItem>
              )}
              {user.akses.tebus === true && (
                <MenuItem>
                  Tebus <NavLink to="/daftarTebus" />
                </MenuItem>
              )}
              {user.akses.perpanjangan === true && (
                <MenuItem>
                  Perpanjangan <NavLink to="/daftarPerpanjangan" />
                </MenuItem>
              )}
              {user.akses.cicilan === true && (
                <MenuItem>
                  Cicilan <NavLink to="/daftarCicilan" />
                </MenuItem>
              )}
              {user.akses.jualAgunan === true && (
                <MenuItem>
                  Jual Agunan <NavLink to="/jualAgunan" />
                </MenuItem>
              )}
              {user.akses.infoNasabahGadai === true && (
                <MenuItem>
                  Info Nasabah <NavLink to="/daftarInfoNasabahGadai" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu title={"Laporan"}>
              {user.akses.lapTunggakan === true && (
                <MenuItem>
                  Tunggakan <NavLink to="/laporanTunggakan" />
                </MenuItem>
              )}
              {user.akses.lapTunggakanRinci === true && (
                <MenuItem>
                  Tunggakan Rinci <NavLink to="/laporanTunggakanRinci" />
                </MenuItem>
              )}
              {user.akses.lapOutstanding === true && (
                <MenuItem>
                  Total Outstanding <NavLink to="/laporanTotalPiutang" />
                </MenuItem>
              )}
              {user.akses.lapStockSiapDijual === true && (
                <MenuItem>
                  Stock Siap Dijual <NavLink to="/laporanStockSiapDijual" />
                </MenuItem>
              )}
              {user.akses.lapPenerimaan === true && (
                <MenuItem>
                  Penerimaan <NavLink to="/laporanPenerimaan" />
                </MenuItem>
              )}
              {user.akses.lapJualBarang === true && (
                <MenuItem>
                  Jual Barang <NavLink to="/laporanJualBarang" />
                </MenuItem>
              )}
              {user.akses.lapOutstandingGadai === true && (
                <MenuItem>
                  Outstanding <NavLink to="/laporanOutstanding" />
                </MenuItem>
              )}
              {user.akses.lapPiutangLunasGadai === true && (
                <MenuItem>
                  Piutang Lunas <NavLink to="/laporanPiutangLunas" />
                </MenuItem>
              )}
            </SubMenu>
          </SubMenu>
          <SubMenu title={"Fidusia"} icon={<FaMotorcycle />}>
            <SubMenu title={"Transaksi"}>
              {user.akses.pengajuanFidusia === true && (
                <MenuItem>
                  Pengajuan <NavLink to="/daftarPengajuanFidusia" />
                </MenuItem>
              )}
              {user.akses.approvalFidusia === true && (
                <MenuItem>
                  Approval <NavLink to="/daftarApprovalFidusia" />
                </MenuItem>
              )}
              {user.akses.buktiPencairanFidusia === true && (
                <MenuItem>
                  Bukti Pencairan <NavLink to="/daftarBuktiPencairanFidusia" />
                </MenuItem>
              )}
              {user.akses.angsuranFidusia === true && (
                <MenuItem>
                  Angsuran <NavLink to="/daftarAngsuranFidusia" />
                </MenuItem>
              )}
              {user.akses.percepatanFidusia === true && (
                <MenuItem>
                  Entry Percepatan <NavLink to="/tambahPercepatanFidusia" />
                </MenuItem>
              )}
              {user.akses.infoNasabahFidusia === true && (
                <MenuItem>
                  Info Nasabah <NavLink to="/daftarInfoNasabahFidusia" />
                </MenuItem>
              )}
              {user.akses.penarikanFidusia === true && (
                <MenuItem>
                  Penarikan <NavLink to="/daftarPenarikanFidusia" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu title={"Surat"}>
              {user.akses.sp1 === true && (
                <MenuItem>
                  SP-1 <NavLink to="/daftarSp1Fidusia" />
                </MenuItem>
              )}
              {user.akses.sp2 === true && (
                <MenuItem>
                  SP-2 <NavLink to="/daftarSp2Fidusia" />
                </MenuItem>
              )}
              {user.akses.sp3 === true && (
                <MenuItem>
                  SP-3 <NavLink to="/daftarSp3Fidusia" />
                </MenuItem>
              )}
              {user.akses.sPanggilan === true && (
                <MenuItem>
                  S. Panggilan <NavLink to="/daftarSuratPanggilanFidusia" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu title={"Laporan"}>
              {user.akses.lapTunggakanFidusia === true && (
                <MenuItem>
                  Tunggakan <NavLink to="/laporanTunggakanFidusia" />
                </MenuItem>
              )}
              {user.akses.lapTunggakanFidusiaRinci === true && (
                <MenuItem>
                  Tunggakan Rinci
                  <NavLink to="/laporanTunggakanFidusiaRinci" />
                </MenuItem>
              )}
              {user.akses.lapTotalPiutangFidusia === true && (
                <MenuItem>
                  Total Outstanding <NavLink to="/laporanTotalPiutangFidusia" />
                </MenuItem>
              )}
              {user.akses.lapPinjamanJatuhTempoFidusia === true && (
                <MenuItem>
                  Pinjaman Jatuh Tempo{" "}
                  <NavLink to="/laporanPinjamanJatuhTempoFidusia" />
                </MenuItem>
              )}
              {user.akses.lapPenerimaanFidusia === true && (
                <MenuItem>
                  Penerimaan <NavLink to="/laporanPenerimaanFidusia" />
                </MenuItem>
              )}
              {user.akses.lapOutstandingFidusia === true && (
                <MenuItem>
                  Outstanding <NavLink to="/laporanOutstandingFidusia" />
                </MenuItem>
              )}
              {user.akses.lapPiutangLunasFidusia === true && (
                <MenuItem>
                  Piutang Lunas <NavLink to="/laporanPiutangLunasFidusia" />
                </MenuItem>
              )}
              {user.akses.lapPenarikanFidusia === true && (
                <MenuItem>
                  Penarikan <NavLink to="/laporanPenarikanFidusia" />
                </MenuItem>
              )}
            </SubMenu>
          </SubMenu>
          <SubMenu title={"Finance"} icon={<FaFileInvoiceDollar />}>
            {user.akses.kasMasuk === true && (
              <MenuItem>
                Kas Masuk <NavLink to="/daftarKasMasuk" />
              </MenuItem>
            )}
            {user.akses.kasKeluar === true && (
              <MenuItem>
                Kas Keluar <NavLink to="/daftarKasKeluar" />
              </MenuItem>
            )}
            {user.akses.bankMasuk === true && (
              <MenuItem>
                Bank Masuk <NavLink to="/daftarBankMasuk" />
              </MenuItem>
            )}
            {user.akses.bankKeluar === true && (
              <MenuItem>
                Bank Keluar <NavLink to="/daftarBankKeluar" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu title={"Accounting"} icon={<FaClipboardList />}>
            {user.akses.jurnal === true && (
              <MenuItem>
                Jurnal <NavLink to="/jurnal" />
              </MenuItem>
            )}
            {user.akses.jurnalUmum === true && (
              <MenuItem>
                Jurnal Umum <NavLink to="/daftarJurnalUmum" />
              </MenuItem>
            )}
            {user.akses.jurnalUmum === true && (
              <MenuItem>
                Lap. Jurnal Umum <NavLink to="/laporanJurnalUmum" />
              </MenuItem>
            )}
            {user.akses.posting === true && (
              <MenuItem>
                Posting <NavLink to="/posting" />
              </MenuItem>
            )}
            {user.akses.unposting === true && (
              <MenuItem>
                Unposting <NavLink to="/unposting" />
              </MenuItem>
            )}
            {user.akses.aktivitasBukuBesar === true && (
              <MenuItem>
                Aktivitas Buku Besar <NavLink to="/aktivitasBukuBesar" />
              </MenuItem>
            )}
            {user.akses.neracaSaldo === true && (
              <MenuItem>
                Neraca Saldo <NavLink to="/neracaSaldo" />
              </MenuItem>
            )}
            {user.akses.labaRugiRekap === true && (
              <MenuItem>
                Laba Rugi Rekap <NavLink to="/labaRugiRekap" />
              </MenuItem>
            )}
            {user.akses.labaRugi === true && (
              <MenuItem>
                Laba Rugi <NavLink to="/labaRugi" />
              </MenuItem>
            )}
            {user.akses.neraca === true && (
              <MenuItem>
                Neraca <NavLink to="/neraca" />
              </MenuItem>
            )}
            {user.akses.arusKasRekap === true && (
              <MenuItem>
                Arus Kas Rekap <NavLink to="/arusKasRekap" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu title={"Accounting OJK"} icon={<FaCity />}>
            {user.akses.labaRugiOjk === true && (
              <MenuItem>
                Laba Rugi OJK <NavLink to="/labaRugiOjk" />
              </MenuItem>
            )}
            {user.akses.neracaOjk === true && (
              <MenuItem>
                Neraca OJK <NavLink to="/neracaOjk" />
              </MenuItem>
            )}
            {user.akses.arusKasOjk === true && (
              <MenuItem>
                Arus Kas OJK <NavLink to="/arusKasOjk" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu title={"Utility"} icon={<FaUserCog />}>
            {user.akses.profilUser === true && (
              <MenuItem>
                Profil User <NavLink to="/profilUser" />
              </MenuItem>
            )}
            {user.akses.daftarUser === true && (
              <MenuItem>
                Daftar User <NavLink to="/daftarUser" />
              </MenuItem>
            )}
            {user.akses.tutupPeriode === true && (
              <MenuItem>
                Tutup Periode
                <NavLink to="/tutupPeriode" />
              </MenuItem>
            )}
            {user.akses.gantiPeriode === true && (
              <MenuItem>
                Ganti Periode <NavLink to="/gantiPeriode" />
              </MenuItem>
            )}
            {user.tipeUser === "OWNER/DIREKSI" &&
              user.akses.setting === true && (
                <MenuItem>
                  Setting <NavLink to="/setting" />
                </MenuItem>
              )}
          </SubMenu>
        </Menu>
      </SidebarContent>
      {/* Footer */}
      <SidebarFooter style={{ textAlign: "center" }}>
        <p style={{ fontSize: "12px", marginTop: "10px" }}>{user.username}</p>
        <p style={{ fontSize: "12px", marginTop: "-10px" }}>
          Cabang : {user.cabang.namaCabang}
        </p>
        <div className="sidebar-btn-wrapper" style={{ paddingBottom: "10px" }}>
          <Link
            className="sidebar-btn"
            style={{ cursor: "pointer" }}
            to="/"
            onClick={logoutButtonHandler}
          >
            <span style={{ marginRight: "6px" }}>Logout</span>
            <FaSignOutAlt />
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
