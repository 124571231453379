import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Button, ButtonGroup, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const TampilSetting = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [settingId, setSettingId] = useState("");

  // GADAI
  const [batasPinjamanDenda, setBatasPinjamanDenda] = useState("");
  const [denda1, setDenda1] = useState("");
  const [denda2, setDenda2] = useState("");
  const [lamaJtTempo, setLamaJtTempo] = useState("");
  const [kategoriPercepatan, setKategoriPercepatan] = useState("");
  const [hariBatasPercepatan1, setHariBatasPercepatan1] = useState("");
  const [diskonBatasPercepatan1, setDiskonBatasPercepatan1] = useState("");
  const [hariBatasPercepatan2, setHariBatasPercepatan2] = useState("");
  const [diskonBatasPercepatan2, setDiskonBatasPercepatan2] = useState("");
  const [hariBatasPercepatan3, setHariBatasPercepatan3] = useState("");
  const [diskonBatasPercepatan3, setDiskonBatasPercepatan3] = useState("");
  const [batasBunga, setBatasBunga] = useState("");
  const [batasJual, setBatasJual] = useState("");
  const [lamaHariBukaJual, setLamaHariBukaJual] = useState("");
  const [persenUntungJual, setPersenUntungJual] = useState("");
  const [feeAdmPerpanjanganGadai, setFeeAdmPerpanjanganGadai] = useState("");
  const [feeAdmGadai, setFeeAdmGadai] = useState("");
  const [isOpenFeeAdmGadai, setIsOpenFeeAdmGadai] = useState("");

  // COA GADAI
  const [coaKasKasir, setCoaKasKasir] = useState("");
  const [coaKodeKas, setCoaKodeKas] = useState("");
  const [piutangNasabahGadai, setPiutangNasabahGadai] = useState("");
  const [pendapatanAdministrasiGadai, setPendapatanAdministrasiGadai] =
    useState("");
  const [pendapatanBungaPinjGadai, setPendapatanBungaPinjGadai] = useState("");
  const [kasPenerimaanGadai, setKasPenerimaanGadai] = useState("");
  const [pendDendaPiutangGadai, setPendDendaPiutangGadai] = useState("");
  const [pendPinaltiPiutangFidusia, setPendPinaltiPiutangFidusia] =
    useState("");
  const [pendPenjualanBarangGadai, setPendPenjualanBarangGadai] = useState("");

  // FIDUSIA
  const [toleransiFidusia, setToleransiFidusia] = useState("");
  const [persenDendaFidusia, setPersenDendaFidusia] = useState("");
  const [sp1, setSp1] = useState("");
  const [sp2, setSp2] = useState("");
  const [sp3, setSp3] = useState("");
  const [sp1LambatBayar, setSp1LambatBayar] = useState("");
  const [sp2LambatBayar, setSp2LambatBayar] = useState("");
  const [sp3LambatBayar, setSp3LambatBayar] = useState("");
  const [sPanggilan, setSPanggilan] = useState("");

  // COA FIDUSIA
  const [kasPenerimaanFidusia, setKasPenerimaanFidusia] = useState("");
  const [piutangNasabahFidusia, setPiutangNasabahFidusia] = useState("");
  const [pendapatanBungaPinjFidusia, setPendapatanBungaPinjFidusia] =
    useState("");
  const [pendDendaPiutangFidusia, setPendDendaPiutangFidusia] = useState("");

  // Coa
  const [labaRugiPeriodeBerjalan, setLabaRugiPeriodeBerjalan] = useState("");
  const [accountPembalance, setAccountPembalance] = useState("");
  const [groupKewajibanHutang, setGroupKewajibanHutang] = useState("");
  const [groupModalSaham, setGroupModalSaham] = useState("");
  const [groupAktivaLancar, setGroupAktivaLancar] = useState("");
  const [groupAktivaTetap, setGroupAktivaTetap] = useState("");
  const [kodeGroupPendapatan, setKodeGroupPendapatan] = useState("");
  const [kodeGroupBiayaAtasPendapatan, setKodeGroupBiayaAtasPendapatan] =
    useState("");
  const [kodeGroupPengeluaranOperasional, setKodeGroupPengeluaranOperasional] =
    useState("");
  const [kodeGroupPendapatanLainLain, setKodeGroupPendapatanLainLain] =
    useState("");
  const [kodeGroupBiayaLainLain, setKodeGroupBiayaLainLain] = useState("");
  const [subGroupCoaKas, setSubGroupCoaKas] = useState("");
  const [titipanNasabah, setTitipanNasabah] = useState("");
  const [biayaKerugian, setBiayaKerugian] = useState("");

  // Coa Ojk
  const [
    urutanCoaOjkLabaRugiJumlahPendapatan,
    setUrutanCoaOjkLabaRugiJumlahPendapatan,
  ] = useState("");
  const [urutanCoaOjkLabaRugiJumlahBeban, setUrutanCoaOjkLabaRugiJumlahBeban] =
    useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSettingByCabang();
  }, []);

  const getSettingByCabang = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/lastSetting`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setSettingId(response.data.id);

      // GADAI
      setBatasPinjamanDenda(response.data.batasPinjamanDenda);
      setDenda1(response.data.denda1);
      setDenda2(response.data.denda2);
      setLamaJtTempo(response.data.lamaJtTempo);
      setKategoriPercepatan(response.data.kategoriPercepatan);
      setHariBatasPercepatan1(response.data.hariBatasPercepatan1);
      setDiskonBatasPercepatan1(response.data.diskonBatasPercepatan1);
      setHariBatasPercepatan2(response.data.hariBatasPercepatan2);
      setDiskonBatasPercepatan2(response.data.diskonBatasPercepatan2);
      setHariBatasPercepatan3(response.data.hariBatasPercepatan3);
      setDiskonBatasPercepatan3(response.data.diskonBatasPercepatan3);
      setBatasBunga(response.data.batasBunga);
      setBatasJual(response.data.batasJual);
      setLamaHariBukaJual(response.data.lamaHariBukaJual);
      setPersenUntungJual(response.data.persenUntungJual);
      setFeeAdmPerpanjanganGadai(response.data.feeAdmPerpanjanganGadai);
      setFeeAdmGadai(response.data.feeAdmGadai);
      setIsOpenFeeAdmGadai(
        response.data.isOpenFeeAdmGadai === true ? "YA" : "TIDAK"
      );

      // COA GADAI
      setCoaKasKasir(response.data.coaKasKasir);
      setCoaKodeKas(response.data.coaKodeKas);
      setPiutangNasabahGadai(response.data.piutangNasabahGadai);
      setPendapatanAdministrasiGadai(response.data.pendapatanAdministrasiGadai);
      setPendapatanBungaPinjGadai(response.data.pendapatanBungaPinjGadai);
      setKasPenerimaanGadai(response.data.kasPenerimaanGadai);
      setPendDendaPiutangGadai(response.data.pendDendaPiutangGadai);
      setPendPinaltiPiutangFidusia(response.data.pendPinaltiPiutangFidusia);
      setPendPenjualanBarangGadai(response.data.pendPenjualanBarangGadai);

      // FIDUSIA
      setToleransiFidusia(response.data.toleransiFidusia);
      setPersenDendaFidusia(response.data.persenDendaFidusia);
      setSp1(response.data.sp1);
      setSp2(response.data.sp2);
      setSp3(response.data.sp3);
      setSp1LambatBayar(response.data.sp1LambatBayar);
      setSp2LambatBayar(response.data.sp2LambatBayar);
      setSp3LambatBayar(response.data.sp3LambatBayar);
      setSPanggilan(response.data.sPanggilan);

      // COA FIDUSIA
      setKasPenerimaanFidusia(response.data.kasPenerimaanFidusia);
      setPiutangNasabahFidusia(response.data.piutangNasabahFidusia);
      setPendapatanBungaPinjFidusia(response.data.pendapatanBungaPinjFidusia);
      setPendDendaPiutangFidusia(response.data.pendDendaPiutangFidusia);

      // COA
      setLabaRugiPeriodeBerjalan(response.data.labaRugiPeriodeBerjalan);
      setAccountPembalance(response.data.accountPembalance);
      setGroupKewajibanHutang(response.data.groupKewajibanHutang);
      setGroupModalSaham(response.data.groupModalSaham);
      setGroupAktivaLancar(response.data.groupAktivaLancar);
      setGroupAktivaTetap(response.data.groupAktivaTetap);
      setKodeGroupPendapatan(response.data.kodeGroupPendapatan);
      setKodeGroupBiayaAtasPendapatan(
        response.data.kodeGroupBiayaAtasPendapatan
      );
      setKodeGroupPengeluaranOperasional(
        response.data.kodeGroupPengeluaranOperasional
      );
      setKodeGroupPendapatanLainLain(response.data.kodeGroupPendapatanLainLain);
      setKodeGroupBiayaLainLain(response.data.kodeGroupBiayaLainLain);
      setSubGroupCoaKas(response.data.subGroupCoaKas);
      setTitipanNasabah(response.data.titipanNasabah);
      setBiayaKerugian(response.data.biayaKerugian);

      // COA OJK
      setUrutanCoaOjkLabaRugiJumlahPendapatan(
        response.data.urutanCoaOjkLabaRugiJumlahPendapatan
      );
      setUrutanCoaOjkLabaRugiJumlahBeban(
        response.data.urutanCoaOjkLabaRugiJumlahBeban
      );
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Setting</h5>
      <Container className="d-flex justify-content-center">
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/setting/${settingId}/edit`);
            }}
          >
            Ubah Setting
          </Button>
        </ButtonGroup>
      </Container>
      <hr />
      <Container>
        <Form>
          <h5>GADAI</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Denda 1 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={denda1.toLocaleString("de-DE")}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Lama Jatuh Tempo :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${lamaJtTempo} hari`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Hari Batas Percepatan 1 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hariBatasPercepatan1}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Hari Batas Percepatan 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hariBatasPercepatan2}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Hari Batas Percepatan 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hariBatasPercepatan3}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Batas Bunga :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={batasBunga} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Untung Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${persenUntungJual} %`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Fee Adm. :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${feeAdmGadai} %`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Buka Fee Adm. :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={isOpenFeeAdmGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>

            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Batas Pinjaman Denda :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={batasPinjamanDenda.toLocaleString("de-DE")}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Denda 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={denda2.toLocaleString("de-DE")}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kategori Percepatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kategoriPercepatan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Diskon Batas Percepatan 1 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={diskonBatasPercepatan1}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Diskon Batas Percepatan 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={diskonBatasPercepatan2}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Diskon Batas Percepatan 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={diskonBatasPercepatan3}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Batas Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={batasJual} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Lama Hari Buka Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={lamaHariBukaJual}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Fee Adm. Perpanjangan Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${feeAdmPerpanjanganGadai}%`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>

          <hr />
          <h5>COA GADAI</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Coa Kas Kasir :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={coaKasKasir} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Coa Kode Kas Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={coaKodeKas} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kas Penerimaan Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kasPenerimaanGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pendapatan Administrasi Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendapatanAdministrasiGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Denda Piutang Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendDendaPiutangGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Pinalti Piutang Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendPinaltiPiutangFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Piutang Nasabah Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={piutangNasabahGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Penjualan Barang Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendPenjualanBarangGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Bunga Pinj. Gadai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendapatanBungaPinjGadai}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>

          <hr />
          <h5>FIDUSIA</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      toleransiFidusia (hari) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={toleransiFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      SP-1 (hari) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={sp1} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      SP-2 (hari) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={sp2} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      SP-3 (hari) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={sp3} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      S. Panggilan (hari) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={sPanggilan} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Denda (%) :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={persenDendaFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Lambar Bayar SP-1 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${sp1LambatBayar} hari`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Lambar Bayar SP-2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${sp2LambatBayar} hari`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Lambar Bayar SP-3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${sp3LambatBayar} hari`}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>

          <hr />
          <h5>COA FIDUSIA</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kas Penerimaan Fidusia:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kasPenerimaanFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Piutang Nasabah Fidusia:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={piutangNasabahFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Bunga Pinj. Fidusia:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendapatanBungaPinjFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Pend. Denda Piutang Fidusia:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={pendDendaPiutangFidusia}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>

          <hr />
          <h5>COA</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Laba Rugi Periode Berjalan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={labaRugiPeriodeBerjalan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Group Kewajiban Hutang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={groupKewajibanHutang}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Group Aktiva Lancar :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={groupAktivaLancar}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kode Group Pendapatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeGroupPendapatan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kode Group Biaya Atas Pendapatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeGroupBiayaAtasPendapatan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kode Group Pengeluaran Operasional :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeGroupPengeluaranOperasional}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kode Group Pendapatan Lain-Lain :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeGroupPendapatanLainLain}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Kode Group Biaya Lain-Lain :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeGroupBiayaLainLain}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Sub Group Coa Kas :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={subGroupCoaKas} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Biaya Kerugian :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={biayaKerugian} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>

            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Account Pembalance :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={accountPembalance}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Group Modal Saham :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={groupModalSaham} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Group Aktiva Tetap :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={groupAktivaTetap}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Titipan Nasabah :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={titipanNasabah} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>

          <hr />
          <h5>COA</h5>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Urutan Coa Ojk Laba Rugi Jumlah Pendapatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={urutanCoaOjkLabaRugiJumlahPendapatan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Urutan Coa Ojk Laba Rugi Beban :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={urutanCoaOjkLabaRugiJumlahBeban}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}></Box>
          </Box>
        </Form>
      </Container>
    </Container>
  );
};

export default TampilSetting;

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
