import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UbahCustomer = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alamatSama, setAlamatSama] = useState(false);
  const [cif, setCif] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState(new Date());
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [ketProvinsi, setKetProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [ketProvinsiTT, setKetProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [ketKabupaten, setKetKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [ketKabupatenTT, setKetKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [ketKecamatan, setKetKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [ketKecamatanTT, setKetKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [ketKelurahan, setKetKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [ketKelurahanTT, setKetKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");

  const [kelurahans, setKelurahans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [kabupatens, setKabupatens] = useState([]);
  const [provinsis, setProvinsis] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisKelaminOption = ["LAKI-LAKI", "PEREMPUAN"];
  const agamaOption = [
    "ISLAM",
    "PROTESTAN",
    "KATOLIK",
    "HINDU",
    "BUDHA",
    "KHONGHUCU",
    "LAIN",
  ];
  const statusPerkawinanOption = ["BELUM MENIKAH", "(JANDA/DUDA)", "MENIKAH"];
  const kewarganegaraanOption = ["WNI", "WNA"];
  let kelurahanOptions = kelurahans.map((kelurahan) => ({
    label: `${kelurahan.id} - ${kelurahan.namaKelurahan}`,
  }));
  let kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.id} - ${kecamatan.namaKecamatan}`,
  }));
  let kabupatenOptions = kabupatens.map((kabupaten) => ({
    label: `${kabupaten.id} - ${kabupaten.namaKabupaten}`,
  }));
  let provinsiOptions = provinsis.map((provinsi) => ({
    label: `${provinsi.id} - ${provinsi.namaProvinsi}`,
  }));

  useEffect(() => {
    getCustomerById();
    getAreasData();
  }, []);

  const getCustomerById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/customers/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setCif(response.data.id);
    setNikCustomer(response.data.nikCustomer);
    setNoKkCustomer(response.data.noKkCustomer);
    setNamaCustomer(response.data.namaCustomer);
    setTempatLahirCustomer(response.data.tempatLahirCustomer);
    setTanggalLahirCustomer(new Date(response.data.tanggalLahirCustomer));
    setJenisKelaminCustomer(response.data.jenisKelaminCustomer);
    setAgamaCustomer(response.data.agamaCustomer);
    setNoTeleponCustomer(response.data.noTeleponCustomer);
    setAlamatCustomer(response.data.alamatCustomer);
    setAlamatTTCustomer(response.data.alamatTTCustomer);
    setKodeProvinsi(response.data.provinsis.id);
    setKodeProvinsiTT(response.data.provinsisTT.id);
    setKodeKabupaten(response.data.kabupaten.id);
    setKodeKabupatenTT(response.data.kabupatenTT.id);
    setKodeKecamatan(response.data.kecamatan.id);
    setKodeKecamatanTT(response.data.kecamatanTT.id);
    setKodeKelurahan(response.data.kelurahan.id);
    setKodeKelurahanTT(response.data.kelurahanTT.id);
    setKodePos(response.data.kelurahan.kodePos);
    setKodePosTT(response.data.kelurahanTT.kodePos);
    setStatusPerkawinanCustomer(response.data.statusPerkawinanCustomer);
    setPekerjaanCustomer(response.data.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.kewarganegaraanCustomer);
    setLoading(false);
  };

  const getAreasData = async () => {
    const allKelurahan = await axios.post(`${tempUrl}/kelurahans`, {
      _id: user.id,
      token: user.token,
    });
    setKelurahans(allKelurahan.data);

    const allKecamatan = await axios.post(`${tempUrl}/kecamatans`, {
      _id: user.id,
      token: user.token,
    });
    setKecamatans(allKecamatan.data);

    const allKabupaten = await axios.post(`${tempUrl}/kabupatens`, {
      _id: user.id,
      token: user.token,
    });
    setKabupatens(allKabupaten.data);

    const allProvinsi = await axios.post(`${tempUrl}/provinsis`, {
      _id: user.id,
      token: user.token,
    });
    setProvinsis(allProvinsi.data);
  };

  const findKelurahan = async (kelurahanId) => {
    if (kelurahanId) {
      const response = await axios.post(
        `${tempUrl}/kelurahans/${kelurahanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKelurahan(kelurahanId);
      if (kelurahanId == "99999999") {
        setKodeProvinsi("");
        setKodeKabupaten("");
        setKodeKecamatan("");
        setKodePos("");
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatan(
          `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
        );
        setKodePos(response.data.kodePos);
      }
    }
  };

  const findKelurahanTT = async (kelurahanId) => {
    let tempKelurahanId = kelurahanId.split(" ", 1)[0];
    if (kelurahanId) {
      const response = await axios.post(
        `${tempUrl}/kelurahans/${tempKelurahanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKelurahanTT(kelurahanId);
      if (tempKelurahanId == "99999999") {
        setKodeProvinsiTT("");
        setKodeKabupatenTT("");
        setKodeKecamatanTT("");
        if (alamatSama && kodePos.length > 0) {
          setKodePosTT(kodePos);
        } else {
          setKodePosTT("");
        }
        return;
      }
      if (response.data) {
        setKodeProvinsiTT(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenTT(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatanTT(
          `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
        );
        setKodePosTT(response.data.kodePos);
      }
    }
  };

  const findKecamatan = async (kecamatanId) => {
    kecamatanId = kecamatanId.split(" ", 1)[0];
    if (kecamatanId) {
      const response = await axios.post(
        `${tempUrl}/kecamatans/${kecamatanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKecamatan(kecamatanId);
      if (kecamatanId == "999999") {
        setKodeProvinsi("");
        setKodeKabupaten("");
        setKodeKecamatan(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatan(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
      }
    }
  };

  const findKecamatanTT = async (kecamatanId) => {
    kecamatanId = kecamatanId.split(" ", 1)[0];
    if (kecamatanId) {
      const response = await axios.post(
        `${tempUrl}/kecamatans/${kecamatanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKecamatanTT(kecamatanId);
      if (kecamatanId == "999999") {
        setKodeProvinsiTT("");
        setKodeKabupatenTT("");
        setKodeKecamatanTT(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsiTT(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenTT(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatanTT(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
      }
    }
  };

  const findKabupaten = async (kabupatenId) => {
    kabupatenId = kabupatenId.split(" ", 1)[0];
    if (kabupatenId) {
      const response = await axios.post(
        `${tempUrl}/kabupatens/${kabupatenId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKabupaten(kabupatenId);
      if (kabupatenId == "9999") {
        setKodeProvinsi("");
        setKodeKabupaten(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
      }
    }
  };

  const findKabupatenTT = async (kabupatenId) => {
    kabupatenId = kabupatenId.split(" ", 1)[0];
    if (kabupatenId) {
      const response = await axios.post(
        `${tempUrl}/kabupatens/${kabupatenId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKabupatenTT(kabupatenId);
      if (kabupatenId == "9999") {
        setKodeProvinsiTT("");
        setKodeKabupatenTT(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsiTT(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenTT(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
      }
    }
  };

  const findProvinsi = async (provinsiId) => {
    provinsiId = provinsiId.split(" ", 1)[0];
    if (provinsiId) {
      const response = await axios.post(`${tempUrl}/provinsis/${provinsiId}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeProvinsi(provinsiId);
      if (response.data) {
        setKodeProvinsi(`${response.data.id} - ${response.data.namaProvinsi}`);
      }
    }
  };

  const findProvinsiTT = async (provinsiId) => {
    provinsiId = provinsiId.split(" ", 1)[0];
    if (provinsiId) {
      const response = await axios.post(`${tempUrl}/provinsis/${provinsiId}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeProvinsiTT(provinsiId);
      if (response.data) {
        setKodeProvinsiTT(
          `${response.data.id} - ${response.data.namaProvinsi}`
        );
      }
    }
  };

  const updateCustomer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeKelurahan.length !== 0 &&
      kodeKelurahanTT.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateCustomer/${id}`, {
            nikCustomer,
            noKkCustomer,
            namaCustomer,
            tempatLahirCustomer,
            tanggalLahirCustomer,
            jenisKelaminCustomer,
            agamaCustomer,
            noTeleponCustomer,
            alamatCustomer,
            alamatTTCustomer,
            kelurahanTTId: kodeKelurahanTT,
            statusPerkawinanCustomer,
            pekerjaanCustomer,
            kewarganegaraanCustomer,

            kelurahanId: kodeKelurahan,
            provinsiId: kodeProvinsi.split(" ", 1)[0],
            kabupatenId: kodeKabupaten.split(" ", 1)[0],
            kecamatanId: kodeKecamatan.split(" ", 1)[0],

            ketProvinsi,
            ketKabupaten,
            ketKecamatan,
            ketKelurahan,
            kodePos,

            kelurahanTTId: kodeKelurahanTT.split(" ", 1)[0],
            provinsiTTId: kodeProvinsiTT.split(" ", 1)[0],
            kabupatenTTId: kodeKabupatenTT.split(" ", 1)[0],
            kecamatanTTId: kodeKecamatanTT.split(" ", 1)[0],

            ketProvinsiTT,
            ketKabupatenTT,
            ketKecamatanTT,
            ketKelurahanTT,
            kodePosTT,

            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/customer/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Customer</h5>
      <hr />
      <Card>
        <Card.Header>Customer</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateCustomer}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CIF :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control required value={cif} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikCustomer}
                          onChange={(e) =>
                            setNikCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKkCustomer}
                          onChange={(e) =>
                            setNoKkCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaCustomer}
                          onChange={(e) =>
                            setNamaCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirCustomer}
                          onChange={(e) =>
                            setTempatLahirCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control />}
                          selected={tanggalLahirCustomer}
                          onChange={(date) => setTanggalLahirCustomer(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisKelaminCustomer}
                          onChange={(e) => {
                            setJenisKelaminCustomer(e.target.value);
                          }}
                        >
                          {jenisKelaminOption.map((jenisKelamin) => (
                            <option value={jenisKelamin}>{jenisKelamin}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={agamaCustomer}
                          onChange={(e) => {
                            setAgamaCustomer(e.target.value);
                          }}
                        >
                          {agamaOption.map((agama) => (
                            <option value={agama}>{agama}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponCustomer}
                          onChange={(e) =>
                            setNoTeleponCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={statusPerkawinanCustomer}
                          onChange={(e) => {
                            setStatusPerkawinanCustomer(e.target.value);
                          }}
                        >
                          {statusPerkawinanOption.map((statusPerkawinan) => (
                            <option value={statusPerkawinan}>
                              {statusPerkawinan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanCustomer}
                          onChange={(e) =>
                            setPekerjaanCustomer(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kewarganegaraanCustomer}
                          onChange={(e) => {
                            setKewarganegaraanCustomer(e.target.value);
                          }}
                        >
                          {kewarganegaraanOption.map((kewarganegaraan) => (
                            <option value={kewarganegaraan}>
                              {kewarganegaraan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatCustomer}
                              onChange={(e) =>
                                setAlamatCustomer(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelurahanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKelurahan.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKelurahan(value.split(" ", 1)[0]);
                              }}
                              value={kodeKelurahan}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={kodeKelurahan !== "99999999"}
                              value={ketKelurahan}
                              onChange={(e) =>
                                setKetKelurahan(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kecamatanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKecamatan.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKecamatan(value);
                              }}
                              value={kodeKecamatan}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeKecamatan.split(" ", 1)[0] !== "999999"
                              }
                              value={ketKecamatan}
                              onChange={(e) =>
                                setKetKecamatan(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kabupatenOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKabupaten.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKabupaten(value);
                              }}
                              value={kodeKabupaten}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeKabupaten.split(" ", 1)[0] !== "9999"
                              }
                              value={ketKabupaten}
                              onChange={(e) =>
                                setKetKabupaten(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={provinsiOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeProvinsi.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findProvinsi(value);
                              }}
                              value={kodeProvinsi}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={kodeProvinsi.split(" ", 1)[0] !== "99"}
                              value={ketProvinsi}
                              onChange={(e) =>
                                setKetProvinsi(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              type="number"
                              value={kodePos}
                              disabled={
                                kodeKelurahan.split(" ", 1)[0] !== "99999999"
                              }
                              onChange={(e) =>
                                setKodePos(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>Tempat Tinggal</Box>
                      <Box>
                        <Form.Check
                          type="checkbox"
                          label="Sama dengan alamat identitas"
                          checked={alamatSama}
                          onChange={() => {
                            setAlamatSama(!alamatSama);
                            if (!alamatSama === true) {
                              setAlamatTTCustomer(alamatCustomer);
                              setKodeKelurahanTT(kodeKelurahan);
                              setKetKelurahanTT(ketKelurahan);
                              setKodeKecamatanTT(kodeKecamatan);
                              setKetKecamatanTT(ketKecamatan);
                              setKodeKabupatenTT(kodeKabupaten);
                              setKetKabupatenTT(ketKabupaten);
                              setKodeProvinsiTT(kodeProvinsi);
                              setKetProvinsiTT(ketProvinsi);
                              setKodePosTT(kodePos);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatTTCustomer}
                              onChange={(e) =>
                                setAlamatTTCustomer(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelurahanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKelurahanTT.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKelurahanTT(value);
                              }}
                              value={kodeKelurahanTT}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeKelurahanTT.split(" ", 1)[0] !== "99999999"
                              }
                              value={ketKelurahanTT}
                              onChange={(e) =>
                                setKetKelurahanTT(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kecamatanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKecamatanTT.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKecamatanTT(value);
                              }}
                              value={kodeKecamatanTT}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeKecamatanTT.split(" ", 1)[0] !== "999999"
                              }
                              value={ketKecamatanTT}
                              onChange={(e) =>
                                setKetKecamatanTT(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kabupatenOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKabupatenTT.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKabupatenTT(value);
                              }}
                              value={kodeKabupatenTT}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeKabupatenTT.split(" ", 1)[0] !== "9999"
                              }
                              value={ketKabupatenTT}
                              onChange={(e) =>
                                setKetKabupatenTT(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={provinsiOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeProvinsiTT.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findProvinsiTT(value);
                              }}
                              value={kodeProvinsiTT}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              disabled={
                                kodeProvinsiTT.split(" ", 1)[0] !== "99"
                              }
                              value={ketProvinsiTT}
                              onChange={(e) =>
                                setKetProvinsiTT(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              type="number"
                              value={kodePosTT}
                              disabled={
                                kodeKelurahanTT.split(" ", 1)[0] !== "99999999"
                              }
                              onChange={(e) =>
                                setKodePosTT(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/customer")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahCustomer;

const alertBox = {
  width: "100%",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
