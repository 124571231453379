import React, { useState, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, tempUrlFico } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Container, Form, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LabaRugi = () => {
  const { user, dispatch, setting } = useContext(AuthContext);
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [totalLabaRugi, setTotalLabaRugi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [open, setOpen] = useState(false);
  const [labaRugisData, setLabaRugisData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const postingLabaRugiPeriodeBerjalan = async (jumlah) => {
    let countNeracaSaldo = 0;
    try {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);
      // Jurnal Posting Laba Rugi Periode Berjalan
      await axios.post(
        `${tempUrlFico}/saveJurnalPostingLabaRugiPeriodeBerjalan`,
        {
          dariTanggal: user.tutupperiode.dariTanggal,
          sampaiTanggal: user.tutupperiode.sampaiTanggal,
          labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
          accountPembalance: setting.accountPembalance,
          jumlah,
          namaPeriode: user.tutupperiode.namaPeriode,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      tempPersenLoading += 30;
      setPersenLoading(tempPersenLoading.toString());

      let tempDariTanggal = new Date(user.tutupperiode.dariTanggal);

      // Update Periode Lama
      let tempDariTgl =
        tempDariTanggal.getFullYear() +
        "-" +
        (tempDariTanggal.getMonth() + 1) +
        "-" +
        "1";
      let dariTanggal = tempDariTgl;

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTanggal =
        tempDariTanggal.getFullYear() +
        "-" +
        (tempDariTanggal.getMonth() + 1) +
        "-" +
        lastday(tempDariTanggal.getFullYear(), tempDariTanggal.getMonth());

      const allNeracaSaldo = await axios.post(
        `${tempUrlFico}/neracaSaldoAboveTanggal`,
        {
          dariTanggal,
          sampaiTanggal,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      const deletedNeracaSaldoAboveTanggal = await axios.post(
        `${tempUrlFico}/deleteNeracaSaldoAboveTanggal`,
        {
          dariTanggal,
          sampaiTanggal,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      tempPersenLoading += 30;
      setPersenLoading(tempPersenLoading.toString());

      const groupedNeracaSaldo = allNeracaSaldo.data.reduce(
        (group, neracaSaldo) => {
          const { tglNeracaSaldo } = neracaSaldo;
          group[tglNeracaSaldo] = group[tglNeracaSaldo] ?? [];
          group[tglNeracaSaldo].push(neracaSaldo);
          return group;
        },
        {}
      );

      // for (let i = 0; i < allNeracaSaldo.data.length; i++) {
      //   // Delete tglBigger Neraca Saldo
      //   await axios.post(
      //     `${tempUrlFico}/deleteNeracaSaldo/${allNeracaSaldo.data[i].id}`,
      //     {
      //       _id: user.id,
      //       token: user.token,
      //       kodeCabang: user.cabang.id,
      //     }
      //   );
      //   countNeracaSaldo++;
      // }
      tempPersenLoading += 30;
      setPersenLoading(tempPersenLoading.toString());

      // for (let i = 0; i < countNeracaSaldo; i++) {
      for (let i = 0; i < Object.keys(groupedNeracaSaldo).length; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrlFico}/saveLastNeracaSaldo`, {
          dariTanggal,
          sampaiTanggal,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        // Update Neraca Saldo Jurnal Posting
        await axios.post(
          `${tempUrlFico}/saveJurnalPostingNeracaSaldoRebuild2`,
          {
            dariTanggal,
            sampaiTanggal,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );

        let tempDariTgl = new Date(dariTanggal);
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1) +
          "-" +
          "1";
        dariTanggal = tempDariTgl;

        sampaiTanggal =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());
      }
      setPersenLoading("0");
      setLoading(false);
      setOpen(true);
    } catch (e) {
      alert(e);
    }
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanLabaRugi",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);

      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 40;
      setPersenLoading(tempPersenLoading.toString());

      let neracas = await axios.post(`${tempUrlFico}/labaRugisRebuild`, {
        kodeGroupPendapatan: setting.kodeGroupPendapatan,
        kodeGroupBiayaAtasPendapatan: setting.kodeGroupBiayaAtasPendapatan,
        kodeGroupPengeluaranOperasional:
          setting.kodeGroupPengeluaranOperasional,
        kodeGroupPendapatanLainLain: setting.kodeGroupPendapatanLainLain,
        kodeGroupBiayaLainLain: setting.kodeGroupBiayaLainLain,
        dariTanggal: user.tutupperiode.dariTanggal,
        sampaiTanggal: user.tutupperiode.sampaiTanggal,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setLabaRugisData(neracas.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setPersenLoading("0");

      if (neracas.data) {
        let tempSubTotal = 0;
        let tempTotal = 0;
        let totalPendapatan = 0;
        let totalBiaya = 0;
        let labaRugiOperasional = 0;

        let keys = Object.keys(neracas.data);

        for (let j = 0; j < Object.keys(neracas.data).length; j++) {
          let groupByKodeSubGroupCOA = neracas.data[keys[j]].reduce(
            (group, labaRugi) => {
              const { namaSubGroupCOA } = labaRugi;
              group[namaSubGroupCOA] = group[namaSubGroupCOA] ?? [];
              group[namaSubGroupCOA].push(labaRugi);
              return group;
            },
            {}
          );
          let keysSubGroup = Object.keys(groupByKodeSubGroupCOA);

          for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
            for (
              let k = 0;
              k < groupByKodeSubGroupCOA[keysSubGroup[j]].length;
              k++
            ) {
              tempSubTotal += groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah;
              tempTotal += groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah;
            }
            tempSubTotal = 0;
          }

          if (
            neracas.data[keys[j]][0].groupCOA === setting.kodeGroupPendapatan
          ) {
            totalPendapatan += tempTotal;
          } else if (
            neracas.data[keys[j]][0].groupCOA ===
            setting.kodeGroupBiayaAtasPendapatan
          ) {
            totalBiaya += tempTotal;
          } else if (
            neracas.data[keys[j]][0].groupCOA ===
            setting.kodeGroupPengeluaranOperasional
          ) {
            totalBiaya += tempTotal;
          } else if (
            neracas.data[keys[j]][0].groupCOA ===
            setting.kodeGroupPendapatanLainLain
          ) {
            totalPendapatan += tempTotal;
          } else if (
            neracas.data[keys[j]][0].groupCOA === setting.kodeGroupBiayaLainLain
          ) {
            totalBiaya += tempTotal;
          }

          tempTotal = 0;
        }
        labaRugiOperasional = totalPendapatan - totalBiaya;
        console.log(labaRugiOperasional);

        if (
          new Date(user.tutupperiode.dariTanggal) >
          new Date(setting.programDariTanggal)
        ) {
          postingLabaRugiPeriodeBerjalan(labaRugiOperasional);
        }
      }
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableTextLast = {
    letterSpacing: "0.01px",
    borderTop: "1px solid black",
  };

  const list = [];
  let tempSubTotal = 0;
  let tempTotal = 0;
  let totalPendapatan = 0;
  let totalBiaya = 0;
  let labaRugiOperasional = 0;

  let keys = Object.keys(labaRugisData);

  for (let j = 0; j < Object.keys(labaRugisData).length; j++) {
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="3">
          {labaRugisData[keys[j]][0].namaGroupCOA} :
        </td>
      </tr>
    );
    let groupByKodeSubGroupCOA = labaRugisData[keys[j]].reduce(
      (group, labaRugi) => {
        const { namaSubGroupCOA } = labaRugi;
        group[namaSubGroupCOA] = group[namaSubGroupCOA] ?? [];
        group[namaSubGroupCOA].push(labaRugi);
        return group;
      },
      {}
    );
    let keysSubGroup = Object.keys(groupByKodeSubGroupCOA);

    for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
      list.push(
        <>
          <tr style={tableText}>
            <td
              style={{
                fontWeight: 700,
                paddingLeft: "10px",
                border: "1px solid black",
              }}
              colspan="3"
            >
              {groupByKodeSubGroupCOA[keysSubGroup[j]][0].namaSubGroupCOA} :
            </td>
          </tr>
        </>
      );
      for (let k = 0; k < groupByKodeSubGroupCOA[keysSubGroup[j]].length; k++) {
        tempSubTotal += groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah;
        tempTotal += groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah;
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {groupByKodeSubGroupCOA[keysSubGroup[j]][k].namaCOA}
            </td>
            <td
              style={{
                textAlign: "right",
                width: "25%",
                border: "1px solid black",
              }}
            >
              {groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah > 0
                ? groupByKodeSubGroupCOA[keysSubGroup[j]][
                    k
                  ].jumlah.toLocaleString("en-EN")
                : `(${groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah
                    .toLocaleString("en-EN")
                    .slice(1)})`}
            </td>
            <td style={{ width: "25%", border: "1px solid black" }}></td>
          </tr>
        );
      }
      list.push(
        <tr style={tableText}>
          <td
            style={{
              fontWeight: 700,
              paddingLeft: "10px",
              border: "1px solid black",
            }}
          >
            SUBTOTAL{" "}
            {groupByKodeSubGroupCOA[keysSubGroup[j]][0].namaSubGroupCOA} :
          </td>
          <td
            style={{
              width: "25%",
              textAlign: "right",
              borderTop: "1px solid black",
              fontWeight: 700,
              border: "1px solid black",
            }}
          >
            {tempSubTotal > 0
              ? tempSubTotal.toLocaleString("en-EN")
              : `(${tempSubTotal.toLocaleString("en-EN").slice(1)})`}
          </td>
          <td style={{ width: "25%", border: "1px solid black" }}></td>
        </tr>
      );
      tempSubTotal = 0;
    }
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          TOTAL {labaRugisData[keys[j]][0].namaGroupCOA} :
        </td>
        <td style={{ width: "25%", border: "1px solid black" }}></td>
        <td
          style={{
            width: "25%",
            textAlign: "right",
            fontWeight: 700,
            border: "1px solid black",
          }}
        >
          {tempTotal > 0
            ? tempTotal.toLocaleString("en-EN")
            : `(${tempTotal.toLocaleString("en-EN").slice(1)})`}
        </td>
      </tr>
    );

    if (labaRugisData[keys[j]][0].groupCOA === setting.kodeGroupPendapatan) {
      totalPendapatan += tempTotal;
    } else if (
      labaRugisData[keys[j]][0].groupCOA ===
      setting.kodeGroupBiayaAtasPendapatan
    ) {
      totalBiaya += tempTotal;
    } else if (
      labaRugisData[keys[j]][0].groupCOA ===
      setting.kodeGroupPengeluaranOperasional
    ) {
      totalBiaya += tempTotal;
    } else if (
      labaRugisData[keys[j]][0].groupCOA === setting.kodeGroupPendapatanLainLain
    ) {
      totalPendapatan += tempTotal;
    } else if (
      labaRugisData[keys[j]][0].groupCOA === setting.kodeGroupBiayaLainLain
    ) {
      totalBiaya += tempTotal;
    }

    tempTotal = 0;
  }

  list.push(
    <tr>
      <td style={{ fontWeight: 700, border: "1px solid black" }}>
        TOTAL PENDAPATAN :
      </td>
      <td style={{ width: "25%", border: "1px solid black" }}></td>
      <td
        style={{
          width: "25%",
          textAlign: "right",
          borderBottom: "1px solid black",
          fontWeight: 700,
          border: "1px solid black",
        }}
      >
        {totalPendapatan > 0
          ? totalPendapatan.toLocaleString("en-EN")
          : `(${totalPendapatan.toLocaleString("en-EN").slice(1)})`}
      </td>
    </tr>
  );
  list.push(
    <tr>
      <td style={{ fontWeight: 700, border: "1px solid black" }}>
        TOTAL BIAYA :
      </td>
      <td style={{ width: "25%", border: "1px solid black" }}></td>
      <td
        style={{
          width: "25%",
          textAlign: "right",
          borderBottom: "1px solid black",
          fontWeight: 700,
          border: "1px solid black",
        }}
      >
        {totalBiaya > 0
          ? totalBiaya.toLocaleString("en-EN")
          : `(${totalBiaya.toLocaleString("en-EN").slice(1)})`}
      </td>
    </tr>
  );
  labaRugiOperasional = totalPendapatan - totalBiaya;
  list.push(
    <tr>
      <td style={{ fontWeight: 700, border: "1px solid black" }}>
        LABA RUGI OPERASIONAL :
      </td>
      <td style={{ width: "25%", border: "1px solid black" }}></td>
      <td
        style={{
          width: "25%",
          textAlign: "right",
          borderBottom: "1px solid black",
          fontWeight: 700,
        }}
      >
        {labaRugiOperasional > 0
          ? labaRugiOperasional.toLocaleString("en-EN")
          : `(${labaRugiOperasional.toLocaleString("en-EN").slice(1)})`}
      </td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanLabaRugi",
    sheet: "LaporanLabaRugi",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Laba Rugi</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Button variant="contained" startIcon={<SearchIcon />} type="submit">
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Laba Rugi
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()} `}
            S/D :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <table class="styled-table" ref={tableRef}>
            <tbody>
              <tr>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
              </tr>
              {list}
            </tbody>
          </table>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal Laba Rugi Periode Berjalan berhasil diposting!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LabaRugi;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
