import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { NumericFormat } from "react-number-format";

const UbahJualAgunan = () => {
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const [validated, setValidated] = useState(false);

  const [noJual, setNoJual] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [noSbg, setNoSbg] = useState("");
  const [namaPembeli, setNamaPembeli] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [ketJam, setKetJam] = useState("");
  const [hargaTafsirJam, setHargaTafsirJam] = useState("");
  const [nilaiJual, setNilaiJual] = useState("");
  const [pokok, setPokok] = useState("");
  const [sisa, setSisa] = useState("");
  const [bunga, setBunga] = useState("");
  const [denda, setDenda] = useState("");
  const [pendapatanPenjualan, setPendapatanPenjualan] = useState("");
  const [titipanKonsumen, setTitipanKonsumen] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  const [coas, setCoas] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    id && getJualAgunanById();
    getCoasData();
  }, [id]);

  const getJualAgunanById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualAgunans/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoJual(response.data.noJual);

      let newTglJual = new Date(response.data.tglJual);
      let tempTglJual = `${newTglJual.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${(newTglJual.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${newTglJual.getFullYear()}`;
      setTglJual(tempTglJual);
      setNoSbg(response.data.noSbg);
      setNamaPembeli(response.data.namaPembeli);
      setKeterangan(response.data.keterangan);
      setKetJam(response.data.jaminan.ketJam);
      setHargaTafsirJam(response.data.jaminan.hargaTafsirJam);
      setNilaiJual(response.data.nilaiJual);
      setPokok(response.data.pokok);
      setBunga(response.data.bunga);
      setDenda(response.data.denda);
      setSisa(
        response.data.nilaiJual -
          response.data.pokok -
          response.data.bunga -
          response.data.denda
      );
      setPendapatanPenjualan(response.data.pendapatanPenjualan);
      setTitipanKonsumen(response.data.titipanKonsumen);
      setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);
    }
  };

  const getCoasData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const updateJualAgunan = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeCOA.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateJualAgunan/${id}`, {
            nilaiJual: nilaiJual,
            pokok,
            bunga,
            denda,
            pendapatanPenjualan,
            titipanKonsumen,
            kodeCOA: kodeCOA.split(" -", 1)[0],
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/jualAgunan/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Jual Agunan</h5>
      {id && (
        <Container>
          <hr />
          <Form noValidate validated={validated} onSubmit={updateJualAgunan}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Jual :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={noJual} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Sbg :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={noSbg} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Agunan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={ketJam}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Nama Pembeli :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={namaPembeli} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tgl. Jual :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={tglJual} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Harga Agunan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaTafsirJam.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nilai Jual <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <NumericFormat
                      required
                      value={nilaiJual}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setNilaiJual(
                          values.formattedValue
                            .split(".")
                            .join("")
                            .replace(/,/g, "")
                        );

                        let tempNum = values.formattedValue
                          .split(".")
                          .join("")
                          .replace(/,/g, "");

                        let tempSisa = tempNum - pokok - bunga - denda;
                        setSisa(tempSisa);
                        let tempPersenUntungJual =
                          ((pokok + bunga + denda) * setting.persenUntungJual) /
                          100;
                        if (tempSisa > tempPersenUntungJual) {
                          tempPersenUntungJual =
                            (tempNum * setting.persenUntungJual) / 100;

                          let tempTitipanKonsumen =
                            tempSisa - tempPersenUntungJual;

                          setPendapatanPenjualan(tempPersenUntungJual);
                          setTitipanKonsumen(tempTitipanKonsumen);
                        } else {
                          setPendapatanPenjualan(tempSisa);
                          setTitipanKonsumen(0);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Pokok :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={pokok.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Bunga :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={bunga.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Denda :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={denda.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sisa :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={sisa.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Pend. Penjualan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={pendapatanPenjualan.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Titipan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={titipanKonsumen.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Kas <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={coaOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCOA.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCOA(value.label);
                        } else {
                          setKodeCOA("");
                        }
                      }}
                      value={kodeCOA}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/jualAgunan")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Container>
      )}
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahJualAgunan;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
