import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles.scss";
import { Sidebar, Footer } from "./components";
import { AuthContext } from "./contexts/AuthContext";
import { useStateContext } from "./contexts/ContextProvider";
import {
  Login,
  ProfilUser,
  UbahProfilUser,
  DaftarUser,
  TambahUser,
  UbahUser,
  TutupPeriode,
  TampilSetting,
  UbahSetting,
  TampilGantiPeriode,
  TampilKategoriJaminan,
  TambahKategoriJaminan,
  UbahKategoriJaminan,
  TampilJenisJaminan,
  TambahJenisJaminan,
  UbahJenisJaminan,
  TampilMarketing,
  TambahMarketing,
  UbahMarketing,
  TampilJenisCOA,
  TambahJenisCOA,
  UbahJenisCOA,
  TampilGroupCOA,
  TambahGroupCOA,
  UbahGroupCOA,
  TampilSubGroupCOA,
  TambahSubGroupCOA,
  UbahSubGroupCOA,
  TampilCOA,
  TambahCOA,
  UbahCOA,
  TampilFormLabaRugiOJK,
  UbahFormLabaRugiOJK,
  TambahFormLabaRugiOJK,
  TampilFormNeracaOJK,
  UbahFormNeracaOJK,
  TambahFormNeracaOJK,
  TampilFormArusKasOJK,
  TambahFormArusKasOJK,
  UbahFormArusKasOJK,
  TampilCabang,
  TambahCabang,
  UbahCabang,
  TampilProvinsi,
  TambahProvinsi,
  UbahProvinsi,
  TampilKabupaten,
  TambahKabupaten,
  UbahKabupaten,
  TampilKecamatan,
  TambahKecamatan,
  UbahKecamatan,
  TampilKelurahan,
  TambahKelurahan,
  UbahKelurahan,
  TampilCustomer,
  TambahCustomer,
  UbahCustomer,
  TampilSurveyor,
  UbahSurveyor,
  TambahSurveyor,
  TampilEksekutor,
  UbahEksekutor,
  TambahEksekutor,
  TampilTipe,
  UbahTipe,
  TambahTipe,
  TampilWarna,
  UbahWarna,
  TambahWarna,
  TampilDaftarPengajuan,
  TambahPengajuan,
  TampilPengajuan,
  UbahPengajuan,
  TampilDaftarInfoNasabahGadai,
  TampilInfoNasabahGadai,
  TambahJaminan,
  TampilJaminan,
  UbahJaminan,
  TampilDaftarApproval,
  TambahApproval,
  TampilApproval,
  UbahApproval,
  TampilDaftarBuktiPencairan,
  TampilBuktiPencairan,
  TampilDaftarTopup,
  TambahTopup,
  TampilTopup,
  TampilOneTopup,
  UbahTopup,
  TampilDaftarTebus,
  TambahTebus,
  TampilTebus,
  TampilDaftarPerpanjangan,
  TambahPerpanjangan,
  TampilPerpanjangan,
  UbahPerpanjangan,
  TampilDaftarCicilan,
  TambahCicilan,
  TampilCicilan,
  UbahCicilan,
  TampilJualAgunan,
  UbahJualAgunan,
  TambahJualAgunan,
  LaporanTunggakan,
  LaporanTunggakanRinci,
  LaporanOutstanding,
  LaporanStockSiapDijual,
  LaporanPenerimaan,
  LaporanJualBarang,
  LaporanOutstandingGadai,
  LaporanPiutangLunasGadai,
  TampilDaftarPengajuanFidusia,
  TambahPengajuanFidusia,
  TampilPengajuanFidusia,
  UbahPengajuanFidusia,
  TampilDaftarApprovalFidusia,
  TambahApprovalFidusia,
  TampilApprovalFidusia,
  UbahApprovalFidusia,
  TambahRejectFidusia,
  TampilRejectFidusia,
  UbahRejectFidusia,
  TampilDaftarSp1Fidusia,
  TambahSp1Fidusia,
  TampilSp1Fidusia,
  TampilDaftarSp2Fidusia,
  TambahSp2Fidusia,
  TampilSp2Fidusia,
  TampilDaftarSp3Fidusia,
  TambahSp3Fidusia,
  TampilSp3Fidusia,
  TampilDaftarSuratPanggilanFidusia,
  TambahSuratPanggilanFidusia,
  TampilSuratPanggilanFidusia,
  TampilDaftarBuktiPencairanFidusia,
  TampilBuktiPencairanFidusia,
  TampilDaftarAngsuranFidusia,
  TampilAngsuranFidusia,
  TampilAngsuranFidusiaChild,
  UbahAngsuranFidusiaChild,
  TambahAngsuranFidusia,
  TampilAngsuranFidusiaSP,
  TampilDaftarInfoNasabahFidusia,
  TampilInfoNasabahFidusia,
  TampilInfoNasabahFidusiaSP,
  TampilDaftarPenarikanFidusia,
  TampilPenarikanFidusia,
  TambahPenarikanFidusia,
  TambahPercepatanFidusia,
  LaporanTunggakanFidusia,
  LaporanTunggakanFidusiaRinci,
  LaporanTotalPiutang,
  LaporanPenarikanFidusia,
  LaporanPinjamanJatuhTempo,
  LaporanPenerimaanFidusia,
  LaporanOutstandingFidusia,
  LaporanPiutangLunasFidusia,
  TampilDaftarKasMasuk,
  TambahKasMasuk,
  TampilKasMasuk,
  UbahKasMasuk,
  TambahKasMasukChild,
  TampilKasMasukChild,
  UbahKasMasukChild,
  TampilDaftarKasKeluar,
  TambahKasKeluar,
  TampilKasKeluar,
  UbahKasKeluar,
  TambahKasKeluarChild,
  TampilKasKeluarChild,
  UbahKasKeluarChild,
  TampilDaftarBankMasuk,
  TambahBankMasuk,
  TampilBankMasuk,
  UbahBankMasuk,
  TambahBankMasukChild,
  TampilBankMasukChild,
  UbahBankMasukChild,
  TampilDaftarBankKeluar,
  TambahBankKeluar,
  TampilBankKeluar,
  UbahBankKeluar,
  TambahBankKeluarChild,
  TampilBankKeluarChild,
  UbahBankKeluarChild,
  Posting,
  Unposting,
  AktivitasBukuBesar,
  NeracaSaldo,
  LabaRugiRekap,
  LabaRugi,
  Neraca,
  ArusKasRekap,
  Jurnal,
  LabaRugiOjk,
  NeracaOjk,
  ArusKasOjk,
  TampilDaftarJurnalUmum,
  TambahJurnalUmum,
  TampilJurnalUmum,
  UbahJurnalUmum,
  TambahJurnalUmumChild,
  TampilJurnalUmumChild,
  UbahJurnalUmumChild,
  LaporanJurnalUmum,
} from "./pages/index";
import { FaBars } from "react-icons/fa";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();
  // const { collapseSidebar } = useProSidebar();
  const { user } = useContext(AuthContext);
  // const [open, setOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const USERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user) {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const JAMINANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.jaminan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MARKETINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.marketing) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BUKUBESARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.bukuBesar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const AREARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.area) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CUSTOMERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.customer) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  // const FIDUSIARoute = ({ children }) => {
  //   const { user } = useContext(AuthContext);

  //   if (user.akses.fidusia) {
  //     return children;
  //   }

  //   return <Navigate to="/unauthorized" />;
  // };

  const SURVEYORRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.surveyor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const EKSEKUTORRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.eksekutor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TIPERoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tipe) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const WARNARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.warna) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CABANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.cabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PROFILUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.profilUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETTINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.setting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.daftarUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TUTUPPERIODERoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tutupPeriode) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const GANTIPERIODERoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.gantiPeriode) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENGAJUANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pengajuan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const INFONASABAHGADAIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.infoNasabahGadai) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const APPROVALRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.approval) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BUKTIPENCAIRANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.buktiPencairan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TOPUPRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.topup) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TEBUSRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tebus) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PERPANJANGANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.perpanjangan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CICILANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.cicilan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const JUALAGUNANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.jualAgunan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPTUNGGAKANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapTunggakan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPTUNGGAKANRINCIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapTunggakanRinci) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPOUTSTANDINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapOutstanding) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPSTOCKSIAPDIJUALRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapStockSiapDijual) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPENERIMAANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPenerimaan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPJUALBARANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPenerimaan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPOUTSTANDINGGADAIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapOutstandingGadai) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPIUTANGLUNASGADAIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPiutangLunasGadai) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENGAJUANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pengajuanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const APPROVALFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.approvalFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BUKTIPENCAIRANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.buktiPencairanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ANGSURANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.angsuranFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const INFONASABAHFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.infoNasabahFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENARIKANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.penarikanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PERCEPATANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.percepatanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SP1Route = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.sp1) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SP2Route = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.sp2) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SP3Route = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.sp3) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SPANGGILANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.sPanggilan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPTUNGGAKANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapTunggakanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPTUNGGAKANFIDUSIARINCIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapTunggakanFidusiaRinci) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPTOTALPIUTANGFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapTotalPiutangFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPENARIKANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPenarikanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPINJAMANJATUHTEMPOFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPinjamanJatuhTempoFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPENERIMAANFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPenerimaanFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPOUTSTANDINGFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapOutstandingFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPIUTANGLUNASFIDUSIARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.lapPiutangLunasFidusia) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KASMASUKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kasMasuk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KASKELUARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kasKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BANKMASUKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.bankMasuk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BANKKELUARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.bankKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const POSTINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.posting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const AKTIVITASBUKUBESARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.aktivitasBukuBesar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NERACASALDORoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.neracaSaldo) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const JURNALRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.jurnal) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const JURNALUMUMRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.jurnalUmum) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LABARUGIREKAPRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.labaRugiRekap) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LABARUGIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.labaRugi) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NERACARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.neraca) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ARUSKASREKAPRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.arusKasRekap) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LABARUGIOJKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.labaRugiOjk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NERACAOJKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.neracaOjk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ARUSKASOJKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.arusKasOjk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {user && (
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      )}

      <main>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* Profil User */}
          <Route
            path="/profilUser"
            element={
              <PROFILUSERRoute>
                <ProfilUser />
              </PROFILUSERRoute>
            }
          />
          <Route
            path="/profilUser/:id/edit"
            element={
              <PROFILUSERRoute>
                <UbahProfilUser />
              </PROFILUSERRoute>
            }
          />
          {/* Daftar User */}
          <Route
            path="/daftarUser"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id/edit"
            element={
              <DAFTARUSERRoute>
                <UbahUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/tambahUser"
            element={
              <DAFTARUSERRoute>
                <TambahUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/tutupPeriode"
            element={
              <TUTUPPERIODERoute>
                <TutupPeriode />
              </TUTUPPERIODERoute>
            }
          />
          {/* Setting */}
          <Route
            path="/setting"
            element={
              <SETTINGRoute>
                <TampilSetting />
              </SETTINGRoute>
            }
          />
          <Route
            path="/setting/:id/edit"
            element={
              <SETTINGRoute>
                <UbahSetting />
              </SETTINGRoute>
            }
          />
          {/* Ganti Periode */}
          <Route
            path="/gantiPeriode"
            element={
              <GANTIPERIODERoute>
                <TampilGantiPeriode />
              </GANTIPERIODERoute>
            }
          />
          <Route
            path="/gantiPeriode/:id"
            element={
              <GANTIPERIODERoute>
                <TampilGantiPeriode />
              </GANTIPERIODERoute>
            }
          />
          {/* Kategori Jaminan */}
          <Route
            path="/kategoriJaminan"
            element={
              <JAMINANRoute>
                <TampilKategoriJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/kategoriJaminan/:id"
            element={
              <JAMINANRoute>
                <TampilKategoriJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/kategoriJaminan/:id/edit"
            element={
              <JAMINANRoute>
                <UbahKategoriJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/kategoriJaminan/tambahKategoriJaminan"
            element={
              <JAMINANRoute>
                <TambahKategoriJaminan />
              </JAMINANRoute>
            }
          />
          {/* Jenis Jaminan */}
          <Route
            path="/jenisJaminan"
            element={
              <JAMINANRoute>
                <TampilJenisJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/jenisJaminan/:id"
            element={
              <JAMINANRoute>
                <TampilJenisJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/jenisJaminan/tambahJenisJaminan"
            element={
              <JAMINANRoute>
                <TambahJenisJaminan />
              </JAMINANRoute>
            }
          />
          <Route
            path="/jenisJaminan/:id/edit"
            element={
              <JAMINANRoute>
                <UbahJenisJaminan />
              </JAMINANRoute>
            }
          />
          {/* Marketing */}
          <Route
            path="/marketing"
            element={
              <MARKETINGRoute>
                <TampilMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/:id"
            element={
              <MARKETINGRoute>
                <TampilMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/tambahMarketing"
            element={
              <MARKETINGRoute>
                <TambahMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/:id/edit"
            element={
              <MARKETINGRoute>
                <UbahMarketing />
              </MARKETINGRoute>
            }
          />
          {/* Jenis COA */}
          <Route
            path="/jenisCoa"
            element={
              <BUKUBESARRoute>
                <TampilJenisCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/jenisCoa/:id"
            element={
              <BUKUBESARRoute>
                <TampilJenisCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/jenisCoa/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahJenisCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/jenisCoa/tambahJenisCoa"
            element={
              <BUKUBESARRoute>
                <TambahJenisCOA />
              </BUKUBESARRoute>
            }
          />
          {/* Group COA */}
          <Route
            path="/groupCoa"
            element={
              <BUKUBESARRoute>
                <TampilGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/groupCoa/:id"
            element={
              <BUKUBESARRoute>
                <TampilGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/groupCoa/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/groupCoa/tambahGroupCoa"
            element={
              <BUKUBESARRoute>
                <TambahGroupCOA />
              </BUKUBESARRoute>
            }
          />
          {/* Sub Group COA */}
          <Route
            path="/subGroupCoa"
            element={
              <BUKUBESARRoute>
                <TampilSubGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/subGroupCoa/:id"
            element={
              <BUKUBESARRoute>
                <TampilSubGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/subGroupCoa/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahSubGroupCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/subGroupCoa/tambahSubGroupCoa"
            element={
              <BUKUBESARRoute>
                <TambahSubGroupCOA />
              </BUKUBESARRoute>
            }
          />
          {/*  COA */}
          <Route
            path="/coa"
            element={
              <BUKUBESARRoute>
                <TampilCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/coa/:id"
            element={
              <BUKUBESARRoute>
                <TampilCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/coa/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahCOA />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/coa/tambahCoa"
            element={
              <BUKUBESARRoute>
                <TambahCOA />
              </BUKUBESARRoute>
            }
          />
          {/*  Laba Rugi COA OJK */}
          <Route
            path="/formLabaRugiOJK"
            element={
              <BUKUBESARRoute>
                <TampilFormLabaRugiOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formLabaRugiOJK/:id"
            element={
              <BUKUBESARRoute>
                <TampilFormLabaRugiOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formLabaRugiOJK/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahFormLabaRugiOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formLabaRugiOJK/tambahFormLabaRugiOJK"
            element={
              <BUKUBESARRoute>
                <TambahFormLabaRugiOJK />
              </BUKUBESARRoute>
            }
          />
          {/*  Neraca COA OJK */}
          <Route
            path="/formNeracaOJK"
            element={
              <BUKUBESARRoute>
                <TampilFormNeracaOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formNeracaOJK/:id"
            element={
              <BUKUBESARRoute>
                <TampilFormNeracaOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formNeracaOJK/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahFormNeracaOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formNeracaOJK/tambahFormNeracaOJK"
            element={
              <BUKUBESARRoute>
                <TambahFormNeracaOJK />
              </BUKUBESARRoute>
            }
          />
          {/*  ArusKas COA OJK */}
          <Route
            path="/formArusKasOJK"
            element={
              <BUKUBESARRoute>
                <TampilFormArusKasOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formArusKasOJK/:id"
            element={
              <BUKUBESARRoute>
                <TampilFormArusKasOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formArusKasOJK/:id/edit"
            element={
              <BUKUBESARRoute>
                <UbahFormArusKasOJK />
              </BUKUBESARRoute>
            }
          />
          <Route
            path="/formArusKasOJK/tambahFormArusKasOJK"
            element={
              <BUKUBESARRoute>
                <TambahFormArusKasOJK />
              </BUKUBESARRoute>
            }
          />
          {/*  Cabang */}
          <Route
            path="/cabang"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id/edit"
            element={
              <CABANGRoute>
                <UbahCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/tambahCabang"
            element={
              <CABANGRoute>
                <TambahCabang />
              </CABANGRoute>
            }
          />
          {/*  Provinsi */}
          <Route
            path="/provinsi"
            element={
              <AREARoute>
                <TampilProvinsi />
              </AREARoute>
            }
          />
          <Route
            path="/provinsi/:id"
            element={
              <AREARoute>
                <TampilProvinsi />
              </AREARoute>
            }
          />
          <Route
            path="/provinsi/:id/edit"
            element={
              <AREARoute>
                <UbahProvinsi />
              </AREARoute>
            }
          />
          <Route
            path="/provinsi/tambahProvinsi"
            element={
              <AREARoute>
                <TambahProvinsi />
              </AREARoute>
            }
          />
          {/*  Kabupaten */}
          <Route
            path="/kabupaten"
            element={
              <AREARoute>
                <TampilKabupaten />
              </AREARoute>
            }
          />
          <Route
            path="/kabupaten/:id"
            element={
              <AREARoute>
                <TampilKabupaten />
              </AREARoute>
            }
          />
          <Route
            path="/kabupaten/:id/edit"
            element={
              <AREARoute>
                <UbahKabupaten />
              </AREARoute>
            }
          />
          <Route
            path="/kabupaten/tambahKabupaten"
            element={
              <AREARoute>
                <TambahKabupaten />
              </AREARoute>
            }
          />
          {/*  Kecamatan */}
          <Route
            path="/kecamatan"
            element={
              <AREARoute>
                <TampilKecamatan />
              </AREARoute>
            }
          />
          <Route
            path="/kecamatan/:id"
            element={
              <AREARoute>
                <TampilKecamatan />
              </AREARoute>
            }
          />
          <Route
            path="/kecamatan/:id/edit"
            element={
              <AREARoute>
                <UbahKecamatan />
              </AREARoute>
            }
          />
          <Route
            path="/kecamatan/tambahKecamatan"
            element={
              <AREARoute>
                <TambahKecamatan />
              </AREARoute>
            }
          />
          {/*  Kelurahan */}
          <Route
            path="/kelurahan"
            element={
              <AREARoute>
                <TampilKelurahan />
              </AREARoute>
            }
          />
          <Route
            path="/kelurahan/:id"
            element={
              <AREARoute>
                <TampilKelurahan />
              </AREARoute>
            }
          />
          <Route
            path="/kelurahan/:id/edit"
            element={
              <AREARoute>
                <UbahKelurahan />
              </AREARoute>
            }
          />
          <Route
            path="/kelurahan/tambahKelurahan"
            element={
              <AREARoute>
                <TambahKelurahan />
              </AREARoute>
            }
          />
          {/*  Customer */}
          <Route
            path="/customer"
            element={
              <CUSTOMERRoute>
                <TampilCustomer />
              </CUSTOMERRoute>
            }
          />
          <Route
            path="/customer/:id"
            element={
              <CUSTOMERRoute>
                <TampilCustomer />
              </CUSTOMERRoute>
            }
          />
          <Route
            path="/customer/:id/edit"
            element={
              <CUSTOMERRoute>
                <UbahCustomer />
              </CUSTOMERRoute>
            }
          />
          <Route
            path="/customer/tambahCustomer"
            element={
              <CUSTOMERRoute>
                <TambahCustomer />
              </CUSTOMERRoute>
            }
          />
          {/*  Surveyor */}
          <Route
            path="/surveyor"
            element={
              <SURVEYORRoute>
                <TampilSurveyor />
              </SURVEYORRoute>
            }
          />
          <Route
            path="/surveyor/:id"
            element={
              <SURVEYORRoute>
                <TampilSurveyor />
              </SURVEYORRoute>
            }
          />
          <Route
            path="/surveyor/:id/edit"
            element={
              <SURVEYORRoute>
                <UbahSurveyor />
              </SURVEYORRoute>
            }
          />
          <Route
            path="/surveyor/tambahSurveyor"
            element={
              <SURVEYORRoute>
                <TambahSurveyor />
              </SURVEYORRoute>
            }
          />
          {/*  Eksekutor */}
          <Route
            path="/eksekutor"
            element={
              <EKSEKUTORRoute>
                <TampilEksekutor />
              </EKSEKUTORRoute>
            }
          />
          <Route
            path="/eksekutor/:id"
            element={
              <EKSEKUTORRoute>
                <TampilEksekutor />
              </EKSEKUTORRoute>
            }
          />
          <Route
            path="/eksekutor/:id/edit"
            element={
              <EKSEKUTORRoute>
                <UbahEksekutor />
              </EKSEKUTORRoute>
            }
          />
          <Route
            path="/eksekutor/tambahEksekutor"
            element={
              <EKSEKUTORRoute>
                <TambahEksekutor />
              </EKSEKUTORRoute>
            }
          />
          {/*  Tipe */}
          <Route
            path="/tipe"
            element={
              <TIPERoute>
                <TampilTipe />
              </TIPERoute>
            }
          />
          <Route
            path="/tipe/:id"
            element={
              <TIPERoute>
                <TampilTipe />
              </TIPERoute>
            }
          />
          <Route
            path="/tipe/:id/edit"
            element={
              <TIPERoute>
                <UbahTipe />
              </TIPERoute>
            }
          />
          <Route
            path="/tipe/tambahTipe"
            element={
              <TIPERoute>
                <TambahTipe />
              </TIPERoute>
            }
          />
          {/*  Warna */}
          <Route
            path="/warna"
            element={
              <WARNARoute>
                <TampilWarna />
              </WARNARoute>
            }
          />
          <Route
            path="/warna/:id"
            element={
              <WARNARoute>
                <TampilWarna />
              </WARNARoute>
            }
          />
          <Route
            path="/warna/:id/edit"
            element={
              <WARNARoute>
                <UbahWarna />
              </WARNARoute>
            }
          />
          <Route
            path="/warna/tambahWarna"
            element={
              <WARNARoute>
                <TambahWarna />
              </WARNARoute>
            }
          />
          {/* GADAI */}
          {/* Pengajuan */}
          <Route
            path="/daftarPengajuan"
            element={
              <PENGAJUANRoute>
                <TampilDaftarPengajuan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/tambahPengajuan"
            element={
              <PENGAJUANRoute>
                <TambahPengajuan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/:id"
            element={
              <PENGAJUANRoute>
                <TampilPengajuan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/:id/edit"
            element={
              <PENGAJUANRoute>
                <UbahPengajuan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/:id/tambahJaminan"
            element={
              <PENGAJUANRoute>
                <TambahJaminan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/:id/:idJaminan"
            element={
              <PENGAJUANRoute>
                <TampilJaminan />
              </PENGAJUANRoute>
            }
          />
          <Route
            path="/daftarPengajuan/pengajuan/:id/:idJaminan/edit"
            element={
              <PENGAJUANRoute>
                <UbahJaminan />
              </PENGAJUANRoute>
            }
          />
          {/* Info Nasabah Gadai */}
          <Route
            path="/daftarInfoNasabahGadai"
            element={
              <INFONASABAHGADAIRoute>
                <TampilDaftarInfoNasabahGadai />
              </INFONASABAHGADAIRoute>
            }
          />
          <Route
            path="/daftarInfoNasabahGadai/infoNasabahGadai/:id"
            element={
              <INFONASABAHGADAIRoute>
                <TampilInfoNasabahGadai />
              </INFONASABAHGADAIRoute>
            }
          />
          {/* Approval */}
          <Route
            path="/daftarApproval"
            element={
              <APPROVALRoute>
                <TampilDaftarApproval />
              </APPROVALRoute>
            }
          />
          <Route
            path="/daftarApproval/approval/tambahApproval"
            element={
              <APPROVALRoute>
                <TambahApproval />
              </APPROVALRoute>
            }
          />
          <Route
            path="/daftarApproval/approval/:id"
            element={
              <APPROVALRoute>
                <TampilApproval />
              </APPROVALRoute>
            }
          />
          <Route
            path="/daftarApproval/approval/:id/edit"
            element={
              <APPROVALRoute>
                <UbahApproval />
              </APPROVALRoute>
            }
          />
          {/* Bukti Pencairan */}
          <Route
            path="/daftarBuktiPencairan"
            element={
              <BUKTIPENCAIRANRoute>
                <TampilDaftarBuktiPencairan />
              </BUKTIPENCAIRANRoute>
            }
          />
          <Route
            path="/daftarBuktiPencairan/buktiPencairan/:id"
            element={
              <BUKTIPENCAIRANRoute>
                <TampilBuktiPencairan />
              </BUKTIPENCAIRANRoute>
            }
          />
          {/* Topup */}
          <Route
            path="/daftarTopup"
            element={
              <TOPUPRoute>
                <TampilDaftarTopup />
              </TOPUPRoute>
            }
          />
          <Route
            path="/daftarTopup/topup/tambahTopup"
            element={
              <TOPUPRoute>
                <TambahTopup />
              </TOPUPRoute>
            }
          />
          <Route
            path="/daftarTopup/topup/:id"
            element={
              <TOPUPRoute>
                <TampilTopup />
              </TOPUPRoute>
            }
          />
          <Route
            path="/daftarTopup/topup/:id/:idTopup"
            element={
              <TOPUPRoute>
                <TampilOneTopup />
              </TOPUPRoute>
            }
          />
          <Route
            path="/daftarTopup/topup/:id/:idTopup/edit"
            element={
              <TOPUPRoute>
                <UbahTopup />
              </TOPUPRoute>
            }
          />
          {/* Tebus */}
          <Route
            path="/daftarTebus"
            element={
              <TEBUSRoute>
                <TampilDaftarTebus />
              </TEBUSRoute>
            }
          />
          <Route
            path="/daftarTebus/tebus/tambahTebus"
            element={
              <TEBUSRoute>
                <TambahTebus />
              </TEBUSRoute>
            }
          />
          <Route
            path="/daftarTebus/tebus/:id"
            element={
              <TEBUSRoute>
                <TampilTebus />
              </TEBUSRoute>
            }
          />
          {/* Perpanjangan */}
          <Route
            path="/daftarPerpanjangan"
            element={
              <PERPANJANGANRoute>
                <TampilDaftarPerpanjangan />
              </PERPANJANGANRoute>
            }
          />
          <Route
            path="/daftarPerpanjangan/perpanjangan/tambahPerpanjangan"
            element={
              <PERPANJANGANRoute>
                <TambahPerpanjangan />
              </PERPANJANGANRoute>
            }
          />
          <Route
            path="/daftarPerpanjangan/perpanjangan/:id"
            element={
              <PERPANJANGANRoute>
                <TampilPerpanjangan />
              </PERPANJANGANRoute>
            }
          />
          <Route
            path="/daftarPerpanjangan/perpanjangan/:id/edit"
            element={
              <PERPANJANGANRoute>
                <UbahPerpanjangan />
              </PERPANJANGANRoute>
            }
          />
          {/* Cicilan */}
          <Route
            path="/daftarCicilan"
            element={
              <CICILANRoute>
                <TampilDaftarCicilan />
              </CICILANRoute>
            }
          />
          <Route
            path="/daftarCicilan/cicilan/tambahCicilan"
            element={
              <CICILANRoute>
                <TambahCicilan />
              </CICILANRoute>
            }
          />
          <Route
            path="/daftarCicilan/cicilan/:id"
            element={
              <CICILANRoute>
                <TampilCicilan />
              </CICILANRoute>
            }
          />
          <Route
            path="/daftarCicilan/cicilan/:id/edit"
            element={
              <CICILANRoute>
                <UbahCicilan />
              </CICILANRoute>
            }
          />
          {/* Gadai Jual Agunan */}
          <Route
            path="/jualAgunan"
            element={
              <JUALAGUNANRoute>
                <TampilJualAgunan />
              </JUALAGUNANRoute>
            }
          />
          <Route
            path="/jualAgunan/:id"
            element={
              <JUALAGUNANRoute>
                <TampilJualAgunan />
              </JUALAGUNANRoute>
            }
          />
          <Route
            path="/jualAgunan/:id/edit"
            element={
              <JUALAGUNANRoute>
                <UbahJualAgunan />
              </JUALAGUNANRoute>
            }
          />
          <Route
            path="/jualAgunan/tambahJualAgunan"
            element={
              <JUALAGUNANRoute>
                <TambahJualAgunan />
              </JUALAGUNANRoute>
            }
          />
          {/* Laporan Gadai */}
          <Route
            path="/laporanTunggakan"
            element={
              <LAPTUNGGAKANRoute>
                <LaporanTunggakan />
              </LAPTUNGGAKANRoute>
            }
          />
          <Route
            path="/laporanTunggakanRinci"
            element={
              <LAPTUNGGAKANRINCIRoute>
                <LaporanTunggakanRinci />
              </LAPTUNGGAKANRINCIRoute>
            }
          />
          <Route
            path="/laporanTotalPiutang"
            element={
              <LAPOUTSTANDINGRoute>
                <LaporanOutstanding />
              </LAPOUTSTANDINGRoute>
            }
          />
          <Route
            path="/laporanStockSiapDijual"
            element={
              <LAPSTOCKSIAPDIJUALRoute>
                <LaporanStockSiapDijual />
              </LAPSTOCKSIAPDIJUALRoute>
            }
          />
          <Route
            path="/laporanPenerimaan"
            element={
              <LAPPENERIMAANRoute>
                <LaporanPenerimaan />
              </LAPPENERIMAANRoute>
            }
          />
          <Route
            path="/laporanJualBarang"
            element={
              <LAPJUALBARANGRoute>
                <LaporanJualBarang />
              </LAPJUALBARANGRoute>
            }
          />
          <Route
            path="/laporanOutstanding"
            element={
              <LAPOUTSTANDINGGADAIRoute>
                <LaporanOutstandingGadai />
              </LAPOUTSTANDINGGADAIRoute>
            }
          />
          <Route
            path="/laporanPiutangLunas"
            element={
              <LAPPIUTANGLUNASGADAIRoute>
                <LaporanPiutangLunasGadai />
              </LAPPIUTANGLUNASGADAIRoute>
            }
          />
          {/* FIDUSIA */}
          {/* Pengajuan */}
          <Route
            path="/daftarPengajuanFidusia"
            element={
              <PENGAJUANFIDUSIARoute>
                <TampilDaftarPengajuanFidusia />
              </PENGAJUANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarPengajuanFidusia/pengajuanFidusia/tambahPengajuanFidusia"
            element={
              <PENGAJUANFIDUSIARoute>
                <TambahPengajuanFidusia />
              </PENGAJUANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarPengajuanFidusia/pengajuanFidusia/:id"
            element={
              <PENGAJUANFIDUSIARoute>
                <TampilPengajuanFidusia />
              </PENGAJUANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarPengajuanFidusia/pengajuanFidusia/:id/edit"
            element={
              <PENGAJUANFIDUSIARoute>
                <UbahPengajuanFidusia />
              </PENGAJUANFIDUSIARoute>
            }
          />
          {/* Approval Fidusia */}
          <Route
            path="/daftarApprovalFidusia"
            element={
              <APPROVALFIDUSIARoute>
                <TampilDaftarApprovalFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/tambahApprovalFidusia"
            element={
              <APPROVALFIDUSIARoute>
                <TambahApprovalFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/:id"
            element={
              <APPROVALFIDUSIARoute>
                <TampilApprovalFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/:id/edit"
            element={
              <APPROVALFIDUSIARoute>
                <UbahApprovalFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/tambahRejectFidusia"
            element={
              <APPROVALFIDUSIARoute>
                <TambahRejectFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/reject/:id"
            element={
              <APPROVALFIDUSIARoute>
                <TampilRejectFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          <Route
            path="/daftarApprovalFidusia/approvalFidusia/reject/:id/edit"
            element={
              <APPROVALFIDUSIARoute>
                <UbahRejectFidusia />
              </APPROVALFIDUSIARoute>
            }
          />
          {/* Surat Fidusia SP1 */}
          <Route
            path="/daftarSp1Fidusia"
            element={
              <SP1Route>
                <TampilDaftarSp1Fidusia />
              </SP1Route>
            }
          />
          <Route
            path="/daftarSp1Fidusia/sp1Fidusia/tambahSp1Fidusia"
            element={
              <SP1Route>
                <TambahSp1Fidusia />
              </SP1Route>
            }
          />
          <Route
            path="/daftarSp1Fidusia/sp1Fidusia/:id"
            element={
              <SP1Route>
                <TampilSp1Fidusia />
              </SP1Route>
            }
          />
          {/* Surat Fidusia SP2 */}
          <Route
            path="/daftarSp2Fidusia"
            element={
              <SP2Route>
                <TampilDaftarSp2Fidusia />
              </SP2Route>
            }
          />
          <Route
            path="/daftarSp2Fidusia/sp2Fidusia/tambahSp2Fidusia"
            element={
              <SP2Route>
                <TambahSp2Fidusia />
              </SP2Route>
            }
          />
          <Route
            path="/daftarSp2Fidusia/sp2Fidusia/:id"
            element={
              <SP2Route>
                <TampilSp2Fidusia />
              </SP2Route>
            }
          />
          {/* Surat Fidusia SP3 */}
          <Route
            path="/daftarSp3Fidusia"
            element={
              <SP3Route>
                <TampilDaftarSp3Fidusia />
              </SP3Route>
            }
          />
          <Route
            path="/daftarSp3Fidusia/sp3Fidusia/tambahSp3Fidusia"
            element={
              <SP3Route>
                <TambahSp3Fidusia />
              </SP3Route>
            }
          />
          <Route
            path="/daftarSp3Fidusia/sp3Fidusia/:id"
            element={
              <SP3Route>
                <TampilSp3Fidusia />
              </SP3Route>
            }
          />
          {/* Surat Panggilan SP3 */}
          <Route
            path="/daftarSuratPanggilanFidusia"
            element={
              <SPANGGILANRoute>
                <TampilDaftarSuratPanggilanFidusia />
              </SPANGGILANRoute>
            }
          />
          <Route
            path="/daftarSuratPanggilanFidusia/suratPanggilanFidusia/tambahSuratPanggilanFidusia"
            element={
              <SPANGGILANRoute>
                <TambahSuratPanggilanFidusia />
              </SPANGGILANRoute>
            }
          />
          <Route
            path="/daftarSuratPanggilanFidusia/suratPanggilanFidusia/:id"
            element={
              <SPANGGILANRoute>
                <TampilSuratPanggilanFidusia />
              </SPANGGILANRoute>
            }
          />
          {/* Bukti Pencairan Fidusia */}
          <Route
            path="/daftarBuktiPencairanFidusia"
            element={
              <BUKTIPENCAIRANFIDUSIARoute>
                <TampilDaftarBuktiPencairanFidusia />
              </BUKTIPENCAIRANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarBuktiPencairanFidusia/buktiPencairanFidusia/:id"
            element={
              <BUKTIPENCAIRANFIDUSIARoute>
                <TampilBuktiPencairanFidusia />
              </BUKTIPENCAIRANFIDUSIARoute>
            }
          />
          {/* Angsuran Fidusia */}
          <Route
            path="/daftarAngsuranFidusia"
            element={
              <ANGSURANFIDUSIARoute>
                <TampilDaftarAngsuranFidusia />
              </ANGSURANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarAngsuranFidusia/angsuranFidusia/tambahAngsuranFidusia"
            element={
              <ANGSURANFIDUSIARoute>
                <TambahAngsuranFidusia />
              </ANGSURANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarAngsuranFidusia/angsuranFidusia/:id"
            element={
              <ANGSURANFIDUSIARoute>
                <TampilAngsuranFidusia />
              </ANGSURANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarAngsuranFidusia/angsuranFidusia/:id/sp"
            element={
              <ANGSURANFIDUSIARoute>
                <TampilAngsuranFidusiaSP />
              </ANGSURANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarAngsuranFidusia/angsuranFidusia/:id/:idAngsuranChild"
            element={
              <ANGSURANFIDUSIARoute>
                <TampilAngsuranFidusiaChild />
              </ANGSURANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarAngsuranFidusia/angsuranFidusia/:id/:idAngsuranChild/edit"
            element={
              <ANGSURANFIDUSIARoute>
                <UbahAngsuranFidusiaChild />
              </ANGSURANFIDUSIARoute>
            }
          />
          {/* Info Nasabah Fidusia */}
          <Route
            path="/daftarInfoNasabahFidusia"
            element={
              <INFONASABAHFIDUSIARoute>
                <TampilDaftarInfoNasabahFidusia />
              </INFONASABAHFIDUSIARoute>
            }
          />
          <Route
            path="/daftarInfoNasabahFidusia/infoNasabahFidusia/:id"
            element={
              <INFONASABAHFIDUSIARoute>
                <TampilInfoNasabahFidusia />
              </INFONASABAHFIDUSIARoute>
            }
          />
          <Route
            path="/daftarInfoNasabahFidusia/infoNasabahFidusia/:id/sp"
            element={
              <INFONASABAHFIDUSIARoute>
                <TampilInfoNasabahFidusiaSP />
              </INFONASABAHFIDUSIARoute>
            }
          />
          {/* Penarikan Fidusia */}
          <Route
            path="/daftarPenarikanFidusia"
            element={
              <PENARIKANFIDUSIARoute>
                <TampilDaftarPenarikanFidusia />
              </PENARIKANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarPenarikanFidusia/penarikanFidusia/:id"
            element={
              <PENARIKANFIDUSIARoute>
                <TampilPenarikanFidusia />
              </PENARIKANFIDUSIARoute>
            }
          />
          <Route
            path="/daftarPenarikanFidusia/penarikanFidusia/tambahPenarikanFidusia"
            element={
              <PENARIKANFIDUSIARoute>
                <TambahPenarikanFidusia />
              </PENARIKANFIDUSIARoute>
            }
          />
          {/* Percepatan Fidusia */}
          <Route
            path="/tambahPercepatanFidusia"
            element={
              <PERCEPATANFIDUSIARoute>
                <TambahPercepatanFidusia />
              </PERCEPATANFIDUSIARoute>
            }
          />
          {/* Laporan Fidusia */}
          <Route
            path="/laporanTunggakanFidusia"
            element={
              <LAPTUNGGAKANFIDUSIARoute>
                <LaporanTunggakanFidusia />
              </LAPTUNGGAKANFIDUSIARoute>
            }
          />
          <Route
            path="/laporanTunggakanFidusiaRinci"
            element={
              <LAPTUNGGAKANFIDUSIARINCIRoute>
                <LaporanTunggakanFidusiaRinci />
              </LAPTUNGGAKANFIDUSIARINCIRoute>
            }
          />
          <Route
            path="/laporanTotalPiutangFidusia"
            element={
              <LAPTOTALPIUTANGFIDUSIARoute>
                <LaporanTotalPiutang />
              </LAPTOTALPIUTANGFIDUSIARoute>
            }
          />
          <Route
            path="/laporanPenarikanFidusia"
            element={
              <LAPPENARIKANFIDUSIARoute>
                <LaporanPenarikanFidusia />
              </LAPPENARIKANFIDUSIARoute>
            }
          />
          <Route
            path="/laporanPinjamanJatuhTempoFidusia"
            element={
              <LAPPINJAMANJATUHTEMPOFIDUSIARoute>
                <LaporanPinjamanJatuhTempo />
              </LAPPINJAMANJATUHTEMPOFIDUSIARoute>
            }
          />
          <Route
            path="/laporanPenerimaanFidusia"
            element={
              <LAPPENERIMAANFIDUSIARoute>
                <LaporanPenerimaanFidusia />
              </LAPPENERIMAANFIDUSIARoute>
            }
          />
          <Route
            path="/laporanOutstandingFidusia"
            element={
              <LAPOUTSTANDINGFIDUSIARoute>
                <LaporanOutstandingFidusia />
              </LAPOUTSTANDINGFIDUSIARoute>
            }
          />
          <Route
            path="/laporanPiutangLunasFidusia"
            element={
              <LAPPIUTANGLUNASFIDUSIARoute>
                <LaporanPiutangLunasFidusia />
              </LAPPIUTANGLUNASFIDUSIARoute>
            }
          />
          {/* FINANCE */}
          {/* Kas Masuk */}
          <Route
            path="/daftarKasMasuk"
            element={
              <KASMASUKRoute>
                <TampilDaftarKasMasuk />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/tambahKasMasuk"
            element={
              <KASMASUKRoute>
                <TambahKasMasuk />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/:id"
            element={
              <KASMASUKRoute>
                <TampilKasMasuk />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/:id/edit"
            element={
              <KASMASUKRoute>
                <UbahKasMasuk />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/:id/tambahKasMasukChild"
            element={
              <KASMASUKRoute>
                <TambahKasMasukChild />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/:id/:idKasMasukChild"
            element={
              <KASMASUKRoute>
                <TampilKasMasukChild />
              </KASMASUKRoute>
            }
          />
          <Route
            path="/daftarKasMasuk/kasMasuk/:id/:idKasMasukChild/edit"
            element={
              <KASMASUKRoute>
                <UbahKasMasukChild />
              </KASMASUKRoute>
            }
          />
          {/* Kas Keluar */}
          <Route
            path="/daftarKasKeluar"
            element={
              <KASKELUARRoute>
                <TampilDaftarKasKeluar />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/tambahKasKeluar"
            element={
              <KASKELUARRoute>
                <TambahKasKeluar />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/:id"
            element={
              <KASKELUARRoute>
                <TampilKasKeluar />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/:id/edit"
            element={
              <KASKELUARRoute>
                <UbahKasKeluar />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/:id/tambahKasKeluarChild"
            element={
              <KASKELUARRoute>
                <TambahKasKeluarChild />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/:id/:idKasKeluarChild"
            element={
              <KASKELUARRoute>
                <TampilKasKeluarChild />
              </KASKELUARRoute>
            }
          />
          <Route
            path="/daftarKasKeluar/kasKeluar/:id/:idKasKeluarChild/edit"
            element={
              <KASKELUARRoute>
                <UbahKasKeluarChild />
              </KASKELUARRoute>
            }
          />
          {/* Bank Masuk */}
          <Route
            path="/daftarBankMasuk"
            element={
              <BANKMASUKRoute>
                <TampilDaftarBankMasuk />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/tambahBankMasuk"
            element={
              <BANKMASUKRoute>
                <TambahBankMasuk />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/:id"
            element={
              <BANKMASUKRoute>
                <TampilBankMasuk />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/:id/edit"
            element={
              <BANKMASUKRoute>
                <UbahBankMasuk />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/:id/tambahBankMasukChild"
            element={
              <BANKMASUKRoute>
                <TambahBankMasukChild />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/:id/:idBankMasukChild"
            element={
              <BANKMASUKRoute>
                <TampilBankMasukChild />
              </BANKMASUKRoute>
            }
          />
          <Route
            path="/daftarBankMasuk/bankMasuk/:id/:idBankMasukChild/edit"
            element={
              <BANKMASUKRoute>
                <UbahBankMasukChild />
              </BANKMASUKRoute>
            }
          />
          {/* Bank Keluar */}
          <Route
            path="/daftarBankKeluar"
            element={
              <BANKKELUARRoute>
                <TampilDaftarBankKeluar />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/tambahBankKeluar"
            element={
              <BANKKELUARRoute>
                <TambahBankKeluar />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/:id"
            element={
              <BANKKELUARRoute>
                <TampilBankKeluar />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/:id/edit"
            element={
              <BANKKELUARRoute>
                <UbahBankKeluar />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/:id/tambahBankKeluarChild"
            element={
              <BANKKELUARRoute>
                <TambahBankKeluarChild />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/:id/:idBankKeluarChild"
            element={
              <BANKKELUARRoute>
                <TampilBankKeluarChild />
              </BANKKELUARRoute>
            }
          />
          <Route
            path="/daftarBankKeluar/bankKeluar/:id/:idBankKeluarChild/edit"
            element={
              <BANKKELUARRoute>
                <UbahBankKeluarChild />
              </BANKKELUARRoute>
            }
          />
          {/* Posting */}
          <Route
            path="/posting"
            element={
              <POSTINGRoute>
                <Posting />
              </POSTINGRoute>
            }
          />
          <Route
            path="/unposting"
            element={
              <POSTINGRoute>
                <Unposting />
              </POSTINGRoute>
            }
          />
          <Route
            path="/aktivitasBukuBesar"
            element={
              <AKTIVITASBUKUBESARRoute>
                <AktivitasBukuBesar />
              </AKTIVITASBUKUBESARRoute>
            }
          />
          <Route
            path="/neracaSaldo"
            element={
              <NERACASALDORoute>
                <NeracaSaldo />
              </NERACASALDORoute>
            }
          />
          <Route
            path="/jurnal"
            element={
              <JURNALRoute>
                <Jurnal />
              </JURNALRoute>
            }
          />
          <Route
            path="/labaRugiRekap"
            element={
              <LABARUGIREKAPRoute>
                <LabaRugiRekap />
              </LABARUGIREKAPRoute>
            }
          />
          <Route
            path="/labaRugi"
            element={
              <LABARUGIRoute>
                <LabaRugi />
              </LABARUGIRoute>
            }
          />
          <Route
            path="/neraca"
            element={
              <NERACARoute>
                <Neraca />
              </NERACARoute>
            }
          />
          <Route
            path="/arusKasRekap"
            element={
              <ARUSKASREKAPRoute>
                <ArusKasRekap />
              </ARUSKASREKAPRoute>
            }
          />
          {/* Accounting OJK */}
          <Route
            path="/labaRugiOjk"
            element={
              <LABARUGIOJKRoute>
                <LabaRugiOjk />
              </LABARUGIOJKRoute>
            }
          />
          <Route
            path="/neracaOjk"
            element={
              <NERACAOJKRoute>
                <NeracaOjk />
              </NERACAOJKRoute>
            }
          />
          <Route
            path="/arusKasOjk"
            element={
              <ARUSKASOJKRoute>
                <ArusKasOjk />
              </ARUSKASOJKRoute>
            }
          />
          {/* Jurnal Umum */}
          <Route
            path="/daftarJurnalUmum"
            element={
              <JURNALUMUMRoute>
                <TampilDaftarJurnalUmum />
              </JURNALUMUMRoute>
            }
          />
          <Route
            path="/daftarJurnalUmum/jurnalUmum/tambahJurnalUmum"
            element={
              <JURNALUMUMRoute>
                <TambahJurnalUmum />
              </JURNALUMUMRoute>
            }
          />
          <Route
            path="/daftarJurnalUmum/jurnalUmum/:id"
            element={
              <JURNALUMUMRoute>
                <TampilJurnalUmum />
              </JURNALUMUMRoute>
            }
          />
          <Route
            path="/daftarJurnalUmum/jurnalUmum/:id/edit"
            element={
              <JURNALUMUMRoute>
                <UbahJurnalUmum />
              </JURNALUMUMRoute>
            }
          />
          {/* Jurnal Umum Child */}
          <Route
            path="/daftarJurnalUmum/jurnalUmum/:id/tambahJurnalUmumChild"
            element={
              <JURNALUMUMRoute>
                <TambahJurnalUmumChild />
              </JURNALUMUMRoute>
            }
          />
          <Route
            path="/daftarJurnalUmum/jurnalUmum/:id/:idJurnalUmumChild"
            element={
              <JURNALUMUMRoute>
                <TampilJurnalUmumChild />
              </JURNALUMUMRoute>
            }
          />
          <Route
            path="/daftarJurnalUmum/jurnalUmum/:id/:idJurnalUmumChild/edit"
            element={
              <JURNALUMUMRoute>
                <UbahJurnalUmumChild />
              </JURNALUMUMRoute>
            }
          />
          {/* Laporan Jurnal Umum */}
          <Route
            path="/laporanJurnalUmum"
            element={
              <JURNALUMUMRoute>
                <LaporanJurnalUmum />
              </JURNALUMUMRoute>
            }
          />
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default App;
