import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import DatePicker from "react-datepicker";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const UbahPengajuanFidusia = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // Data Customer
  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [ketResikoAju, setKetResikoAju] = useState("");

  // Data Penjamin
  const [alamatSama, setAlamatSama] = useState(false);
  const [nikPenjamin, setNikPenjamin] = useState("");
  const [noKkPenjamin, setNoKkPenjamin] = useState("");
  const [namaPenjamin, setNamaPenjamin] = useState("");
  const [tempatLahirPenjamin, setTempatLahirPenjamin] = useState("");
  const [tanggalLahirPenjamin, setTanggalLahirPenjamin] = useState(new Date());
  const [jenisKelaminPenjamin, setJenisKelaminPenjamin] = useState("");
  const [agamaPenjamin, setAgamaPenjamin] = useState("");
  const [noTeleponPenjamin, setNoTeleponPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [alamatRefRegister, setAlamatRefRegister] = useState("");
  const [teleponRefRegister, setTeleponRefRegister] = useState("");
  const [alamatPenjamin, setAlamatPenjamin] = useState("");
  const [kodeProvinsiPenjamin, setKodeProvinsiPenjamin] = useState("");
  const [kodeKabupatenPenjamin, setKodeKabupatenPenjamin] = useState("");
  const [kodeKecamatanPenjamin, setKodeKecamatanPenjamin] = useState("");
  const [kodeKelurahanPenjamin, setKodeKelurahanPenjamin] = useState("");
  const [kodePosPenjamin, setKodePosPenjamin] = useState("");
  const [statusPerkawinanPenjamin, setStatusPerkawinanPenjamin] = useState("");
  const [pekerjaanPenjamin, setPekerjaanPenjamin] = useState("");
  const [kewarganegaraanPenjamin, setKewarganegaraanPenjamin] = useState("");

  // Data Pinjaman
  const [noAju, setNoAju] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [kategori, setKategori] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState(0);
  const [angPerBulan, setAngPerBulan] = useState(0);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tanggalAju, setTanggalAju] = useState(new Date());
  const [tglAng, setTglAng] = useState(new Date());
  const [tglAngAkhir, setTglAngAkhir] = useState(new Date());
  const [tenor, setTenor] = useState(0);
  const [bungaPerTahun, setBungaPerTahun] = useState(0);
  const [totalPiutang, setTotalPiutang] = useState(0);
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  // Data Kendaraan
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [cc, setCc] = useState("");
  const [kodeWarna, setKodeWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [tglStnk, setTglStnk] = useState(new Date());
  const [bpkbAN, setBpkbAN] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [isi, setIsi] = useState("");
  const [merk, setMerk] = useState("");
  const [nopol, setNopol] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("");
  const [taksiran, setTaksiran] = useState("");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("");

  // Asuransi
  const [noFidusia, setNoFidusia] = useState("");
  const [nilaiPertanggungan, setNilaiPertanggungan] = useState(0);
  const [noAsuransi, setNoAsuransi] = useState("");

  const [kelurahans, setKelurahans] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [coas, setCoas] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [tipes, setTipes] = useState([]);
  const [warnas, setWarnas] = useState([]);
  const [error, setError] = useState(false);
  const [searchTermCustomer, setSearchTermCustomer] = useState("");
  const [openCustomer, setOpenCustomer] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClickOpenCustomer = () => {
    setOpenCustomer(true);
  };

  const handleCloseCustomer = () => {
    setOpenCustomer(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisKelaminOption = ["LAKI-LAKI", "PEREMPUAN"];
  const agamaOption = [
    "ISLAM",
    "PROTESTAN",
    "KATOLIK",
    "HINDU",
    "BUDHA",
    "KHONGHUCU",
    "LAIN",
  ];
  const statusPerkawinanOption = ["BELUM MENIKAH", "(JANDA/DUDA)", "MENIKAH"];
  const kewarganegaraanOption = ["WNI", "WNA"];
  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];
  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];

  let kelurahanOptions = kelurahans.map((kelurahan) => ({
    label: `${kelurahan.id} - ${kelurahan.namaKelurahan}`,
  }));
  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));
  let surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));
  let tipeOptions = tipes.map((tipe) => ({
    label: `${tipe.kodeTipe} | ${tipe.namaTipe}`,
  }));

  const tempPostsCustomer = customers.filter((val) => {
    if (searchTermCustomer === "") {
      return val;
    } else if (
      val.namaCustomer
        .toUpperCase()
        .includes(searchTermCustomer.toUpperCase()) ||
      val.alamatCustomer
        .toUpperCase()
        .includes(searchTermCustomer.toUpperCase()) ||
      val.noTeleponCustomer
        .toUpperCase()
        .includes(searchTermCustomer.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getKelurahansData();
    getMarketingsData();
    getSurveyorsData();
    getCoasData();
    getTipesData();
    getWarnasData();
    getPengajuanFidusiaById();
  }, []);

  const getCoasData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const getKelurahansData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/kelurahans`, {
      _id: user.id,
      token: user.token,
    });
    setKelurahans(response.data);
  };

  const findKelurahan = async (kelurahanId) => {
    kelurahanId = kelurahanId.split(" ", 1)[0];
    if (kelurahanId) {
      const response = await axios.post(
        `${tempUrl}/kelurahans/${kelurahanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKelurahanPenjamin(kelurahanId);
      setKodeProvinsiPenjamin(
        `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
      );
      setKodeKabupatenPenjamin(
        `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
      );
      setKodeKecamatanPenjamin(
        `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
      );
      setKodePosPenjamin(response.data.kodePos);
    } else {
      setKodeKelurahanPenjamin("");
      setKodeProvinsiPenjamin("");
      setKodeKabupatenPenjamin("");
      setKodeKecamatanPenjamin("");
      setKodePosPenjamin("");
    }
  };

  const findTipe = async (id) => {
    id = id.split(" |", 1)[0];
    if (id) {
      const response = await axios.post(`${tempUrl}/tipeByKodeTipe`, {
        kodeTipe: id,
        _id: user.id,
        token: user.token,
      });
      setKodeTipe(id);
      setNoRangka(response.data.noRangka);
      setMerk(response.data.merk);
      setIsi(response.data.isi);
      setNoMesin(response.data.noMesin);
    } else {
      setKodeTipe("");
      setNoRangka("");
      setMerk("");
      setIsi("");
      setNoMesin("");
    }
  };

  const getMarketingsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getSurveyorsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/surveyors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setSurveyors(response.data);
  };

  const getTipesData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/tipes`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setTipes(response.data);
  };

  const getWarnasData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/warnas`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setWarnas(response.data);
  };

  const getPengajuanFidusiaById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/pengajuanFidusias/${id}`, {
      _id: user.id,
      token: user.token,
    });
    // Data Customer
    setCifCustomer(response.data.customer.cifCustomer);
    setNikCustomer(response.data.customer.nikCustomer);
    setNoKkCustomer(response.data.customer.noKkCustomer);
    setNamaCustomer(response.data.customer.namaCustomer);
    setTempatLahirCustomer(response.data.customer.tempatLahirCustomer);
    setTanggalLahirCustomer(
      formatDate(response.data.customer.tanggalLahirCustomer)
    );
    setJenisKelaminCustomer(response.data.customer.jenisKelaminCustomer);
    setAgamaCustomer(response.data.customer.agamaCustomer);
    setNoTeleponCustomer(response.data.customer.noTeleponCustomer);
    setAlamatCustomer(response.data.customer.alamatCustomer);
    setAlamatTTCustomer(response.data.customer.alamatTTCustomer);
    setKodeProvinsi(response.data.customer.provinsis.namaProvinsi);
    setKodeProvinsiTT(response.data.customer.provinsisTT.namaProvinsi);
    setKodeKabupaten(response.data.customer.kabupaten.namaKabupaten);
    setKodeKabupatenTT(response.data.customer.kabupatenTT.namaKabupaten);
    setKodeKecamatan(response.data.customer.kecamatan.namaKecamatan);
    setKodeKecamatanTT(response.data.customer.kecamatanTT.namaKecamatan);
    setKodeKelurahan(response.data.customer.kelurahan.namaKelurahan);
    setKodeKelurahanTT(response.data.customer.kelurahanTT.namaKelurahan);
    setKodePos(response.data.customer.kelurahan.kodePos);
    setKodePosTT(response.data.customer.kelurahanTT.kodePos);
    setStatusPerkawinanCustomer(
      response.data.customer.statusPerkawinanCustomer
    );
    setPekerjaanCustomer(response.data.customer.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.customer.kewarganegaraanCustomer);
    setKodeCOA(response.data.coa.kodeCOA);
    setJenisResikoAju(response.data.jenisResikoAju);
    setKetResikoAju(response.data.ketResikoAju);

    // Data Penjamin
    setNikPenjamin(response.data.nikPenjamin);
    setNoKkPenjamin(response.data.noKkPenjamin);
    setNamaPenjamin(response.data.namaPenjamin);
    setTempatLahirPenjamin(response.data.tempatLahirPenjamin);
    setTanggalLahirPenjamin(new Date(response.data.tanggalLahirPenjamin));
    setJenisKelaminPenjamin(response.data.jenisKelaminPenjamin);
    setAgamaPenjamin(response.data.agamaPenjamin);
    setNoTeleponPenjamin(response.data.noTeleponPenjamin);
    setNamaRefRegister(response.data.namaRefRegister);
    setAlamatRefRegister(response.data.alamatRefRegister);
    setTeleponRefRegister(response.data.teleponRefRegister);
    setAlamatPenjamin(response.data.alamatPenjamin);
    setKodeProvinsiPenjamin(response.data.provinsisPenjamin.namaProvinsi);
    setKodeKabupatenPenjamin(response.data.kabupatenPenjamin.namaKabupaten);
    setKodeKecamatanPenjamin(response.data.kecamatanPenjamin.namaKecamatan);
    setKodeKelurahanPenjamin(response.data.kelurahanPenjamin.id);
    setKodePosPenjamin(response.data.kelurahanPenjamin.kodePos);
    setStatusPerkawinanPenjamin(response.data.statusPerkawinanPenjamin);
    setPekerjaanPenjamin(response.data.pekerjaanPenjamin);
    setKewarganegaraanPenjamin(response.data.kewarganegaraanPenjamin);

    // Data Pinjaman
    setNoAju(response.data.noAju);
    setNoPerjanjian(response.data.noPerjanjian);
    setKategori(response.data.kategori);
    setPinjamanAju(response.data.pinjamanAju);
    setAngPerBulan(response.data.angPerBulan);
    setKodeMarketing(response.data.marketing.kodeMarketing);
    setTanggalAju(new Date(response.data.tanggalAju));
    setTglAng(new Date(response.data.tglAng));
    countDateDurationReview(response.data.tenor, response.data.tglAng);
    setTenor(response.data.tenor);
    setBungaPerTahun(response.data.bungaPerTahun);
    setTotalPiutang(response.data.totalPiutang);
    setKodeSurveyor(response.data.surveyor.kodeSurveyor);

    // Data Kendaraan
    setKodeTipe(response.data.tipe.kodeTipe);
    setMerk(response.data.tipe.merk);
    setTahun(response.data.tahun);
    setNoRangka(response.data.noRangka);
    setCc(response.data.tipe.isi);
    setKodeWarna(response.data.warna.id);
    setNoBpkb(response.data.noBpkb);
    setTglStnk(new Date(response.data.tglStnk));
    setBpkbAN(response.data.bpkbAN);
    setNoMesin(response.data.noMesin);
    setNopol(response.data.nopol);

    // Data Penilaian
    setHargaPasarSetempatOtr(response.data.hargaPasarSetempatOtr);
    setTaksiran(response.data.taksiran);
    setMaksimalPemberianPinjaman(response.data.maksimalPemberianPinjaman);

    // Data Asuransi
    setNoFidusia(response.data.noFidusia);
    setNilaiPertanggungan(response.data.nilaiPertanggungan);
    setNoAsuransi(response.data.noAsuransi);
    setLoading(false);
  };

  function dateWithMonthsDelay(input, months) {
    const date = new Date(input);
    date.setMonth(date.getMonth() + months);

    return date;
  }

  const updatePengajuanFidusia = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      cifCustomer.length !== 0 &&
      kodeKelurahanPenjamin.length !== 0 &&
      kodeMarketing.length !== 0 &&
      kodeSurveyor.length !== 0 &&
      kodeCOA.length !== 0 &&
      kodeTipe.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updatePengajuanFidusia/${id}`, {
          // Data Customer
          cifCustomer,
          jenisResikoAju,
          ketResikoAju,

          // Data Penjamin
          nikPenjamin,
          noKkPenjamin,
          namaPenjamin,
          tempatLahirPenjamin,
          tanggalLahirPenjamin,
          jenisKelaminPenjamin,
          agamaPenjamin,
          noTeleponPenjamin,
          namaRefRegister,
          alamatRefRegister,
          teleponRefRegister,
          alamatPenjamin,
          kelurahanIdPenjamin: kodeKelurahanPenjamin,
          statusPerkawinanPenjamin,
          pekerjaanPenjamin,
          kewarganegaraanPenjamin,

          // Data Pinjaman
          noPerjanjian,
          kategori,
          tglAng,
          tglJatuhTempo: tglAng,
          pinjamanAju: pinjamanAju,
          angPerBulan: angPerBulan,
          kodeMarketing,
          tanggalAju,
          tenor,
          bungaPerTahun: bungaPerTahun,
          totalPiutang,
          kodeSurveyor,
          kodeCOA,

          // Data Kendaraan
          kodeTipe,
          tahun,
          noRangka,
          kodeWarna,
          noBpkb,
          tglStnk,
          noMesin,
          isi,
          merk,
          nopol,
          bpkbAN,

          // Data Penilaian
          hargaPasarSetempatOtr,
          taksiran,
          maksimalPemberianPinjaman,

          // Data Asuransi
          noFidusia,
          nilaiPertanggungan,
          noAsuransi,

          kodeCabang: user.cabang.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarPengajuanFidusia/pengajuanFidusia/${id}`);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const countDateDuration = (date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    var finalDate = new Date(final);
    setTglAngAkhir(new Date(finalDate));
  };

  const countDateDurationReview = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    var finalDate = new Date(final);
    setTglAngAkhir(new Date(finalDate));
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Pengajuan</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={updatePengajuanFidusia}>
        <Card>
          <Card.Header>Data Pribadi Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          required
                          value={cifCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKkCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={agamaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisResikoAju}
                          onChange={(e) => {
                            setJenisResikoAju(e.target.value);
                          }}
                        >
                          {jenisResikoOption.map((jenisResiko) => (
                            <option value={jenisResiko}>{jenisResiko}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={ketResikoAju}
                          onChange={(e) =>
                            setKetResikoAju(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>Tempat Tinggal</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatTTCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupatenTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsiTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePosTT} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penjamin</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikPenjamin}
                          onChange={(e) =>
                            setNikPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noKkPenjamin}
                          onChange={(e) =>
                            setNoKkPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPenjamin}
                          onChange={(e) =>
                            setNamaPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirPenjamin}
                          onChange={(e) =>
                            setTempatLahirPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control />}
                          selected={tanggalLahirPenjamin}
                          onChange={(date) => setTanggalLahirPenjamin(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisKelaminPenjamin}
                          onChange={(e) => {
                            setJenisKelaminPenjamin(e.target.value);
                          }}
                        >
                          {jenisKelaminOption.map((jenisKelamin) => (
                            <option value={jenisKelamin}>{jenisKelamin}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={agamaPenjamin}
                          onChange={(e) => {
                            setAgamaPenjamin(e.target.value);
                          }}
                        >
                          {agamaOption.map((agama) => (
                            <option value={agama}>{agama}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponPenjamin}
                          onChange={(e) =>
                            setNoTeleponPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={statusPerkawinanPenjamin}
                          onChange={(e) => {
                            setStatusPerkawinanPenjamin(e.target.value);
                          }}
                        >
                          {statusPerkawinanOption.map((statusPerkawinan) => (
                            <option value={statusPerkawinan}>
                              {statusPerkawinan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanPenjamin}
                          onChange={(e) =>
                            setPekerjaanPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kewarganegaraanPenjamin}
                          onChange={(e) => {
                            setKewarganegaraanPenjamin(e.target.value);
                          }}
                        >
                          {kewarganegaraanOption.map((kewarganegaraan) => (
                            <option value={kewarganegaraan}>
                              {kewarganegaraan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRefRegister}
                          onChange={(e) =>
                            setNamaRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={alamatRefRegister}
                          onChange={(e) =>
                            setAlamatRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={teleponRefRegister}
                          onChange={(e) =>
                            setTeleponRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={alamatPenjamin}
                              onChange={(e) =>
                                setAlamatPenjamin(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelurahanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKelurahanPenjamin.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findKelurahan(value.label);
                                } else {
                                  findKelurahan("");
                                }
                              }}
                              defaultValue={kodeKelurahanPenjamin}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKecamatanPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKabupatenPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeProvinsiPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodePosPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Perjanjian :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noPerjanjian}
                          onChange={(e) =>
                            setNoPerjanjian(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pinjaman Rp. <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={pinjamanAju}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setPinjamanAju(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={angPerBulan}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setAngPerBulan(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );

                            let tempNum = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            let tempTotalPiutang = parseFloat(tempNum) * tenor;
                            setTotalPiutang(tempTotalPiutang);

                            let tempBungaPerTahun = (
                              ((tempTotalPiutang - pinjamanAju) /
                                pinjamanAju /
                                (tenor / 12)) *
                              100
                            ).toFixed(2);

                            if (
                              isFinite(tempBungaPerTahun) &&
                              !isNaN(tempBungaPerTahun)
                            ) {
                              setBungaPerTahun(tempBungaPerTahun);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          type="number"
                          value={tenor}
                          onChange={(e) => {
                            setTenor(e.target.value);
                            let tempTotalPiutang =
                              parseInt(angPerBulan) * e.target.value;
                            setTotalPiutang(tempTotalPiutang);

                            let tempBungaPerTahun =
                              ((tempTotalPiutang - pinjamanAju) / pinjamanAju) *
                              100;
                            if (
                              isFinite(tempBungaPerTahun) &&
                              !isNaN(tempBungaPerTahun)
                            ) {
                              setBungaPerTahun(
                                tempBungaPerTahun.toLocaleString("de-DE")
                              );
                            }
                            countDateDurationReview(e.target.value, tglAng);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bunga/Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control required />}
                          selected={tanggalAju}
                          onChange={(date) => setTanggalAju(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang 1 <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          selected={tglAng}
                          customInput={<Form.Control required />}
                          onChange={(date) => {
                            setTglAng(date);
                            countDateDuration(date);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          selected={tglAngAkhir}
                          customInput={
                            <Form.Control required disabled readOnly />
                          }
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kategori <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kategori}
                          onChange={(e) => {
                            setKategori(e.target.value);
                          }}
                        >
                          {kategoriOption.map((kategori) => (
                            <option value={kategori}>{kategori}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeSurveyor.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeSurveyor(value.split(" ", 1)[0]);
                          }}
                          defaultValue={kodeSurveyor}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeMarketing(value.split(" ", 1)[0]);
                          }}
                          defaultValue={kodeMarketing}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeCOA.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeCOA(value.split(" ", 1)[0]);
                          }}
                          defaultValue={kodeCOA}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Kendaraan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={tipeOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeTipe.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onChange={(e, value) => {
                            if (value) {
                              findTipe(value.label);
                            } else {
                              findTipe("");
                            }
                          }}
                          defaultValue={kodeTipe}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          type="number"
                          value={tahun}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 4) {
                              setTahun(e.target.value.toUpperCase());
                            }
                          }}
                          isInvalid={tahun.length != 4 && true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Tahun harus diisi dan harus 4 digit angka!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noRangka}
                          onChange={(e) =>
                            setNoRangka(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noMesin}
                          onChange={(e) =>
                            setNoMesin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Polisi <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nopol}
                          onChange={(e) =>
                            setNopol(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Warna <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kodeWarna}
                          onChange={(e) => {
                            setKodeWarna(e.target.value);
                          }}
                        >
                          {warnas.map((warna, index) => (
                            <option value={warna.id}>{warna.namaWarna}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Merk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={merk}
                          onChange={(e) =>
                            setMerk(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CC :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={isi}
                          onChange={(e) => setIsi(e.target.value.toUpperCase())}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Stnk <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control />}
                          selected={tglStnk}
                          onChange={(date) => setTglStnk(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. BPKP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noBpkb}
                          onChange={(e) =>
                            setNoBpkb(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        BPKP A/N <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bpkbAN}
                          onChange={(e) =>
                            setBpkbAN(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={hargaPasarSetempatOtr}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setHargaPasarSetempatOtr(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={taksiran}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setTaksiran(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={maksimalPemberianPinjaman}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setMaksimalPemberianPinjaman(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Asuransi</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noFidusia}
                          onChange={(e) =>
                            setNoFidusia(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nilai Pertanggungan :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={nilaiPertanggungan}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setNilaiPertanggungan(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Asuransi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noAsuransi}
                          onChange={(e) =>
                            setNoAsuransi(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(`/daftarPengajuanFidusia/pengajuanFidusia/${id}`)
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Card.Body>
        </Card>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Customer`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCustomer} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Alamat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      No. Telp / HP
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCustomer
                    .filter((val) => {
                      if (searchTermCustomer === "") {
                        return val;
                      } else if (
                        val.namaCustomer
                          .toUpperCase()
                          .includes(searchTermCustomer.toUpperCase()) ||
                        val.alamatCustomer
                          .toUpperCase()
                          .includes(searchTermCustomer.toUpperCase()) ||
                        val.noTeleponCustomer
                          .toUpperCase()
                          .includes(searchTermCustomer.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((customer, index) => (
                      <TableRow
                        key={customer._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          const findTotalPengajuanPerCustomer =
                            await axios.post(
                              `${tempUrl}/pengajuanByCustomerId`,
                              {
                                customerId: customer.id,
                                _id: user.id,
                                token: user.token,
                                kodeCabang: user.cabang.id,
                              }
                            );
                          if (findTotalPengajuanPerCustomer.data.length >= 3) {
                            alert(
                              `Customer ${customer.cifCustomer} telah memiliki pengajuan yang belum approve 3 kali!`
                            );
                          } else {
                            setCifCustomer(customer.cifCustomer);
                            setNikCustomer(customer.nikCustomer);
                            setNoKkCustomer(customer.noKkCustomer);
                            setNamaCustomer(customer.namaCustomer);
                            setTempatLahirCustomer(
                              customer.tempatLahirCustomer
                            );
                            setTanggalLahirCustomer(
                              formatDate(customer.tanggalLahirCustomer)
                            );
                            setJenisKelaminCustomer(
                              customer.jenisKelaminCustomer
                            );
                            setAgamaCustomer(customer.agamaCustomer);
                            setNoTeleponCustomer(customer.noTeleponCustomer);
                            setAlamatCustomer(customer.alamatCustomer);
                            setAlamatTTCustomer(customer.alamatTTCustomer);
                            setKodeKelurahan(
                              `${customer.kelurahan.id} - ${customer.kelurahan.namaKelurahan}`
                            );
                            setKodeKelurahanTT(
                              `${customer.kelurahanTT.id} - ${customer.kelurahanTT.namaKelurahan}`
                            );
                            setKodeKecamatan(
                              `${customer.kecamatan.id} - ${customer.kecamatan.namaKecamatan}`
                            );
                            setKodeKecamatanTT(
                              `${customer.kecamatanTT.id} - ${customer.kecamatanTT.namaKecamatan}`
                            );
                            setKodeKabupaten(
                              `${customer.kabupaten.id} - ${customer.kabupaten.namaKabupaten}`
                            );
                            setKodeKabupatenTT(
                              `${customer.kabupatenTT.id} - ${customer.kabupatenTT.namaKabupaten}`
                            );
                            setKodeProvinsi(
                              `${customer.provinsis.id} - ${customer.provinsis.namaProvinsi}`
                            );
                            setKodeProvinsiTT(
                              `${customer.provinsisTT.id} - ${customer.provinsisTT.namaProvinsi}`
                            );
                            setKodePos(customer.kelurahan.kodePos);
                            setKodePosTT(customer.kelurahanTT.kodePos);
                            setStatusPerkawinanCustomer(
                              customer.statusPerkawinanCustomer
                            );
                            setPekerjaanCustomer(customer.pekerjaanCustomer);
                            setKewarganegaraanCustomer(
                              customer.kewarganegaraanCustomer
                            );
                          }
                          handleCloseCustomer();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {customer.namaCustomer}
                        </TableCell>
                        <TableCell>{customer.alamatCustomer}</TableCell>
                        <TableCell>{customer.noTeleponCustomer}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UbahPengajuanFidusia;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
