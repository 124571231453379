import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanStockSiapDijual = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [sampaiTanggal, setSampaiTanggal] = useState(new Date());
  const [kategoriJaminanId, setKategoriJaminanId] = useState("");
  const [namaKategoriJaminan, setNamaKategoriJaminan] = useState("");

  const [kategoriJaminans, setKategoriJaminans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapStocksData, setLapStocksData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);

  let kategoriJaminanOptions = kategoriJaminans.map((kategoriJaminan) => ({
    label: kategoriJaminan.namaKategori,
    id: kategoriJaminan.id,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanStockSiapDijual",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let jaminansBlmTerjualForLaporan = await axios.post(
        `${tempUrl}/jaminansBlmTerjualForLaporan`,
        {
          kategoriJaminanId,
          batasJual: setting.batasJual,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setLapStocksData(jaminansBlmTerjualForLaporan.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getKategoriJaminansData();
  }, []);

  const getKategoriJaminansData = async () => {
    setKategoriJaminanId("");
    const response = await axios.post(`${tempUrl}/kategoriJaminans`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKategoriJaminans(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];
  let tempTotalHarga = 0;
  let tempTotalUnit = 0;

  let keys = Object.keys(lapStocksData);

  for (let j = 0; j < Object.keys(lapStocksData).length; j++) {
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="7">
          {lapStocksData[keys[j]][0].groupKategoriJaminan} :
        </td>
      </tr>
    );
    let groupBy = lapStocksData[keys[j]].reduce((group, pengajuan) => {
      const { groupKategoriJaminan } = pengajuan;
      group[groupKategoriJaminan] = group[groupKategoriJaminan] ?? [];
      group[groupKategoriJaminan].push(pengajuan);
      return group;
    }, {});
    let keysSubGroup = Object.keys(groupBy);

    for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
      for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
        tempTotalHarga += groupBy[keysSubGroup[j]][k].hargaTafsirJam;
        tempTotalUnit += 1;
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].pengajuan.noSbg}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].tglApproval}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].keterlambatan}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].pengajuan.customer.namaCustomer}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].ketJam}
            </td>
            <td style={textNumberRight}>
              {groupBy[keysSubGroup[j]][k].hargaTafsirJam.toLocaleString(
                "en-EN"
              )}
            </td>
            <td style={textNumberRight}>1</td>
          </tr>
        );
      }
    }
  }

  list.push(
    <tr style={tableText}>
      <td
        style={{
          paddingLeft: "15px",
          fontWeight: 700,
          border: "1px solid black",
        }}
        colspan="5"
      >
        Total
      </td>
      <td style={textNumberRightBold}>
        {tempTotalHarga.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRightBold}>
        {tempTotalUnit.toLocaleString("en-EN")}
      </td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanStockSiapDijual",
    sheet: "LaporanStockSiapDijual",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Stock Siap Dijual</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori Jaminan :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriJaminanOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategoriJaminanId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI JAMINAN"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKategoriJaminanId(value.id);
                        setNamaKategoriJaminan(value.label);
                      } else {
                        setKategoriJaminanId("");
                        setNamaKategoriJaminan("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaKategoriJaminan}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Stock Siap Dijual
          </h5>
          <p>
            Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Kategori : {namaKategoriJaminan}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No. Sbg</th>
                <th style={{ border: "1px solid black" }}>Tgl. Approval</th>
                <th style={{ border: "1px solid black" }}>Hari</th>
                <th style={{ border: "1px solid black" }}>Nama Customer</th>
                <th style={{ border: "1px solid black" }}>Agunan</th>
                <th style={{ border: "1px solid black" }}>Harga</th>
                <th style={{ border: "1px solid black" }}>Jml. Stok</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanStockSiapDijual;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
