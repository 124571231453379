import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahCOA = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCOA, setKodeCOA] = useState("");
  const [namaCOA, setNamaCOA] = useState("");
  const [jenisSaldo, setJenisSaldo] = useState("");
  const [kasBank, setKasBank] = useState("");
  const [kodeJenisCOA, setKodeJenisCOA] = useState("");
  const [kodeGroupCOA, setKodeGroupCOA] = useState("");
  const [kodeSubGroupCOA, setKodeSubGroupCOA] = useState("");
  const [kodeCOAOJKLabaRugi, setKodeCOAOJKLabaRugi] = useState("");
  const [kodeCOAOJKNeraca, setKodeCOAOJKNeraca] = useState("");
  const [kodeCOAOJKArusKasDebet, setKodeCOAOJKArusKasDebet] = useState("");
  const [kodeCOAOJKArusKasKredit, setKodeCOAOJKArusKasKredit] = useState("");

  const [subGroupCOAs, setSubGroupCOAs] = useState([]);
  const [formLabaRugiOJKs, setFormLabaRugiOJK] = useState([]);
  const [formNeracaOJKs, setFormNeracaOJK] = useState([]);
  const [formArusKasOJKs, setFormArusKasOJK] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisSaldoOption = ["DEBET", "KREDIT"];
  const kasBankOption = ["KAS", "BANK", "NON KAS BANK"];
  let subGroupCOAOptions = subGroupCOAs.map((subGroupCOA) => ({
    label: `${subGroupCOA.kodeSubGroupCOA} - ${subGroupCOA.namaSubGroupCOA}`,
  }));

  let formLabaRugiOJKOptions = formLabaRugiOJKs.map((formLabaRugiOJK) => ({
    label: `${formLabaRugiOJK.kodeCOAOJK} - ${formLabaRugiOJK.column3}`,
  }));

  let formNeracaOJKOptions = formNeracaOJKs.map((formNeracaOJK) => ({
    label: `${formNeracaOJK.kodeCOAOJK} - ${formNeracaOJK.column3}`,
  }));

  let formArusKasOJKOptions = formArusKasOJKs.map((formArusKasOJK) => ({
    label: `${formArusKasOJK.kodeCOAOJK} - ${formArusKasOJK.column3}`,
  }));

  useEffect(() => {
    getSubGroupCOAData();
    getFormLabaRugiOJKData();
    getFormNeracaOJKData();
    getFormArusKasOJKData();
  }, []);

  const getSubGroupCOAData = async () => {
    const response = await axios.post(`${tempUrl}/subGroupCOAs`, {
      _id: user.id,
      token: user.token,
    });
    setSubGroupCOAs(response.data);
    setJenisSaldo(jenisSaldoOption[0]);
    setKasBank(kasBankOption[0]);
  };

  const getFormLabaRugiOJKData = async () => {
    const response = await axios.post(`${tempUrl}/formLabaRugiOJKKodeCOAs`, {
      _id: user.id,
      token: user.token,
    });
    setFormLabaRugiOJK(response.data);
  };

  const getFormNeracaOJKData = async () => {
    const response = await axios.post(`${tempUrl}/formNeracaOJKKodeCOAs`, {
      _id: user.id,
      token: user.token,
    });
    setFormNeracaOJK(response.data);
  };

  const getFormArusKasOJKData = async () => {
    const response = await axios.post(`${tempUrl}/formArusKasOJKKodeCOAs`, {
      _id: user.id,
      token: user.token,
    });
    setFormArusKasOJK(response.data);
  };

  const getCOANextKode = async (kodeSubGroupCOA) => {
    const response = await axios.post(`${tempUrl}/COAsNextKode`, {
      kodeSubGroupCOA,
      _id: user.id,
      token: user.token,
    });
    setKodeCOA(response.data);
    const findSubGroupCOA = await axios.post(`${tempUrl}/subGroupCOAByKode`, {
      kodeSubGroupCOA,
      _id: user.id,
      token: user.token,
    });
    if (findSubGroupCOA.data) {
      setKodeJenisCOA(
        `${findSubGroupCOA.data.jeniscoa.kodeJenisCOA} - ${findSubGroupCOA.data.jeniscoa.namaJenisCOA}`
      );
      setKodeGroupCOA(
        `${findSubGroupCOA.data.groupcoa.kodeGroupCOA} - ${findSubGroupCOA.data.groupcoa.namaGroupCOA}`
      );
    }
  };

  const saveCOA = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeSubGroupCOA.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveCOA`, {
          namaCOA,
          jenisSaldo,
          kasBank,
          kodeSubGroupCOA,
          kodeCOAOJKLabaRugi: kodeCOAOJKLabaRugi.split(" -", 1)[0],
          kodeCOAOJKNeraca: kodeCOAOJKNeraca.split(" -", 1)[0],
          kodeCOAOJKArusKasDebet: kodeCOAOJKArusKasDebet.split(" -", 1)[0],
          kodeCOAOJKArusKasKredit: kodeCOAOJKArusKasKredit.split(" -", 1)[0],
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/coa");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah COA</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveCOA}>
        <Card>
          <Card.Header>COA</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis COA :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={kodeJenisCOA}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Group COA :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={kodeGroupCOA}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightSmall}>
                    Sub Group COA <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={subGroupCOAOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeSubGroupCOA.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeSubGroupCOA(value.split(" ", 1)[0]);
                        getCOANextKode(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={kodeCOA} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaCOA}
                      onChange={(e) => setNamaCOA(e.target.value.toUpperCase())}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Saldo <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      required
                      value={jenisSaldo}
                      onChange={(e) => {
                        setJenisSaldo(e.target.value);
                      }}
                    >
                      {jenisSaldoOption.map((jenisSaldo) => (
                        <option value={jenisSaldo}>{jenisSaldo}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kas / Bank <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      required
                      value={kasBank}
                      onChange={(e) => {
                        setKasBank(e.target.value);
                      }}
                    >
                      {kasBankOption.map((kasBank) => (
                        <option value={kasBank}>{kasBank}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>COA</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA OJK Laba Rugi :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={formLabaRugiOJKOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error && kodeCOAOJKLabaRugi.length === 0 && true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeCOAOJKLabaRugi(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA OJK Neraca :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={formNeracaOJKOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCOAOJKNeraca.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeCOAOJKNeraca(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA OJK Arus Kas Debet :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={formArusKasOJKOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error && kodeCOAOJKArusKasDebet.length === 0 && true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeCOAOJKArusKasDebet(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA OJK Arus Kas Kredit :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={formArusKasOJKOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error &&
                            kodeCOAOJKArusKasKredit.length === 0 &&
                            true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeCOAOJKArusKasKredit(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/coa")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahCOA;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
