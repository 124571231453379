import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  ButtonGroup,
  Button,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TampilPengajuanFidusia = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  // Data Customer
  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [ketResikoAju, setKetResikoAju] = useState("");

  // Data Penjamin
  const [alamatSama, setAlamatSama] = useState(false);
  const [cifPenjamin, setCifPenjamin] = useState("");
  const [nikPenjamin, setNikPenjamin] = useState("");
  const [noKkPenjamin, setNoKkPenjamin] = useState("");
  const [namaPenjamin, setNamaPenjamin] = useState("");
  const [tempatLahirPenjamin, setTempatLahirPenjamin] = useState("");
  const [tanggalLahirPenjamin, setTanggalLahirPenjamin] = useState("");
  const [jenisKelaminPenjamin, setJenisKelaminPenjamin] = useState("");
  const [agamaPenjamin, setAgamaPenjamin] = useState("");
  const [noTeleponPenjamin, setNoTeleponPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [alamatRefRegister, setAlamatRefRegister] = useState("");
  const [teleponRefRegister, setTeleponRefRegister] = useState("");
  const [alamatPenjamin, setAlamatPenjamin] = useState("");
  const [kodeProvinsiPenjamin, setKodeProvinsiPenjamin] = useState("");
  const [kodeKabupatenPenjamin, setKodeKabupatenPenjamin] = useState("");
  const [kodeKecamatanPenjamin, setKodeKecamatanPenjamin] = useState("");
  const [kodeKelurahanPenjamin, setKodeKelurahanPenjamin] = useState("");
  const [kodePosPenjamin, setKodePosPenjamin] = useState("");
  const [statusPerkawinanPenjamin, setStatusPerkawinanPenjamin] = useState("");
  const [pekerjaanPenjamin, setPekerjaanPenjamin] = useState("");
  const [kewarganegaraanPenjamin, setKewarganegaraanPenjamin] = useState("");

  // Data Pinjaman
  const [noAju, setNoAju] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [kategori, setKategori] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState(0);
  const [angPerBulan, setAngPerBulan] = useState(0);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tanggalAju, setTanggalAju] = useState(new Date());
  const [tglAng, setTglAng] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tenor, setTenor] = useState(0);
  const [bungaPerTahun, setBungaPerTahun] = useState(0);
  const [totalPiutang, setTotalPiutang] = useState(0);
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  // Data Kendaraan
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [kodeWarna, setKodeWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [bpkbAN, setBpkbAN] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [isi, setIsi] = useState("");
  const [merk, setMerk] = useState("");
  const [nopol, setNopol] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("");
  const [taksiran, setTaksiran] = useState("");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("");

  // Asuransi
  const [noFidusia, setNoFidusia] = useState("");
  const [nilaiPertanggungan, setNilaiPertanggungan] = useState(0);
  const [noAsuransi, setNoAsuransi] = useState("");

  const [isPost, setIsPost] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const countDateDurationReview = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    setTglAngAkhir(formatDate(final));
  };

  useEffect(() => {
    getPengajuanFidusiaById();
  }, []);

  const getPengajuanFidusiaById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/pengajuanFidusias/${id}`, {
      _id: user.id,
      token: user.token,
    });
    // Data Customer
    setCifCustomer(response.data.customer.cifCustomer);
    setNikCustomer(response.data.customer.nikCustomer);
    setNoKkCustomer(response.data.customer.noKkCustomer);
    setNamaCustomer(response.data.customer.namaCustomer);
    setTempatLahirCustomer(response.data.customer.tempatLahirCustomer);
    setTanggalLahirCustomer(
      formatDate(response.data.customer.tanggalLahirCustomer)
    );
    setJenisKelaminCustomer(response.data.customer.jenisKelaminCustomer);
    setAgamaCustomer(response.data.customer.agamaCustomer);
    setNoTeleponCustomer(response.data.customer.noTeleponCustomer);
    setAlamatCustomer(response.data.customer.alamatCustomer);
    setAlamatTTCustomer(response.data.customer.alamatTTCustomer);
    setKodeProvinsi(response.data.customer.provinsis.namaProvinsi);
    setKodeProvinsiTT(response.data.customer.provinsisTT.namaProvinsi);
    setKodeKabupaten(response.data.customer.kabupaten.namaKabupaten);
    setKodeKabupatenTT(response.data.customer.kabupatenTT.namaKabupaten);
    setKodeKecamatan(response.data.customer.kecamatan.namaKecamatan);
    setKodeKecamatanTT(response.data.customer.kecamatanTT.namaKecamatan);
    setKodeKelurahan(response.data.customer.kelurahan.namaKelurahan);
    setKodeKelurahanTT(response.data.customer.kelurahanTT.namaKelurahan);
    setKodePos(response.data.customer.kelurahan.kodePos);
    setKodePosTT(response.data.customer.kelurahanTT.kodePos);
    setStatusPerkawinanCustomer(
      response.data.customer.statusPerkawinanCustomer
    );
    setPekerjaanCustomer(response.data.customer.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.customer.kewarganegaraanCustomer);
    setJenisResikoAju(response.data.jenisResikoAju);
    setKetResikoAju(response.data.ketResikoAju);

    // Data Penjamin
    setNikPenjamin(response.data.nikPenjamin);
    setNoKkPenjamin(response.data.noKkPenjamin);
    setNamaPenjamin(response.data.namaPenjamin);
    setTempatLahirPenjamin(response.data.tempatLahirPenjamin);
    setTanggalLahirPenjamin(formatDate(response.data.tanggalLahirPenjamin));
    setJenisKelaminPenjamin(response.data.jenisKelaminPenjamin);
    setAgamaPenjamin(response.data.agamaPenjamin);
    setNoTeleponPenjamin(response.data.noTeleponPenjamin);
    setNamaRefRegister(response.data.namaRefRegister);
    setAlamatRefRegister(response.data.alamatRefRegister);
    setTeleponRefRegister(response.data.teleponRefRegister);
    setAlamatPenjamin(response.data.alamatPenjamin);
    setKodeProvinsiPenjamin(response.data.provinsisPenjamin.namaProvinsi);
    setKodeKabupatenPenjamin(response.data.kabupatenPenjamin.namaKabupaten);
    setKodeKecamatanPenjamin(response.data.kecamatanPenjamin.namaKecamatan);
    setKodeKelurahanPenjamin(response.data.kelurahanPenjamin.namaKelurahan);
    setKodePosPenjamin(response.data.kelurahanPenjamin.kodePos);
    setStatusPerkawinanPenjamin(response.data.statusPerkawinanPenjamin);
    setPekerjaanPenjamin(response.data.pekerjaanPenjamin);
    setKewarganegaraanPenjamin(response.data.kewarganegaraanPenjamin);

    // Data Pinjaman
    setNoAju(response.data.noAju);
    setNoPerjanjian(response.data.noPerjanjian);
    setKategori(response.data.kategori);
    setPinjamanAju(response.data.pinjamanAju);
    setAngPerBulan(response.data.angPerBulan);
    setKodeMarketing(
      `${response.data.marketing.kodeMarketing} - ${response.data.marketing.namaMarketing}`
    );
    setTanggalAju(formatDate(response.data.tanggalAju));
    setTglAng(formatDate(response.data.tglAng));
    countDateDurationReview(response.data.tenor, response.data.tglAng);
    setTenor(response.data.tenor);
    setBungaPerTahun(response.data.bungaPerTahun);
    setTotalPiutang(response.data.totalPiutang);
    setKodeSurveyor(
      `${response.data.surveyor.kodeSurveyor} - ${response.data.surveyor.namaSurveyor}`
    );
    setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);

    // Data Kendaraan
    setKodeTipe(response.data.tipe.kodeTipe);
    setTahun(response.data.tahun);
    setNoRangka(response.data.noRangka);
    setKodeWarna(response.data.warna.namaWarna);
    setNoBpkb(response.data.noBpkb);
    setTglStnk(formatDate(response.data.tglStnk));
    setBpkbAN(response.data.bpkbAN);
    setNoMesin(response.data.noMesin);
    setIsi(response.data.isi);
    setMerk(response.data.merk);
    setNopol(response.data.nopol);

    // Data Penilaian
    setHargaPasarSetempatOtr(response.data.hargaPasarSetempatOtr);
    setTaksiran(response.data.taksiran);
    setMaksimalPemberianPinjaman(response.data.maksimalPemberianPinjaman);

    // Data Asuransi
    setNoFidusia(response.data.noFidusia);
    setNilaiPertanggungan(response.data.nilaiPertanggungan);
    setNoAsuransi(response.data.noAsuransi);

    setIsPost(response.data.isPost);

    let tempIsEditable =
      user.id === response.data.userIdInput && response.data.noSbg === null;

    setIsEditable(tempIsEditable);
    setLoading(false);
  };

  const deletePengajuanFidusia = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deletePengajuanFidusia/${id}`, {
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarPengajuanFidusia");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${noAju} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Data Pengajuan</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarPengajuanFidusia")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isPost === false && (
          <>
            <ButtonGroup variant="contained">
              {isEditable && id && (
                <>
                  <Button
                    color="primary"
                    startIcon={<EditIcon />}
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      navigate(
                        `/daftarPengajuanFidusia/pengajuanFidusia/${id}/edit`
                      );
                    }}
                  >
                    Ubah
                  </Button>
                  <Button
                    color="error"
                    startIcon={<DeleteOutlineIcon />}
                    sx={{ textTransform: "none" }}
                    onClick={handleClickOpen}
                  >
                    Hapus
                  </Button>
                </>
              )}
            </ButtonGroup>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Yakin ingin menghapus data ${noAju}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deletePengajuanFidusia(id)}>Ok</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Data Pribadi Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          required
                          value={cifCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKkCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={agamaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisResikoAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={ketResikoAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>Tempat Tinggal</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatTTCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupatenTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsiTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePosTT} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penjamin</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noKkPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={jenisKelaminPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={agamaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={statusPerkawinanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kewarganegaraanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={alamatRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={teleponRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKelurahanPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKecamatanPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKabupatenPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeProvinsiPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodePosPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Perjanjian :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noPerjanjian} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pinjaman Rp. :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pinjamanAju.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={angPerBulan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tenor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bunga/Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglAng}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglAngAkhir}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kategori :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kategori}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Surveyor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeSurveyor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeMarketing}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Kendaraan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeTipe}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tahun}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Polisi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nopol}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeWarna}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Merk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={merk} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CC :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={isi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Stnk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglStnk}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. BPKP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noBpkb}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        BPKP A/N :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bpkbAN}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={hargaPasarSetempatOtr.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={taksiran.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={maksimalPemberianPinjaman.toLocaleString(
                            "de-DE"
                          )}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Asuransi</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noFidusia} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nilai Pertanggungan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nilaiPertanggungan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Asuransi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAsuransi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
      </Form>
    </Container>
  );
};

export default TampilPengajuanFidusia;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
