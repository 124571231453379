import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import { findMonth, findYear } from "../../../constants/helper";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@mui/styles";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const ArusKasRekap = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [kodeCOA, setKodeCOA] = useState("");

  const [COAsData, setCOAsData] = useState([]);
  const [openCoa, setOpenCoa] = useState(false);
  const [searchTermCoa, setSearchTermCoa] = useState("");
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [open, setOpen] = useState(false);
  const [arusKasRekapsData, setArusKasRekapsData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");
  const [isFetchError, setIsFetchError] = useState(false);
  const navigate = useNavigate();

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClickOpenCoa = () => {
    setOpenCoa(true);
  };

  const handleCloseCoa = () => {
    setOpenCoa(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "ArusKasRekap",
  });

  const tempPostsCoa = COAsData.filter((val) => {
    if (searchTermCoa === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCoa.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCoa.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAsKasBankData();
  }, []);

  const getCOAsKasBankData = async () => {
    setLoading(true);
    try {
      const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
        _id: user.id,
        token: user.token,
      });
      setCOAsData(allCOAs.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);

      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 40;
      setPersenLoading(tempPersenLoading.toString());

      let arusKass = await axios.post(`${tempUrlFico}/arusKasRekaps`, {
        kodeGroupPendapatan: setting.kodeGroupPendapatan,
        kodeGroupBiayaAtasPendapatan: setting.kodeGroupBiayaAtasPendapatan,
        kodeGroupPengeluaranOperasional:
          setting.kodeGroupPengeluaranOperasional,
        kodeGroupPendapatanLainLain: setting.kodeGroupPendapatanLainLain,
        kodeGroupBiayaLainLain: setting.kodeGroupBiayaLainLain,
        kodeCOA,
        dariTanggal,
        sampaiTanggal,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      console.log(arusKass.data);
      setArusKasRekapsData(arusKass.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setPersenLoading("0");
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableTextLast = {
    letterSpacing: "0.01px",
    borderTop: "1px solid black",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const list = [];
  let tempTotalPendapatan = 0;
  let tempTotalBeban = 0;

  for (let arusKasRekap of arusKasRekapsData) {
    if (
      arusKasRekap.urutanCOAOJK === setting.urutanCoaOjkArusKasJumlahPendapatan
    ) {
      tempTotalPendapatan += arusKasRekap.jumlah;
    } else if (
      arusKasRekap.urutanCOAOJK === setting.urutanCoaOjkArusKasJumlahBeban
    ) {
      tempTotalBeban += arusKasRekap.jumlah;
    }
    list.push(
      <tr>
        <td
          style={{
            fontWeight: arusKasRekap.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {arusKasRekap.column1}
        </td>
        <td
          style={{
            fontWeight: arusKasRekap.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {arusKasRekap.column2}
        </td>
        <td
          style={{
            fontWeight: arusKasRekap.isMenu === true ? "800" : "400",
            border: "1px solid black",
          }}
        >
          {arusKasRekap.column3}
        </td>
        <td
          style={{
            fontWeight: arusKasRekap.isMenu === true ? "800" : "400",
            border: "1px solid black",
            letterSpacing: "0.01px",
            textAlign: "right",
          }}
        >
          {arusKasRekap.jumlah !== null &&
            parseFloat(arusKasRekap.jumlah).toLocaleString("en-EN")}
        </td>
      </tr>
    );
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "ArusKasRekap",
    sheet: "ArusKasRekap",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Arus Kas Rekap</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  COA :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    required
                    value={kodeCOA}
                    placeholder="Pilih..."
                    onClick={() => handleClickOpenCoa()}
                    isInvalid={kodeCOA.length === 0 && true}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="contained" startIcon={<SearchIcon />} type="submit">
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={generatePDF}
            >
              PDF
            </Button>
            <Button
              color="secondary"
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <table
          class="styled-table"
          ref={tableRef}
          style={{ visibility: previewPdf === true ? "visible" : "hidden" }}
        >
          <tbody>
            <tr style={{ textAlign: "center", borderBottom: "none" }}>
              <th colSpan="4" style={{ borderBottom: "none" }}>
                PERUSAHAAN PEGADAIAN SWASTA
              </th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">{setting.namaPerusahaan}</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">LAPORAN ARUS KAS</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">
                TRIWULAN KE – II TAHUN {findYear(user.tutupperiode.dariTanggal)}
              </th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="4">
                Untuk Periode Yang Berakhir Pada Bulan{" "}
                {findMonth(user.tutupperiode.dariTanggal)} Tahun{" "}
                {findYear(user.tutupperiode.dariTanggal)}
              </th>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th colspan="2">NAMA AKUN</th>
              <th colspan="2">JUMLAH</th>
            </tr>
            {list}
          </tbody>
        </table>
      </div>

      <Dialog
        open={openCoa}
        onClose={handleCloseCoa}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Account`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCoa} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCoa
                    .filter((val) => {
                      if (searchTermCoa === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCoa.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCoa.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCoa();
                          setPreviewPdf(false);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ArusKasRekap;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};
