import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UbahUser = () => {
  const { screenSize } = useStateContext();
  const { user, setting, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaPeriode, setNamaPeriode] = useState("");
  const [username, setUsername] = useState("");
  const [usernameLama, setUsernameLama] = useState("");
  const [tipeUser, setTipeUser] = useState("");
  const [kodeKwitansi, setKodeKwitansi] = useState("");
  const [coaKasir, setCoaKasir] = useState("");
  const [password, setPassword] = useState("");

  // Akses Master
  const [jaminan, setJaminan] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [bukuBesar, setBukuBesar] = useState(false);
  const [area, setArea] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [surveyor, setSurveyor] = useState(false);
  const [eksekutor, setEksekutor] = useState(false);
  const [tipe, setTipe] = useState(false);
  const [warna, setWarna] = useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Gadai
  const [pengajuan, setPengajuan] = useState(false);
  const [approval, setApproval] = useState(false);
  const [buktiPencairan, setBuktiPencairan] = useState(false);
  const [topup, setTopup] = useState(false);
  const [tebus, setTebus] = useState(false);
  const [perpanjangan, setPerpanjangan] = useState(false);
  const [cicilan, setCicilan] = useState(false);
  const [jualAgunan, setJualAgunan] = useState(false);
  const [infoNasabahGadai, setInfoNasabahGadai] = useState(false);

  // Akses Laporan Gadai
  const [lapTunggakan, setLapTunggakan] = useState(false);
  const [lapTunggakanRinci, setLapTunggakanRinci] = useState(false);
  const [lapOutstanding, setLapOutstanding] = useState(false);
  const [lapStockSiapDijual, setLapStockSiapDijual] = useState(false);
  const [lapPenerimaan, setLapPenerimaan] = useState(false);
  const [lapJualBarang, setLapJualBarang] = useState(false);
  const [lapOutstandingGadai, setLapOutstandingGadai] = useState(false);
  const [lapPiutangLunasGadai, setLapPiutangLunasGadai] = useState(false);

  // Akses Fidusia
  const [pengajuanFidusia, setPengajuanFidusia] = useState(false);
  const [approvalFidusia, setApprovalFidusia] = useState(false);
  const [buktiPencairanFidusia, setBuktiPencairanFidusia] = useState(false);
  const [angsuranFidusia, setAngsuranFidusia] = useState(false);
  const [percepatanFidusia, setPercepatanFidusia] = useState(false);
  const [infoNasabahFidusia, setInfoNasabahFidusia] = useState(false);
  const [penarikanFidusia, setPenarikanFidusia] = useState(false);
  const [sp1, setSp1] = useState(false);
  const [sp2, setSp2] = useState(false);
  const [sp3, setSp3] = useState(false);
  const [sPanggilan, setSPanggilan] = useState(false);

  // Akses Laporan Fidusia
  const [lapTunggakanFidusia, setLapTunggakanFidusia] = useState(false);
  const [lapTunggakanFidusiaRinci, setLapTunggakanFidusiaRinci] =
    useState(false);
  const [lapTotalPiutangFidusia, setLapTotalPiutangFidusia] = useState(false);
  const [lapPinjamanJatuhTempoFidusia, setLapPinjamanJatuhTempoFidusia] =
    useState(false);
  const [lapPenerimaanFidusia, setLapPenerimaanFidusia] = useState(false);
  const [lapOutstandingFidusia, setLapOutstandingFidusia] = useState(false);
  const [lapPiutangLunasFidusia, setLapPiutangLunasFidusia] = useState(false);
  const [lapPenarikanFidusia, setLapPenarikanFidusia] = useState(false);

  // Finance
  const [kasMasuk, setKasMasuk] = useState(false);
  const [kasKeluar, setKasKeluar] = useState(false);
  const [bankMasuk, setBankMasuk] = useState(false);
  const [bankKeluar, setBankKeluar] = useState(false);

  // Accounting
  const [posting, setPosting] = useState(false);
  const [unposting, setUnposting] = useState(false);
  const [aktivitasBukuBesar, setAktivitasBukuBesar] = useState(false);
  const [neracaSaldo, setNeracaSaldo] = useState(false);
  const [labaRugiRekap, setLabaRugiRekap] = useState(false);
  const [labaRugi, setLabaRugi] = useState(false);
  const [neraca, setNeraca] = useState(false);
  const [arusKasRekap, setArusKasRekap] = useState(false);
  const [jurnalUmum, setJurnalUmum] = useState(false);
  const [jurnal, setJurnal] = useState(false);

  // Accounting OJK
  const [labaRugiOjk, setLabaRugiOjk] = useState(false);
  const [neracaOjk, setNeracaOjk] = useState(false);
  const [arusKasOjk, setArusKasOjk] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [tutupPeriode, setTutupPeriode] = useState(false);
  const [gantiPeriode, setGantiPeriode] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  // Check All Akses
  const [checkAll, setCheckAll] = useState(false);

  const [cabangs, setCabangs] = useState([]);
  const [coaSubTunais, setCoaSubTunais] = useState([]);
  const [periodesData, setPeriodesData] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const tipeUserOption = ["MANAGER", "ADMIN"];
  const tipeUserOptionOwner = ["OWNER/DIREKSI", "MANAGER", "ADMIN"];
  let coaOptions = coaSubTunais.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));
  let periodeOptions = periodesData.map((periode) => ({
    label: periode.namaPeriode,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getPeriodesData();
    getCabangsData();
    getCoaSubTunai();
    getUserById();
  }, []);

  const getPeriodesData = async () => {
    setLoading(true);
    try {
      const allPeriode = await axios.post(`${tempUrl}/tutupPeriodesAsc`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabangId,
      });
      setPeriodesData(allPeriode.data);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const getCabangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
    });
    setCabangs(response.data);
  };

  const getCoaSubTunai = async (kodeUnit) => {
    setCoaKasir("");
    const response = await axios.post(`${tempUrl}/COAsSubGroup`, {
      _id: user.id,
      token: user.token,
      kodeSubGroupCOA: setting.subGroupCoaKas,
    });
    setCoaSubTunais(response.data);
  };

  const getUserById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/findUser/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setUsername(response.data.username);
    setUsernameLama(response.data.username);
    setTipeUser(response.data.tipeUser);
    setNamaPeriode(response.data.tutupperiode.namaPeriode);
    setKodeKwitansi(response.data.kodeKwitansi);
    setCoaKasir(response.data.coaKasir);
    setKodeCabang(response.data.cabang.id);

    // Akses Gadai
    setPengajuan(response.data.akses.pengajuan);
    setApproval(response.data.akses.approval);
    setBuktiPencairan(response.data.akses.buktiPencairan);
    setTopup(response.data.akses.topup);
    setTebus(response.data.akses.tebus);
    setPerpanjangan(response.data.akses.perpanjangan);
    setCicilan(response.data.akses.cicilan);
    setJualAgunan(response.data.akses.jualAgunan);
    setInfoNasabahGadai(response.data.akses.infoNasabahGadai);

    // Akses Laporan Gadai
    setLapTunggakan(response.data.akses.lapTunggakan);
    setLapTunggakanRinci(response.data.akses.lapTunggakanRinci);
    setLapOutstanding(response.data.akses.lapOutstanding);
    setLapStockSiapDijual(response.data.akses.lapStockSiapDijual);
    setLapPenerimaan(response.data.akses.lapPenerimaan);
    setLapJualBarang(response.data.akses.lapJualBarang);
    setLapOutstandingGadai(response.data.akses.lapOutstandingGadai);
    setLapPiutangLunasGadai(response.data.akses.lapPiutangLunasGadai);

    // Akses Fidusia
    setPengajuanFidusia(response.data.akses.pengajuanFidusia);
    setApprovalFidusia(response.data.akses.approvalFidusia);
    setBuktiPencairanFidusia(response.data.akses.buktiPencairanFidusia);
    setAngsuranFidusia(response.data.akses.angsuranFidusia);
    setPercepatanFidusia(response.data.akses.percepatanFidusia);
    setInfoNasabahFidusia(response.data.akses.infoNasabahFidusia);
    setPenarikanFidusia(response.data.akses.penarikanFidusia);
    setSp1(response.data.akses.sp1);
    setSp2(response.data.akses.sp2);
    setSp3(response.data.akses.sp3);
    setSPanggilan(response.data.akses.sPanggilan);

    // Akses Laporan Fidusia
    setLapTunggakanFidusia(response.data.akses.lapTunggakanFidusia);
    setLapTunggakanFidusiaRinci(response.data.akses.lapTunggakanFidusiaRinci);
    setLapTotalPiutangFidusia(response.data.akses.lapTotalPiutangFidusia);
    setLapPinjamanJatuhTempoFidusia(
      response.data.akses.lapPinjamanJatuhTempoFidusia
    );
    setLapPenerimaanFidusia(response.data.akses.lapPenerimaanFidusia);
    setLapOutstandingFidusia(response.data.akses.lapOutstandingFidusia);
    setLapPiutangLunasFidusia(response.data.akses.lapPiutangLunasFidusia);
    setLapPenarikanFidusia(response.data.akses.lapPenarikanFidusia);

    // Finance
    setKasMasuk(response.data.akses.kasMasuk);
    setKasKeluar(response.data.akses.kasKeluar);
    setBankMasuk(response.data.akses.bankMasuk);
    setBankKeluar(response.data.akses.bankKeluar);

    // Accounting
    setPosting(response.data.akses.posting);
    setUnposting(response.data.akses.unposting);
    setAktivitasBukuBesar(response.data.akses.aktivitasBukuBesar);
    setNeracaSaldo(response.data.akses.neracaSaldo);
    setLabaRugiRekap(response.data.akses.labaRugiRekap);
    setLabaRugi(response.data.akses.labaRugi);
    setNeraca(response.data.akses.neraca);
    setArusKasRekap(response.data.akses.arusKasRekap);
    setJurnalUmum(response.data.akses.jurnalUmum);
    setJurnal(response.data.akses.jurnal);

    // Accounting OJK
    setLabaRugiOjk(response.data.akses.labaRugiOjk);
    setNeracaOjk(response.data.akses.neracaOjk);
    setArusKasOjk(response.data.akses.arusKasOjk);

    // Akses Master
    setJaminan(response.data.akses.jaminan);
    setMarketing(response.data.akses.marketing);
    setBukuBesar(response.data.akses.bukuBesar);
    setArea(response.data.akses.area);
    setCustomer(response.data.akses.customer);
    setSurveyor(response.data.akses.surveyor);
    setEksekutor(response.data.akses.eksekutor);
    setTipe(response.data.akses.tipe);
    setWarna(response.data.akses.warna);
    setCabang(response.data.akses.cabang);
    setSettingAkses(response.data.akses.setting);

    // Akses Utility
    setProfilUser(response.data.akses.profilUser);
    setDaftarUser(response.data.akses.daftarUser);
    setTutupPeriode(response.data.akses.tutupPeriode);
    setGantiPeriode(response.data.akses.gantiPeriode);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      coaKasir.length !== 0 &&
      namaPeriode.length !== 0
    ) {
      setLoading(true);
      try {
        let tempUsername = await axios.post(`${tempUrl}/getUsername`, {
          username,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        let isUsernameNotValid =
          tempUsername.data.length > 0 && username !== usernameLama;
        if (isUsernameNotValid) {
          handleClickOpenAlert();
        } else {
          setLoading(true);
          if (password.length === 0) {
            setPassword(user.password);
          }
          await axios.post(`${tempUrl}/users/${id}`, {
            username,
            tipeUser,
            namaPeriode,
            coaKasir,
            password,
            tipeAdmin: user.tipeUser,
            akses: {
              jaminan,
              marketing,
              bukuBesar,
              area,
              customer,
              surveyor,
              eksekutor,
              tipe,
              warna,
              cabang,
              pengajuan,
              approval,
              buktiPencairan,
              topup,
              tebus,
              perpanjangan,
              cicilan,
              jualAgunan,
              infoNasabahGadai,
              lapTunggakan,
              lapTunggakanRinci,
              lapOutstanding,
              lapStockSiapDijual,
              lapPenerimaan,
              lapJualBarang,
              lapOutstandingGadai,
              lapPiutangLunasGadai,
              pengajuanFidusia,
              approvalFidusia,
              buktiPencairanFidusia,
              angsuranFidusia,
              percepatanFidusia,
              infoNasabahFidusia,
              penarikanFidusia,
              sp1,
              sp2,
              sp3,
              sPanggilan,
              lapTunggakanFidusia,
              lapTunggakanFidusiaRinci,
              lapTotalPiutangFidusia,
              lapPinjamanJatuhTempoFidusia,
              lapPenerimaanFidusia,
              lapOutstandingFidusia,
              lapPiutangLunasFidusia,
              lapPenarikanFidusia,
              kasMasuk,
              kasKeluar,
              bankMasuk,
              bankKeluar,
              posting,
              unposting,
              aktivitasBukuBesar,
              neracaSaldo,
              labaRugiRekap,
              labaRugi,
              neraca,
              arusKasRekap,
              jurnalUmum,
              jurnal,
              labaRugiOjk,
              neracaOjk,
              arusKasOjk,
              tutupPeriode,
              gantiPeriode,
              profilUser,
              daftarUser,
              setting: settingAkses,
            },
            cabangId: kodeCabang,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);

          if (user.id == id) {
            dispatch({ type: "LOGOUT" });
            navigate("/");
          } else {
            navigate("/daftarUser");
          }
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah User</h5>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data Username Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Username ${username} sudah ada, ganti Username!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Ok</Button>
        </DialogActions>
      </Dialog>
      <hr />
      <Card>
        <Card.Header>User</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateUser}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Username <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={username}
                          onChange={(e) =>
                            setUsername(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tipe User <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Select
                          required
                          value={tipeUser}
                          onChange={(e) => {
                            setTipeUser(e.target.value);
                          }}
                        >
                          {user.tipeUser === "OWNER/DIREKSI"
                            ? tipeUserOptionOwner.map((tipeUser) => (
                                <option value={tipeUser}>{tipeUser}</option>
                              ))
                            : tipeUserOption.map((tipeUser) => (
                                <option value={tipeUser}>{tipeUser}</option>
                              ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Periode <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={periodeOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && namaPeriode.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setNamaPeriode(value);
                          }}
                          value={namaPeriode}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {user.tipeUser === "OWNER/DIREKSI" ? (
                        <Form.Label column sm="3" style={textRight}>
                          Cabang <b style={colorRed}>*</b> :
                        </Form.Label>
                      ) : (
                        <Form.Label column sm="3" style={textRight}>
                          Cabang :
                        </Form.Label>
                      )}
                      <Col sm="9">
                        {user.tipeUser === "OWNER/DIREKSI" ? (
                          <Form.Select
                            required
                            value={kodeCabang}
                            onChange={(e) => {
                              setKodeCabang(e.target.value);
                            }}
                          >
                            {cabangs.map((cabang, index) => (
                              <option value={cabang.id}>
                                {cabang.id} - {cabang.namaCabang}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            required
                            value={kodeCabang}
                            disabled
                            readOnly
                          />
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Kode Kwitansi <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={kodeKwitansi}
                          onChange={(e) =>
                            setKodeKwitansi(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        COA Kasir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && coaKasir.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setCoaKasir(value.split(" ", 1)[0]);
                          }}
                          value={coaKasir}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Password :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={password}
                          onChange={(e) =>
                            setPassword(e.target.value.toUpperCase())
                          }
                          placeholder="Isi jika ingin mengganti Password!"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Container style={{ marginTop: 30 }}>
              <h4>Hak Akses User</h4>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Form>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={checkAll} />}
                        label="Pilih Semua"
                        onChange={() => {
                          setCheckAll(!checkAll);
                          // Akses Master
                          setJaminan(!checkAll);
                          setMarketing(!checkAll);
                          setBukuBesar(!checkAll);
                          setArea(!checkAll);
                          setCustomer(!checkAll);
                          setSurveyor(!checkAll);
                          setEksekutor(!checkAll);
                          setTipe(!checkAll);
                          setWarna(!checkAll);
                          setCabang(!checkAll);
                          // Akses Gadai
                          setPengajuan(!checkAll);
                          setApproval(!checkAll);
                          setBuktiPencairan(!checkAll);
                          setTopup(!checkAll);
                          setTebus(!checkAll);
                          setPerpanjangan(!checkAll);
                          setCicilan(!checkAll);
                          setJualAgunan(!checkAll);
                          setInfoNasabahGadai(!checkAll);
                          // Akses Laporan Gadai
                          setLapTunggakan(!checkAll);
                          setLapTunggakanRinci(!checkAll);
                          setLapOutstanding(!checkAll);
                          setLapStockSiapDijual(!checkAll);
                          setLapPenerimaan(!checkAll);
                          setLapJualBarang(!checkAll);
                          setLapOutstandingGadai(!checkAll);
                          setLapPiutangLunasGadai(!checkAll);
                          // Akses Fidusia
                          setPengajuanFidusia(!checkAll);
                          setApprovalFidusia(!checkAll);
                          setBuktiPencairanFidusia(!checkAll);
                          setAngsuranFidusia(!checkAll);
                          setPercepatanFidusia(!checkAll);
                          setInfoNasabahFidusia(!checkAll);
                          setPenarikanFidusia(!checkAll);
                          setSp1(!checkAll);
                          setSp2(!checkAll);
                          setSp3(!checkAll);
                          setSPanggilan(!checkAll);
                          // Akses Laporan Fidusia
                          setLapTunggakanFidusia(!checkAll);
                          setLapTunggakanFidusiaRinci(!checkAll);
                          setLapTotalPiutangFidusia(!checkAll);
                          setLapPinjamanJatuhTempoFidusia(!checkAll);
                          setLapPenerimaanFidusia(!checkAll);
                          setLapOutstandingFidusia(!checkAll);
                          setLapPiutangLunasFidusia(!checkAll);
                          setLapPenarikanFidusia(!checkAll);
                          // Finance
                          setKasMasuk(!checkAll);
                          setKasKeluar(!checkAll);
                          setBankMasuk(!checkAll);
                          setBankKeluar(!checkAll);
                          // Accounting
                          setPosting(!checkAll);
                          setUnposting(!checkAll);
                          setAktivitasBukuBesar(!checkAll);
                          setNeracaSaldo(!checkAll);
                          setLabaRugiRekap(!checkAll);
                          setLabaRugi(!checkAll);
                          setNeraca(!checkAll);
                          setArusKasRekap(!checkAll);
                          setJurnalUmum(!checkAll);
                          setJurnal(!checkAll);
                          // Accounting OJK
                          setLabaRugiOjk(!checkAll);
                          setNeracaOjk(!checkAll);
                          setArusKasOjk(!checkAll);
                          // Akses Utility
                          setProfilUser(!checkAll);
                          setDaftarUser(!checkAll);
                          setTutupPeriode(!checkAll);
                          setGantiPeriode(!checkAll);
                          setSettingAkses(!checkAll);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </Box>
              </Box>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Master
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jaminan} />}
                      label="Jaminan"
                      onChange={() => setJaminan(!jaminan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={marketing} />}
                      label="Marketing"
                      onChange={() => setMarketing(!marketing)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bukuBesar} />}
                      label="Buku Besar"
                      onChange={() => setBukuBesar(!bukuBesar)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={area} />}
                      label="Area"
                      onChange={() => setArea(!area)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={customer} />}
                      label="Customer"
                      onChange={() => setCustomer(!customer)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={surveyor} />}
                      label="Surveyor"
                      onChange={() => setSurveyor(!surveyor)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={eksekutor} />}
                      label="Eksekutor"
                      onChange={() => setEksekutor(!eksekutor)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tipe} />}
                      label="Tipe"
                      onChange={() => setTipe(!tipe)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={warna} />}
                      label="Warna"
                      onChange={() => setWarna(!warna)}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cabang} />}
                      label="Cabang"
                      onChange={() => setCabang(!cabang)}
                    />
                  </FormGroup> */}
                  <Typography variant="p" sx={[spacingTop]}>
                    Gadai
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pengajuan} />}
                      label="Pengajuan"
                      onChange={() => setPengajuan(!pengajuan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={approval} />}
                      label="Approval"
                      onChange={() => setApproval(!approval)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={buktiPencairan} />}
                      label="Bukti Pencairan"
                      onChange={() => setBuktiPencairan(!buktiPencairan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={topup} />}
                      label="Top-Up"
                      onChange={() => setTopup(!topup)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tebus} />}
                      label="Tebus"
                      onChange={() => setTebus(!tebus)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={perpanjangan} />}
                      label="Perpanjangan"
                      onChange={() => setPerpanjangan(!perpanjangan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cicilan} />}
                      label="Cicilan"
                      onChange={() => setCicilan(!cicilan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jualAgunan} />}
                      label="Jual Agunan"
                      onChange={() => setJualAgunan(!jualAgunan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={infoNasabahGadai} />}
                      label="Info Nasabah"
                      onChange={() => setInfoNasabahGadai(!infoNasabahGadai)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan Gadai
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTunggakan} />}
                      label="Lap. Tunggakan"
                      onChange={() => setLapTunggakan(!lapTunggakan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTunggakanRinci} />}
                      label="Lap. Tunggakan Rinci"
                      onChange={() => setLapTunggakanRinci(!lapTunggakanRinci)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapOutstanding} />}
                      label="Lap. Total Outstanding"
                      onChange={() => setLapOutstanding(!lapOutstanding)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapStockSiapDijual} />}
                      label="Lap. Stock Siap Dijual"
                      onChange={() =>
                        setLapStockSiapDijual(!lapStockSiapDijual)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenerimaan} />}
                      label="Lap. Penerimaan"
                      onChange={() => setLapPenerimaan(!lapPenerimaan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapJualBarang} />}
                      label="Lap. Jual Barang"
                      onChange={() => setLapJualBarang(!lapJualBarang)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapOutstandingGadai} />}
                      label="Lap. Outstanding"
                      onChange={() =>
                        setLapOutstandingGadai(!lapOutstandingGadai)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPiutangLunasGadai} />}
                      label="Lap. Piutang Lunas"
                      onChange={() =>
                        setLapPiutangLunasGadai(!lapPiutangLunasGadai)
                      }
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Fidusia
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pengajuanFidusia} />}
                      label="Pengajuan"
                      onChange={() => setPengajuanFidusia(!pengajuanFidusia)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={approvalFidusia} />}
                      label="Approval"
                      onChange={() => setApprovalFidusia(!approvalFidusia)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={buktiPencairanFidusia} />}
                      label="Bukti Pencairan"
                      onChange={() =>
                        setBuktiPencairanFidusia(!buktiPencairanFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={angsuranFidusia} />}
                      label="Angsuran"
                      onChange={() => setAngsuranFidusia(!angsuranFidusia)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={percepatanFidusia} />}
                      label="Percepatan"
                      onChange={() => setPercepatanFidusia(!percepatanFidusia)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={infoNasabahFidusia} />}
                      label="Info Nasabah"
                      onChange={() =>
                        setInfoNasabahFidusia(!infoNasabahFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penarikanFidusia} />}
                      label="Penarikan "
                      onChange={() => setPenarikanFidusia(!penarikanFidusia)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp1} />}
                      label="SP-1"
                      onChange={() => setSp1(!sp1)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp2} />}
                      label="SP-2"
                      onChange={() => setSp2(!sp2)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp3} />}
                      label="SP-3"
                      onChange={() => setSp3(!sp3)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sPanggilan} />}
                      label="Surat Panggilan"
                      onChange={() => setSPanggilan(!sPanggilan)}
                    />
                  </FormGroup>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan Fidusia
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTunggakanFidusia} />}
                      label="Lap. Tunggakan"
                      onChange={() =>
                        setLapTunggakanFidusia(!lapTunggakanFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTunggakanFidusiaRinci} />}
                      label="Lap. Tunggakan Rinci"
                      onChange={() =>
                        setLapTunggakanFidusiaRinci(!lapTunggakanFidusiaRinci)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTotalPiutangFidusia} />}
                      label="Lap. Total Outstanding"
                      onChange={() =>
                        setLapTotalPiutangFidusia(!lapTotalPiutangFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPinjamanJatuhTempoFidusia} />
                      }
                      label="Lap. Pinjaman Jatuh Tempo"
                      onChange={() =>
                        setLapPinjamanJatuhTempoFidusia(
                          !lapPinjamanJatuhTempoFidusia
                        )
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenerimaanFidusia} />}
                      label="Lap. Penerimaan"
                      onChange={() =>
                        setLapPenerimaanFidusia(!lapPenerimaanFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapOutstandingFidusia} />}
                      label="Lap. Outstanding Fidusia"
                      onChange={() =>
                        setLapOutstandingFidusia(!lapOutstandingFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPiutangLunasFidusia} />}
                      label="Lap. Piutang Lunas"
                      onChange={() =>
                        setLapPiutangLunasFidusia(!lapPiutangLunasFidusia)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenarikanFidusia} />}
                      label="Lap. Penarikan"
                      onChange={() =>
                        setLapPenarikanFidusia(!lapPenarikanFidusia)
                      }
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Finance
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasMasuk} />}
                      label="Kas Masuk"
                      onChange={() => setKasMasuk(!kasMasuk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasKeluar} />}
                      label="Kas Keluar"
                      onChange={() => setKasKeluar(!kasKeluar)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankMasuk} />}
                      label="Bank Masuk"
                      onChange={() => setBankMasuk(!bankMasuk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankKeluar} />}
                      label="Bank Keluar"
                      onChange={() => setBankKeluar(!bankKeluar)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={posting} />}
                      label="Posting"
                      onChange={() => setPosting(!posting)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={unposting} />}
                      label="Unposting"
                      onChange={() => setUnposting(!unposting)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={aktivitasBukuBesar} />}
                      label="Aktivitas Buku Besar"
                      onChange={() =>
                        setAktivitasBukuBesar(!aktivitasBukuBesar)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaSaldo} />}
                      label="Neraca Saldo"
                      onChange={() => setNeracaSaldo(!neracaSaldo)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiRekap} />}
                      label="Laba Rugi Rekap"
                      onChange={() => setLabaRugiRekap(!labaRugiRekap)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugi} />}
                      label="Laba Rugi"
                      onChange={() => setLabaRugi(!labaRugi)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neraca} />}
                      label="Neraca"
                      onChange={() => setNeraca(!neraca)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={arusKasRekap} />}
                      label="Arus Kas Rekap"
                      onChange={() => setArusKasRekap(!arusKasRekap)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jurnalUmum} />}
                      label="Jurnal Umum"
                      onChange={() => setJurnalUmum(!jurnalUmum)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jurnal} />}
                      label="Jurnal"
                      onChange={() => setJurnal(!jurnal)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting OJK
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiOjk} />}
                      label="Laba Rugi OJK"
                      onChange={() => setLabaRugiOjk(!labaRugiOjk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaOjk} />}
                      label="Neraca OJK"
                      onChange={() => setNeracaOjk(!neracaOjk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={arusKasOjk} />}
                      label="Arus Kas OJK"
                      onChange={() => setArusKasOjk(!arusKasOjk)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Utility
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={profilUser} />}
                      label="Profil User"
                      onChange={() => setProfilUser(!profilUser)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarUser} />}
                      label="Daftar User"
                      onChange={() => setDaftarUser(!daftarUser)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tutupPeriode} />}
                      label="Tutup Periode"
                      onChange={() => setTutupPeriode(!tutupPeriode)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={gantiPeriode} />}
                      label="Ganti Periode"
                      onChange={() => setGantiPeriode(!gantiPeriode)}
                    />
                  </FormGroup>
                  {user.tipeUser === "OWNER/DIREKSI" && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={settingAkses} />}
                        label="Setting"
                        onChange={() => setSettingAkses(!settingAkses)}
                      />
                    </FormGroup>
                  )}
                </Box>
              </Box>
            </Container>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarUser")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahUser;

const showDataContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const checkboxTitle = {
  marginBottom: 0,
};

const secondCheckboxTitle = {
  marginTop: 15,
  marginBottom: 0,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};
