import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import { ShowTableJurnalUmum } from "../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, ButtonGroup, Button, Pagination } from "@mui/material";
import jsPDF from "jspdf";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const TampilJurnalUmum = () => {
  const { screenSize } = useStateContext();
  const reportTemplateRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  const [noBukti, setNoBukti] = useState("");
  const [tglJurnalUmumTerbilang, setTglJurnalUmumTerbilang] = useState("");
  const [tglJurnalUmum, setTglJurnalUmum] = useState("");
  const [tglJurnalUmumDate, setTglJurnalUmumDate] = useState();
  const [totalDebet, setTotalDebet] = useState("");
  const [totalKredit, setTotalKredit] = useState("");
  const [isPost, setIsPost] = useState("");

  const [jurnalUmumsChildData, setJurnalUmumsChildData] = useState([]);
  const [jurnalUmumsChildAllData, setJurnalUmumsChildAllData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN" && isPost === false;
  let isEditableAdmin = true;
  // let isEditableAdmin =
  //   user.tipeUser === "ADMIN" &&
  //   tglJurnalUmumDate?.getFullYear() === today.getFullYear() &&
  //   tglJurnalUmumDate?.getMonth() === today.getMonth() &&
  //   tglJurnalUmumDate?.getDate() === today.getDate() &&
  //   isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = jurnalUmumsChildData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(jurnalUmumsChildData.length / PER_PAGE);
  const _DATA = usePagination(jurnalUmumsChildData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  let findMonth = (month) => {
    let tempDateName;
    switch (month) {
      case 1:
        tempDateName = "JANUARI";
        break;
      case 2:
        tempDateName = "FEBRUARI";
        break;
      case 3:
        tempDateName = "MARET";
        break;
      case 4:
        tempDateName = "APRIL";
        break;
      case 5:
        tempDateName = "MEI";
        break;
      case 6:
        tempDateName = "JUNI";
        break;
      case 7:
        tempDateName = "JULI";
        break;
      case 8:
        tempDateName = "AGUSTUS";
        break;
      case 9:
        tempDateName = "SEPTEMBER";
        break;
      case 10:
        tempDateName = "OKTOBER";
        break;
      case 11:
        tempDateName = "NOVEMBER";
        break;
      case 12:
        tempDateName = "DESEMBER";
        break;
      default:
        break;
    }
    return tempDateName;
  };

  useEffect(() => {
    id && getJurnalUmumById();
  }, []);

  const getJurnalUmumById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrlFico}/jurnalUmums/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoBukti(response.data.noBukti);

    let tempTglJurnalUmum = new Date(response.data.tglJurnalUmum);
    let tempTglJurnalUmumName = `
    ${tempTglJurnalUmum.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })} ${findMonth(
      tempTglJurnalUmum.getMonth() + 1
    )} ${tempTglJurnalUmum.getFullYear()}
    `;

    setTglJurnalUmumTerbilang(tempTglJurnalUmumName);
    setTglJurnalUmum(formatDate(response.data.tglJurnalUmum));
    setTglJurnalUmumDate(new Date(response.data.tglJurnalUmum));
    setIsPost(response.data.isPost);
    setTotalDebet(response.data.totalDebet);
    setTotalKredit(response.data.totalKredit);
    const response2 = await axios.post(
      `${tempUrlFico}/jurnalUmumsChildByJurnalUmum`,
      {
        jurnalUmumId: id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setJurnalUmumsChildData(response2.data);
    setLoading(false);
  };

  const getJurnalUmumChildById = async (noBukti) => {
    setLoading(true);
    const response2 = await axios.post(
      `${tempUrlFico}/jurnalUmumsChildByJurnalUmum`,
      {
        jurnalUmumId: id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setJurnalUmumsChildAllData(response2.data);
    setPreviewPdf(!previewPdf);
    setLoading(false);
  };

  const deleteJurnalUmum = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrlFico}/deleteJurnalUmumAndAllChild/${id}`, {
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarJurnalUmum");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.45 },
    });
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const textTableRightBorder = {
    letterSpacing: "0.01px",
    textAlign: "right",
    border: "1px solid black",
  };

  const textTableCenter = {
    letterSpacing: "0.01px",
    textAlign: "center",
  };

  const textTableBorder = {
    border: "1px solid black",
  };

  const textTableCenterBorder = {
    letterSpacing: "0.01px",
    textAlign: "center",
    border: "1px solid black",
  };

  const textNumberRpStyle = {
    display: "flex",
    justifyContent: "space-between",
    height: "20px",
  };

  const list = [];
  let tempTotalDebet = 0;
  let tempTotalKredit = 0;

  for (let i = 0; i < jurnalUmumsChildAllData.length; i++) {
    tempTotalDebet += jurnalUmumsChildAllData[i].debet;
    tempTotalKredit += jurnalUmumsChildAllData[i].kredit;

    list.push(
      <tr style={tableText}>
        <td style={textTableCenterBorder}>{i + 1}</td>
        <td
          style={textTableBorder}
        >{`[${jurnalUmumsChildAllData[i].coa.kodeCOA}] - ${jurnalUmumsChildAllData[i].coa.namaCOA}`}</td>
        <td style={textTableBorder}>{jurnalUmumsChildAllData[i].keterangan}</td>
        <td style={textTableRightBorder}>
          <div style={textNumberRpStyle}>
            <p>Rp</p>
            <p>{jurnalUmumsChildAllData[i].debet.toLocaleString("de-DE")}</p>
          </div>
        </td>
        <td style={textTableRightBorder}>
          <div style={textNumberRpStyle}>
            <p>Rp</p>
            <p>{jurnalUmumsChildAllData[i].kredit.toLocaleString("de-DE")}</p>
          </div>
        </td>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <td style={textTableBorder}></td>
      <td style={textTableBorder}></td>
      <td style={textTableCenterBorder}>
        <b>TOTAL</b>
      </td>
      <td style={textTableRightBorder}>
        <div style={textNumberRpStyle}>
          <b>Rp</b>
          <b>{tempTotalDebet.toLocaleString("de-DE")}</b>
        </div>
      </td>
      <td style={textTableRightBorder}>
        <div style={textNumberRpStyle}>
          <b>Rp</b>
          <b>{tempTotalKredit.toLocaleString("de-DE")}</b>
        </div>
      </td>
    </tr>
  );

  const cetakTerbilang = [];
  cetakTerbilang.push(
    <div style={{ marginTop: "40px" }}>
      <b>TERBILANG</b>
      <p>{angkaTerbilang(tempTotalDebet)} rupiah</p>
    </div>
  );

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Jurnal Umum</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (totalDebet - totalKredit === 0) {
            navigate("/daftarJurnalUmum");
          } else {
            alert(`No. Bukti ${noBukti} ini tidak BALANCE!`);
          }
        }}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJurnalUmumChildById(noBukti);
            }}
          >
            PDF
          </Button>
        </ButtonGroup>
      </Box>

      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} style={cetakContainer}>
            <h5 style={cetakCenterBold}>{setting.namaPerusahaan}</h5>
            <p style={cetakCenterBold}>VOUCHER</p>
            <div style={{ display: "flex" }}>
              <b>TANGGAL: {tglJurnalUmumTerbilang}</b>
              <b style={{ marginLeft: "450px" }}>NO. BUKTI: {noBukti}</b>
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th rowSpan="2" style={borderTableTextCenter}>
                    NO.
                  </th>
                  <th
                    rowSpan="2"
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      width: "200px",
                    }}
                  >
                    GL/akun
                  </th>
                  <th rowSpan="2" style={borderTableTextCenter}>
                    KETERANGAN
                  </th>
                  <th colSpan="2" style={borderTableTextCenter}>
                    NOMINAL
                  </th>
                </tr>
                <tr>
                  <th style={borderTableTextCenter}>DEBET</th>
                  <th style={borderTableTextCenter}>KREDIT</th>
                </tr>
              </thead>
              <tbody>{list}</tbody>
            </table>
            {cetakTerbilang}
            <hr />
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th rowSpan="2" style={borderTableTextCenter}>
                    Dibuat
                  </th>
                  <th rowSpan="2" style={borderTableTextCenter}>
                    Diperiksa
                  </th>
                  <th colSpan="2" style={borderTableTextCenter}>
                    Disetujui
                  </th>
                </tr>
                <tr>
                  <th style={borderTableTextCenterWidth}>Direktur</th>
                  <th style={borderTableTextCenterWidth}>Direktur Utama</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={borderTableTtd}></td>
                  <td style={borderTableTtd}></td>
                  <td style={borderTableTtd}></td>
                  <td style={borderTableTtd}></td>
                </tr>
                <tr>
                  <td style={borderTableTextCenter}>
                    <b>{user.username}</b>
                  </td>
                  <td style={borderTableTextCenter}>
                    <b>{setting.pemeriksa}</b>
                  </td>
                  <td style={borderTableTextCenter}>
                    <b>{setting.direktur}</b>
                  </td>
                  <td style={borderTableTextCenter}>
                    <b>{setting.direkturUtama}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarJurnalUmum/jurnalUmum/${id}/tambahJurnalUmumChild`}
              editLink={`/daftarJurnalUmum/jurnalUmum/${id}/edit`}
              deleteUser={deleteJurnalUmum}
              nameUser={noBukti}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Jurnal Umum</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jurnal Umum :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglJurnalUmum}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Debet :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalDebet.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Kredit :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalKredit.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJurnalUmum id={id} currentPosts={currentPosts} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilJurnalUmum;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cetakContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const cetakCenter = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakCenterBold = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
};

const styleHeaderTableCenter = {
  textAlign: "center",
};

const borderTable = {
  border: "1px solid black",
};

const borderTableTextCenter = {
  border: "1px solid black",
  textAlign: "center",
};

const borderTableTextCenterWidth = {
  border: "1px solid black",
  textAlign: "center",
  width: "200px",
};

const borderTableTtd = {
  border: "1px solid black",
  height: "100px",
};
