import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanOutstandingFidusia = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let tempDariTanggal = new Date(user.tutupperiode.dariTanggal);

  let [dariTanggal, setDariTanggal] = useState(
    new Date(`${tempDariTanggal.getFullYear()}-01-01`)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [kategori, setKategori] = useState("");
  const [groupJtTempo, setGroupJtTempo] = useState("JTTEMPO");
  const [kasirId, setKasirId] = useState("");
  const [namaKasir, setNamaKasir] = useState("");

  const [kasirs, setKasirs] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapOutstandingsData, setLapOutstandingsData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);

  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));

  const handleChangeGroupJtTempo = (event) => {
    setGroupJtTempo(event.target.value);
    setPreviewPdf(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanOutstandingFidusia",
  });

  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let outstandingFidusias = await axios.post(
        `${tempUrl}/laporanOutstandingFidusia`,
        {
          kategori,
          groupJtTempo,
          dariTanggal,
          sampaiTanggal,
          kasirId,
          kodeCabang,
          _id: user.id,
          token: user.token,
        }
      );
      setLapOutstandingsData(outstandingFidusias.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getKasirsData();
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 0;
  let tempTotalAngsuran = 0;
  let tempJlhPinjaman = 0;
  let tempSisaAngsuran = 0;
  let tempTotalJumlahPembayaran = 0;

  for (let i = 0; i < lapOutstandingsData.length; i++) {
    tempNo++;

    let tempTglJatuhTempo = new Date(lapOutstandingsData[i].tglJatuhTempo);
    let tempJumlahPembayaran =
      lapOutstandingsData[i].pinjamanAju -
      parseInt(
        lapOutstandingsData[i].angModal * lapOutstandingsData[i].sisaBulan
      );
    tempTotalJumlahPembayaran += tempJumlahPembayaran;

    tempTotalAngsuran += lapOutstandingsData[i].angPerBulan;
    tempJlhPinjaman += lapOutstandingsData[i].pinjamanAju;
    tempSisaAngsuran += parseInt(
      lapOutstandingsData[i].angModal * lapOutstandingsData[i].sisaBulan
    );

    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {tempNo}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].customer.namaCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].customer.nikCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].customer.alamatCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].customer.noTeleponCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].customer.pekerjaanCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].noPerjanjian}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].sisaBulan === 0 ? "LUNAS" : "AKTIF"}
        </td>
        <td style={textNumberRight}>
          {lapOutstandingsData[i].tenor.toLocaleString("en-EN")}
        </td>
        <td style={{ border: "1px solid black" }}>
          {tempTglJatuhTempo.getDate()}
        </td>
        <td style={textNumberRight}>
          {lapOutstandingsData[i].angPerBulan.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {lapOutstandingsData[i].pinjamanAju.toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {parseInt(
            lapOutstandingsData[i].angModal * lapOutstandingsData[i].sisaBulan
          ).toLocaleString("en-EN")}
        </td>
        <td style={textNumberRight}>
          {tempJumlahPembayaran.toLocaleString("en-EN")}
        </td>
        <td style={{ border: "1px solid black" }}>
          <b>{lapOutstandingsData[i].tipe.kodeTipe}</b>
          <b>{lapOutstandingsData[i].tipe.namaTipe}</b>
          <p>No. Polisi : {lapOutstandingsData[i].nopol}</p>
          <p style={{ marginTop: "-20px" }}>
            No. Rangka : {lapOutstandingsData[i].noRangka}
          </p>
          <p style={{ marginTop: "-20px" }}>
            No. Mesin : {lapOutstandingsData[i].noMesin}
          </p>
          <p style={{ marginTop: "-20px", marginBottom: "-10px" }}>
            No. BPKB : {lapOutstandingsData[i].noBpkb}
          </p>
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].userInput.username}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].userApproval.username}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].marketing.namaMarketing}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapOutstandingsData[i].surveyor.namaSurveyor}
        </td>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={textNumberRight}>
        {tempTotalAngsuran.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRight}>{tempJlhPinjaman.toLocaleString("en-EN")}</td>
      <td style={textNumberRight}>
        {tempSisaAngsuran.toLocaleString("en-EN")}
      </td>
      <td style={textNumberRight}>
        {tempTotalJumlahPembayaran.toLocaleString("en-EN")}
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanOutstandingFidusia",
    sheet: "LaporanOutstandingFidusia",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Outstanding Fidusia</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <FormControl sx={{ marginTop: 1 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Filter
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="JTTEMPO"
                value={groupJtTempo}
                onChange={handleChangeGroupJtTempo}
              >
                <FormControlLabel
                  value="JTTEMPO"
                  control={<Radio />}
                  label="Jt. Tempo"
                />
                <FormControlLabel
                  value="BELUM"
                  control={<Radio />}
                  label="Belum"
                />
                <FormControlLabel
                  value="LUNAS"
                  control={<Radio />}
                  label="Lunas"
                />
                <FormControlLabel
                  value="SEMUA"
                  control={<Radio />}
                  label="Semua"
                />
                <FormControlLabel
                  value="REJECT"
                  control={<Radio />}
                  label="Reject"
                />
              </RadioGroup>
            </FormControl>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategori.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      setKategori(value);
                      setPreviewPdf(false);
                    }}
                    defaultValue={kategori}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                        setNamaKasir("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={generatePDF}
            >
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Outstanding Fidusia
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${dariTanggal.getFullYear()}`}
          </p>
          <p>
            Sampai Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p>Kategori : {kategori}</p>
          <p>Group Jt.Tempo : {groupJtTempo}</p>
          <p>Kasir : {namaKasir}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>No. KTP</th>
                <th style={{ border: "1px solid black" }}>Alamat</th>
                <th style={{ border: "1px solid black" }}>No. Telp</th>
                <th style={{ border: "1px solid black" }}>Pekerjaan</th>
                <th style={{ border: "1px solid black" }}>No. Perjanjian</th>
                <th style={{ border: "1px solid black" }}>Status</th>
                <th style={{ border: "1px solid black" }}>Tenor</th>
                <th style={{ border: "1px solid black" }}>JTO</th>
                <th style={{ border: "1px solid black" }}>Angsuran</th>
                <th style={{ border: "1px solid black" }}>Jlh Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Sisa Angsuran</th>
                <th style={{ border: "1px solid black" }}>Jlh Pembayaran</th>
                <th style={{ border: "1px solid black" }}>Agunan</th>
                <th style={{ border: "1px solid black" }}>User</th>
                <th style={{ border: "1px solid black" }}>Approval</th>
                <th style={{ border: "1px solid black" }}>Marketing</th>
                <th style={{ border: "1px solid black" }}>Surveyor</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanOutstandingFidusia;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
  display: "table",
};
