import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { ShowTableCOA } from "../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilCOA = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeCOA, setKodeCOA] = useState("");
  const [namaCOA, setNamaCOA] = useState("");
  const [jenisSaldo, setJenisSaldo] = useState("");
  const [kasBank, setKasBank] = useState("");
  const [kodeJenisCOA, setKodeJenisCOA] = useState("");
  const [kodeGroupCOA, setKodeGroupCOA] = useState("");
  const [kodeSubGroupCOA, setKodeSubGroupCOA] = useState("");
  const [kodeCOAOJKLabaRugi, setKodeCOAOJKLabaRugi] = useState("");
  const [kodeCOAOJKNeraca, setKodeCOAOJKNeraca] = useState("");
  const [kodeCOAOJKArusKasDebet, setKodeCOAOJKArusKasDebet] = useState("");
  const [kodeCOAOJKArusKasKredit, setKodeCOAOJKArusKasKredit] = useState("");

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [COAs, setCOAs] = useState([]);
  const [COAsPagination, setCOAsPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getCOAs();
  }, []);

  useEffect(() => {
    getCOAsPagination();
    id && getCOAById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTerm]);

  const getCOAsPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/COAsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setCOAsPagination(response.data.coas);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getCOAs = async () => {
    try {
      const response = await axios.post(`${tempUrl}/COAs`, {
        _id: user.id,
        token: user.token,
      });
      setCOAs(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
  };

  const getCOAById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/COAs/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeCOA(response.data.kodeCOA);
      setNamaCOA(response.data.namaCOA);
      setJenisSaldo(response.data.jenisSaldo);
      setKasBank(response.data.kasBank);
      setKodeJenisCOA(
        `${response.data.jeniscoa.kodeJenisCOA} - ${response.data.jeniscoa.namaJenisCOA}`
      );
      setKodeGroupCOA(
        `${response.data.groupcoa.kodeGroupCOA} - ${response.data.groupcoa.namaGroupCOA}`
      );
      setKodeSubGroupCOA(
        `${response.data.subgroupcoa.kodeSubGroupCOA} - ${response.data.subgroupcoa.namaSubGroupCOA}`
      );

      // Find Kode COA OJK Laba Rugi
      const findKodeCOAOJKLabaRugi = await axios.post(
        `${tempUrl}/formLabaRugiOJKByKodeCOAOJK`,
        {
          kodeCOAOJK: response.data.kodeCOAOJKLabaRugi,
          _id: user.id,
          token: user.token,
        }
      );
      if (findKodeCOAOJKLabaRugi.data) {
        setKodeCOAOJKLabaRugi(
          `${findKodeCOAOJKLabaRugi.data.kodeCOAOJK} - ${findKodeCOAOJKLabaRugi.data.column3}`
        );
      } else {
        setKodeCOAOJKLabaRugi("");
      }

      // Find Kode COA OJK Neraca
      const findKodeCOAOJKNeraca = await axios.post(
        `${tempUrl}/formNeracaOJKByKodeCOAOJK`,
        {
          kodeCOAOJK: response.data.kodeCOAOJKNeraca,
          _id: user.id,
          token: user.token,
        }
      );
      if (findKodeCOAOJKNeraca.data) {
        setKodeCOAOJKNeraca(
          `${findKodeCOAOJKNeraca.data.kodeCOAOJK} - ${findKodeCOAOJKNeraca.data.column3}`
        );
      } else {
        setKodeCOAOJKNeraca("");
      }

      // Find Kode COA OJK Arus Kas
      const findKodeCOAOJKArusKasDebet = await axios.post(
        `${tempUrl}/formArusKasOJKByKodeCOAOJK`,
        {
          kodeCOAOJK: response.data.kodeCOAOJKArusKasDebet,
          _id: user.id,
          token: user.token,
        }
      );
      console.log(findKodeCOAOJKArusKasDebet.data);
      if (findKodeCOAOJKArusKasDebet.data) {
        setKodeCOAOJKArusKasDebet(
          `${findKodeCOAOJKArusKasDebet.data.kodeCOAOJK} - ${findKodeCOAOJKArusKasDebet.data.column3}`
        );
      } else {
        setKodeCOAOJKArusKasDebet("");
      }

      // Find Kode COA OJK Arus Kas
      const findKodeCOAOJKArusKasKredit = await axios.post(
        `${tempUrl}/formArusKasOJKByKodeCOAOJK`,
        {
          kodeCOAOJK: response.data.kodeCOAOJKArusKasKredit,
          _id: user.id,
          token: user.token,
        }
      );
      console.log(findKodeCOAOJKArusKasKredit.data);
      if (findKodeCOAOJKArusKasKredit.data) {
        setKodeCOAOJKArusKasKredit(
          `${findKodeCOAOJKArusKasKredit.data.kodeCOAOJK} - ${findKodeCOAOJKArusKasKredit.data.column3}`
        );
      } else {
        setKodeCOAOJKArusKasKredit("");
      }
    }
  };

  const deleteCOA = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteCOA/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      setNamaCOA("");
      setKodeCOA("");
      setJenisSaldo("");
      setKasBank("");
      setKodeGroupCOA("");
      setKodeCOAOJKLabaRugi("");
      setKodeCOAOJKNeraca("");
      setKodeCOAOJKArusKasDebet("");
      setKodeCOAOJKArusKasKredit("");

      navigate("/coa");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${namaCOA} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar COA`, 80, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Coa",
    sheet: "DaftarCoa",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar COA</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getCOAs();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              onDownload();
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Nama</th>
                <th>Jenis Saldo</th>
                <th>Kas Bank</th>
                <th>Kelompok COA</th>
                <th>Group COA</th>
                <th>Sub Group COA</th>
                <th>Kode COA OJK Laba Rugi</th>
                <th>Nama COA OJK Laba Rugi</th>
                <th>Kode COA OJK Neraca</th>
                <th>Nama COA OJK Neraca</th>
                <th>Kode COA OJK Arus Kas Debet</th>
                <th>Nama COA OJK Arus Kas Debet</th>
                <th>Kode COA OJK Arus Kas Kredit</th>
                <th>Nama COA OJK Arus Kas Kredit</th>
              </tr>
            </thead>
            <tbody>
              {COAs.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.kodeCOA}</td>
                  <td>{user.namaCOA}</td>
                  <td>{user.jenisSaldo}</td>
                  <td>{user.kasBank}</td>
                  <td>
                    {user.jeniscoa.kodeJenisCOA} - {user.jeniscoa.namaJenisCOA}
                  </td>
                  <td>
                    {user.groupcoa.kodeGroupCOA} - {user.groupcoa.namaGroupCOA}
                  </td>
                  <td>
                    {user.subgroupcoa.kodeSubGroupCOA} -{" "}
                    {user.subgroupcoa.namaSubGroupCOA}
                  </td>
                  <td>{user.kodeCOAOJKLabaRugi}</td>
                  <td>{user.namaLabaRugiOJK}</td>
                  <td>{user.kodeCOAOJKNeraca}</td>
                  <td>{user.namaNeracaOJK}</td>
                  <td>{user.kodeCOAOJKArusKasDebet}</td>
                  <td>{user.namaArusKasOJKDebet}</td>
                  <td>{user.kodeCOAOJKArusKasKredit}</td>
                  <td>{user.namaArusKasOJKKredit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/coa/tambahCoa`}
          editLink={`/coa/${id}/edit`}
          deleteUser={deleteCOA}
          nameUser={kodeCOA}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Card>
              <Card.Header>COA</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Saldo :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={jenisSaldo} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kas Bank :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kasBank} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Kelompok COA :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeJenisCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Group COA :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeGroupCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Sub Group COA :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeSubGroupCOA}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <Card.Header>COA OJK</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode COA OJK Laba Rugi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOAOJKLabaRugi}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode COA OJK Neraca :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOAOJKNeraca}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode COA OJK Arus Kas Debet :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOAOJKArusKasDebet}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode COA OJK Arus Kas Kredit :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOAOJKArusKasKredit}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableCOA currentPosts={COAsPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      <table ref={tableRef} style={{ visibility: "hidden" }}>
        <tbody>
          <tr>
            <th>Kode</th>
            <th>Nama</th>
            <th>Jenis Saldo</th>
            <th>Kas Bank</th>
            <th>Kelompok COA</th>
            <th>Group COA</th>
            <th>Sub Group COA</th>
            <th>Kode COA OJK Laba Rugi</th>
            <th>Nama COA OJK Laba Rugi</th>
            <th>Kode COA OJK Neraca</th>
            <th>Nama COA OJK Neraca</th>
            <th>Kode COA OJK Arus Kas Debet</th>
            <th>Nama COA OJK Arus Kas Debet</th>
            <th>Kode COA OJK Arus Kas Kredit</th>
            <th>Nama COA OJK Arus Kas Kredit</th>
          </tr>
          {COAs.map((user, index) => (
            <tr>
              <th>{user.kodeCOA}</th>
              <th>{user.namaCOA}</th>
              <th>{user.jenisSaldo}</th>
              <th>{user.kasBank}</th>
              <th>
                {user.jeniscoa.kodeJenisCOA} - {user.jeniscoa.namaJenisCOA}
              </th>
              <th>
                {user.groupcoa.kodeGroupCOA} - {user.groupcoa.namaGroupCOA}
              </th>
              <th>
                {user.subgroupcoa.kodeSubGroupCOA} -{" "}
                {user.subgroupcoa.namaSubGroupCOA}
              </th>
              <th>
                {user.kodeCOAOJKLabaRugi.length > 0
                  ? `'${user.kodeCOAOJKLabaRugi}`
                  : ""}
              </th>
              <th>{user.namaLabaRugiOJK}</th>
              <th>
                {user.kodeCOAOJKNeraca.length > 0
                  ? `'${user.kodeCOAOJKNeraca}`
                  : ""}
              </th>
              <th>{user.namaNeracaOJK}</th>
              <th>
                {user.kodeCOAOJKArusKasDebet.length > 0
                  ? `'${user.kodeCOAOJKArusKasDebet}`
                  : ""}
              </th>
              <th>{user.namaArusKasOJKDebet}</th>
              <th>
                {user.kodeCOAOJKArusKasKredit.length > 0
                  ? `'${user.kodeCOAOJKArusKasKredit}`
                  : ""}
              </th>
              <th>{user.namaArusKasOJKKredit}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TampilCOA;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
