import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrl,
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Table as TableReact,
} from "react-bootstrap";
import jsPDF from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@mui/styles";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const AktivitasBukuBesar = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [dariAccount, setDariAccount] = useState("");
  const [sampaiAccount, setSampaiAccount] = useState("");

  const [COAsData, setCOAsData] = useState([]);
  const [aktivitasBukuBesarsData, setAktivitasBukuBesarsData] = useState([]);
  const [searchTermDariCoa, setSearchTermDariCoa] = useState("");
  const [searchTermSampaiCoa, setSearchTermSampaiCoa] = useState("");
  const [isFetchError, setIsFetchError] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDariCoa, setOpenDariCoa] = useState(false);
  const [openSampaiCoa, setOpenSampaiCoa] = useState(false);
  const navigate = useNavigate();

  const classes = useStyles();

  const handleClickOpenDariCoa = () => {
    setOpenDariCoa(true);
  };

  const handleCloseDariCoa = () => {
    setOpenDariCoa(false);
  };

  const handleClickOpenSampaiCoa = () => {
    setOpenSampaiCoa(true);
  };

  const handleCloseSampaiCoa = () => {
    setOpenSampaiCoa(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tempPostsDariCoa = COAsData.filter((val) => {
    if (searchTermDariCoa === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermDariCoa.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermDariCoa.toUpperCase())
    ) {
      return val;
    }
  });

  const tempPostsSampaiCoa = COAsData.filter((val) => {
    if (searchTermSampaiCoa === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermSampaiCoa.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermSampaiCoa.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAsData();
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    try {
      const allCOAs = await axios.post(`${tempUrl}/COAs`, {
        _id: user.id,
        token: user.token,
      });
      setCOAsData(allCOAs.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanAktivitasBukuBesar",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const aktivitasBukuBesar = await axios.post(
        `${tempUrlFico}/aktivitasBukuBesars`,
        {
          dariTanggal,
          sampaiTanggal,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setAktivitasBukuBesarsData(aktivitasBukuBesar.data);
      setPreviewPdf(!previewPdf);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
    border: "1px solid black",
  };

  const textTableRightBold = {
    letterSpacing: "0.01px",
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableLastText = {
    borderTop: "1px solid black",
    letterSpacing: "0.01px",
    border: "1px solid black",
  };

  const list = [];

  let keys = Object.keys(aktivitasBukuBesarsData);
  let tempDebet = 0;
  let totalDebet = 0;
  let tempKredit = 0;
  let totalKredit = 0;
  let tempSaldo = 0;
  let totalSaldo = 0;

  for (let j = 0; j < Object.keys(aktivitasBukuBesarsData).length; j++) {
    if (
      parseInt(aktivitasBukuBesarsData[keys[j]][0].kodeCOA) >=
        parseInt(dariAccount) &&
      parseInt(aktivitasBukuBesarsData[keys[j]][0].kodeCOA) <=
        parseInt(sampaiAccount)
    ) {
      list.push(
        <tr>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="6"
          >
            Account: {aktivitasBukuBesarsData[keys[j]][0].kodeCOA} -{" "}
            {aktivitasBukuBesarsData[keys[j]][0].namaCOA}
          </td>
        </tr>
      );
      for (let i = 0; i < aktivitasBukuBesarsData[keys[j]].length; i++) {
        let tempTglJurnal = new Date(
          aktivitasBukuBesarsData[keys[j]][i].tglJurnal
        );
        if (aktivitasBukuBesarsData[keys[j]][i].jenis === "D") {
          tempDebet += aktivitasBukuBesarsData[keys[j]][i].jumlah;
          totalDebet += aktivitasBukuBesarsData[keys[j]][i].jumlah;
          tempSaldo += aktivitasBukuBesarsData[keys[j]][i].jumlah;
          totalSaldo += aktivitasBukuBesarsData[keys[j]][i].jumlah;
        } else {
          tempKredit += aktivitasBukuBesarsData[keys[j]][i].jumlah;
          totalKredit += aktivitasBukuBesarsData[keys[j]][i].jumlah;
          tempSaldo -= aktivitasBukuBesarsData[keys[j]][i].jumlah;
          totalSaldo -= aktivitasBukuBesarsData[keys[j]][i].jumlah;
        }
        list.push(
          <tr>
            <td style={{ border: "1px solid black" }}>{`${tempTglJurnal
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(tempTglJurnal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${tempTglJurnal.getFullYear()}`}</td>
            <td style={{ border: "1px solid black" }}>
              {aktivitasBukuBesarsData[keys[j]][i].noBukti}
            </td>
            <td style={{ border: "1px solid black" }}>
              {aktivitasBukuBesarsData[keys[j]][i].keterangan.slice(0, 35)}
            </td>
            {aktivitasBukuBesarsData[keys[j]][i].jenis === "D" ? (
              <>
                <td style={textTableRight}>
                  {aktivitasBukuBesarsData[keys[j]][i].jumlah.toLocaleString(
                    "en-US"
                  )}
                </td>
                <td style={{ border: "1px solid black" }}></td>
              </>
            ) : (
              <>
                <td style={{ border: "1px solid black" }}></td>
                <td style={textTableRight}>
                  {aktivitasBukuBesarsData[keys[j]][i].jumlah.toLocaleString(
                    "en-US"
                  )}
                </td>
              </>
            )}
            <td style={textTableRight}>{tempSaldo.toLocaleString("en-EN")}</td>
          </tr>
        );
      }
      list.push(
        <tr>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="3"
          >
            SubTotal Account: {aktivitasBukuBesarsData[keys[j]][0].kodeCOA} -{" "}
            {aktivitasBukuBesarsData[keys[j]][0].namaCOA}
          </td>
          <td style={textTableRight}>{tempDebet.toLocaleString("en-EN")}</td>
          <td style={textTableRight}>{tempKredit.toLocaleString("en-EN")}</td>
          <td style={textTableRight}>{tempSaldo.toLocaleString("en-EN")}</td>
        </tr>
      );
      tempDebet = 0;
      tempKredit = 0;
      tempSaldo = 0;
    }
  }

  list.push(
    <tr style={{ borderTop: "1px solid black" }}>
      <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="3">
        Balance:
      </td>
      <td style={textTableRightBold}>{totalDebet.toLocaleString("en-EN")}</td>
      <td style={textTableRightBold}>{totalKredit.toLocaleString("en-EN")}</td>
      <td style={textTableRightBold}>{totalSaldo.toLocaleString("en-EN")}</td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanAktivitasBukuBesar",
    sheet: "LaporanAktivitasBukuBesar",
  });

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Aktivitas Buku Besar</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />

      <Card>
        <Card.Header>Rentang Tanggal</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={tampilPdf}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Dari :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={dariTanggal}
                      // minDate={new Date(user.tutupperiode.dariTanggal)}
                      // maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setDariTanggal(date);
                        setPreviewPdf(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sampai :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTanggal}
                      // minDate={new Date(user.tutupperiode.dariTanggal)}
                      // maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setSampaiTanggal(date);
                        setPreviewPdf(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Dari Account <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={dariAccount}
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenDariCoa()}
                      isInvalid={dariAccount.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sampai Account <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={sampaiAccount}
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenSampaiCoa()}
                      isInvalid={sampaiAccount.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                type="submit"
              >
                TAMPIL PDF
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                onDownload();
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Aktivitas Buku Besar
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()} `}
            S/D :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>Tg.</th>
                <th style={{ border: "1px solid black" }}>No. Bukti</th>
                <th style={{ border: "1px solid black" }}>Keterangan Jurnal</th>
                <th style={{ border: "1px solid black" }}>Debet</th>
                <th style={{ border: "1px solid black" }}>Kredit</th>
                <th style={{ border: "1px solid black" }}>Saldo</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>

      <Dialog
        open={openDariCoa}
        onClose={handleCloseDariCoa}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Account`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermDariCoa} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsDariCoa
                    .filter((val) => {
                      if (searchTermDariCoa === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermDariCoa.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermDariCoa.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setDariAccount(user.kodeCOA);
                          handleCloseDariCoa();
                          setPreviewPdf(false);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSampaiCoa}
        onClose={handleCloseSampaiCoa}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Account`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermSampaiCoa} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsSampaiCoa
                    .filter((val) => {
                      if (searchTermSampaiCoa === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermSampaiCoa.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermSampaiCoa.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSampaiAccount(user.kodeCOA);
                          handleCloseSampaiCoa();
                          setPreviewPdf(false);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>

      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default AktivitasBukuBesar;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const alertBox = {
  width: "100%",
};

const tableTitle = {
  border: "1px solid black",
  padding: "10px",
};

const tableTitleRight = {
  border: "1px solid black",
  padding: "10px",
  textAlign: "right",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const colorRed = {
  color: "red",
};
